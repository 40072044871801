import { Component } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchSimTerminateProtectionUpdater } from '../../shared/SubscriberBatchTerminationProtectionUpdater';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiOnOffSwitch } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-change-subscribers-termination-protection',
  templateUrl: './change-subscribers-termination-protection.component.html',
})
export class ChangeSubscriberTerminationProtectionComponent extends DsModalContentBase<
  ChangeSubscriberTerminationProtectionModalData,
  ChangeSubscriberTerminationProtectionCompletionData
> {
  /** Updater which will actually protect/unprotect sim termination */
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchSimTerminateProtectionUpdater;

  /** Terminate protection switch */
  terminationProtectionSwitch = UiOnOffSwitch.default;

  /** Used for translation messages */
  modelInfo = {
    modelName: 'SIM',
  };

  /** Holds current protection status. (Before switch get changed) */
  // @ts-expect-error (legacy code incremental fix)
  currentProtection: boolean;

  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
  }

  ngOnInit() {
    this.updater = new SubscriberBatchSimTerminateProtectionUpdater(
      this.logger,
      this.apiService,
      this.subscribers,
      this.subscriberApiService,
      this.simApiService
    );
    // Set initial value of switch. If any of subscriber has protection on, switch will be on
    this.terminationProtectionSwitch.isOn = this.subscribers.some((subscriber) => !subscriber.terminationEnabled);
    this.terminationProtectionSwitch.onChange = () => {
      this.updater.newTerminationProtectionState = this.terminationProtectionSwitch.isOn ? 'enabled' : 'disabled';
    };
    this.currentProtection = this.terminationProtectionSwitch.isOn;

    this.updater.newTerminationProtectionState = this.terminationProtectionSwitch.isOn ? 'enabled' : 'disabled';
  }

  // @ts-expect-error (legacy code incremental fix)
  outputType: ExtendedSubscriberInterface[];

  get output() {
    return this.updater.subscribers;
  }

  get subscribers(): ExtendedSubscriberInterface[] {
    // FIXME delete this
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers;
  }

  get canConfirm() {
    if (this.updater.state === 'busy') {
      return false;
    }

    const proposedTerminationProtection = this.updater.newTerminationProtectionState === 'enabled';

    const changeWillBeMade = !!this.subscribers.find(
      (s) => s.terminationProtectionIsOn !== proposedTerminationProtection
    );

    return changeWillBeMade;
  }

  override canClose() {
    return this.updater.state !== 'busy';
  }

  async confirm() {
    const updater = await this.updater.performUpdate();
    if (updater.state === 'succeeded') {
      const result: ChangeSubscriberTerminationProtectionCompletionData = {
        success: true,
        updatedSubscribers: this.updater.subscribers,
      };
      this.close(result);
    } else {
      // Do nothing, because the batch update should have displayed any error(s). User may cancel, or try again.
    }
  }
}
export interface ChangeSubscriberTerminationProtectionCompletionData {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
}

export interface ChangeSubscriberTerminationProtectionModalData {
  subscribers: ExtendedSubscriberInterface[];
}
