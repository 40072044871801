<section dsDialogContent class="ds-rows">
  <div class="ds-text">{{'terminate.message' | translate}}</div>
  <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>

  <form *ngIf="shouldShowTerminationProtectionSwitch">
    <div class="ds-field">
      <label class="ds-label">
        {{ 'terminate.protection' | translate }}
      </label>
      <div class="ds-input">
        <ui-on-off-switch
          class="x-termination-protection"
          name="protectionSwitch"
          [content]="terminationProtectionSwitch"
        >
        </ui-on-off-switch>
      </div>
      <div class="ds-text">
          {{ 'terminate.protection_description' | translate }}
      </div>
    </div>
  </form>
</section>

<footer dsDialogFooter>
    <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
      <span [innerHTML]="'TerminateSubscribersComponent.confirm' | translate"></span>
    </button>
    <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
      <span [innerHTML]="'TerminateSubscribersComponent.cancel' | translate"></span>
    </button>
</footer>
