import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SimsService } from '../../../app/shared/subscribers/sims.service';
import { SubscribersService } from '../../../app/shared/subscribers/subscribers.service';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { FeatureSimsBroadcaster } from '../feature-sims-page/feature-sims-broadcaster';

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

@Injectable()
export class SimDetailsService {
  private featureSimsBroadcaster = new FeatureSimsBroadcaster();
  // @ts-expect-error (legacy code incremental fix)
  private subscriber$ = new BehaviorSubject<ExtendedSubscriberInterface>(null);
  // @ts-expect-error (legacy code incremental fix)
  private _subscriber: ExtendedSubscriberInterface;

  constructor(
    private simService: SimsService,
    private subscriberService: SubscribersService,
    private coverageTypeService: CoverageTypeService
  ) {}

  get subscriber() {
    return this.subscriber$.asObservable();
  }

  setSubscriber(sub: ExtendedSubscriberInterface) {
    this._subscriber = sub;
    this.subscriber$.next(sub);
  }

  unsetSubscriber() {
    // @ts-expect-error (legacy code incremental fix)
    this._subscriber = undefined;
    // @ts-expect-error (legacy code incremental fix)
    this.subscriber$.next(undefined);
  }

  get apiMode() {
    return this._subscriber?.isSim ? 'sim' : 'subscriber';
  }

  async updateSubscriber() {
    const subscriber = await(
      this.apiMode === 'sim'
        ? this.simService.get(this._subscriber.simId)
        : this.subscriberService.findByImsi(this._subscriber.imsi)
    );
    this.setSubscriber(subscriber);

    // broadcast the sims page update event
    this.featureSimsBroadcaster.emit({
      updateSimsTable: true,
      simId: subscriber.simId,
      // @ts-expect-error (legacy code incremental fix)
      coverageType: this.coverageTypeService.getCoverageType(),
    });
  }

  // Using it for new details page, leaving above update method untouched for existing modal
  // as either page or modal will be used, so no conflict will arise. once page is fully tested, modal will be gone.
  async fetchSubscriber(param: RequireAtLeastOne<{ simId?: string; imsi?: string }, 'simId' | 'imsi'>) {
    const subscriber = await(
      param.simId
        ? this.simService.get(param.simId)
        : // @ts-expect-error (legacy code incremental fix)
          this.subscriberService.findByImsi(param.imsi)
    );
    this.setSubscriber(subscriber);
  }
}
