<div class="ds-rows slider-content">
  <div class="ds-heading--regular-bold" style="line-height: 40px">
    {{ 'simPacketCaptureSessions.requestCaptureModal.title' | translate }}
  </div>

  <div [innerHTML]="'simPacketCaptureSessions.requestCaptureModal.pricingInfo' | translate"></div>
  <ui-input [content]="prefix"></ui-input>
  <ui-input [content]="duration"></ui-input>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
  <div>
    <ui-button-bar [content]="bottomButtonBar"></ui-button-bar>
  </div>
</div>
