import { LegacyAny } from '@soracom/shared/core';

import { Directive } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { PingSubscriberParams, SoracomApiService } from '../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

@Directive()
export class SubscriberBatchPingWorker extends SubscriberBatchUpdater {
  constructor(
    logger: Logger = LoggerService.shared(),
    apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    public pingSubscriberParams: PingSubscriberParams,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }

  private apiResults: { [key: string]: { status: number; data: any } } = {};

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return LegacyTextContent.translation(
      subscriber.isOnline() ? 'PingSubscribersComponent.online' : 'PingSubscribersComponent.offline'
    );
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return undefined;
  }

  updateFunction(subscriber: ExtendedSubscriberInterface) {
    return subscriber.isSim ? this.pingSim(subscriber) : this.pingSubscriber(subscriber);
  }

  getResult(subscriber: ExtendedSubscriberInterface) {
    return this.apiResults[subscriber.simId];
  }

  private transformResponse = (subscriber: LegacyAny) => {
    return (res: LegacyAny) => {
      this.apiResults[subscriber.simId] = {
        status: res.status,
        data: res.data,
      };
      if (res.status === 200) {
        // BatchUpdater assumes that it returns subscriber attributes in response.
        // ping API doesn't return subscriber attributes, so I replace here.
        res.data = subscriber;
      }
      return res;
    };
  };

  private storeErrorResponse = (subscriber: LegacyAny) => {
    return (res: LegacyAny) => {
      this.apiResults[subscriber.simId] = {
        status: res.status,
        data: res.data,
      };
      throw res;
    };
  };

  private pingSubscriber(subscriber: ExtendedSubscriberInterface) {
    return this.subscriberApiService
      .subscriberDownlinkPingToUserEquipment({ imsi: subscriber.imsi, downlinkPingRequest: this.pingSubscriberParams })
      .then(this.transformResponse(subscriber))
      .catch(this.storeErrorResponse(subscriber));
  }

  private pingSim(subscriber: ExtendedSubscriberInterface) {
    return this.simApiService
      .sendDownlinkPing({ simId: subscriber.simId, downlinkPingRequest: this.pingSubscriberParams })
      .then(this.transformResponse(subscriber))
      .catch(this.storeErrorResponse(subscriber));
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.ping`);
  }
}
