import { Directive } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { ExpiryTime } from '@soracom/shared/soracom-api-typescript-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { oneYearFromNow } from './oneYearFromNow';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

/**
 * These are just the actions defined by the API, plus our one special case, 'UNSET'.
 */
export type ExpiryActions = 'doNothing' | 'deleteSession' | 'deactivate' | 'suspend' | 'terminate' | 'UNSET';

@Directive()
export class SubscriberBatchExpiryUpdater extends SubscriberBatchUpdater {
  // @ts-expect-error (legacy code incremental fix)
  expiryDate: Date;

  // @ts-expect-error (legacy code incremental fix)
  expiryAction: ExpiryActions;

  /** Action which will be set after user select from select box */
  afterAction: string | undefined;

  constructor(
    logger: Logger = LoggerService.shared(),
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }

  /**
   * Function which actually calls api to change expiry.
   */
  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    if (!this.expiryAction || (this.expiryAction !== 'UNSET' && !this.expiryDate)) {
      throw new Error('internal error'); // should never happen
    }

    return subscriber.isSim ? this.updateSim(subscriber) : this.updateLegacySubscriber(subscriber);
  }

  updateLegacySubscriber(subscriber: ExtendedSubscriberInterface): Promise<any> {
    if (this.expiryAction !== 'UNSET') {
      return this.subscriberApiService.setExpiryTime({
        imsi: subscriber.imsi,
        expiryTime: new ExpiryTime({ expiryTime: this.expiryDate.getTime(), expiryAction: this.expiryAction }),
      });
    } else {
      // Per https://app.clubhouse.io/soracom/story/12765/unsetting-sim-expiry-action-should-unset-both-date-and-action-properties we work around a limitation of the API here:
      this.afterAction = 'null';
      const tempExpiryDate = oneYearFromNow();

      return this.subscriberApiService
        .setExpiryTime({
          imsi: subscriber.imsi,
          expiryTime: new ExpiryTime({ expiryTime: tempExpiryDate.getTime(), expiryAction: 'doNothing' }),
        })
        .then(() => this.subscriberApiService.unsetExpiryTime({ imsi: subscriber.imsi }));
    }
  }

  updateSim(subscriber: ExtendedSubscriberInterface): Promise<any> {
    if (this.expiryAction !== 'UNSET') {
      return this.simApiService.setSimExpiryTime({
        simId: subscriber.simId,
        expiryTime: new ExpiryTime({ expiryTime: this.expiryDate.getTime(), expiryAction: this.expiryAction }),
      });
    } else {
      // Per https://app.clubhouse.io/soracom/story/12765/unsetting-sim-expiry-action-should-unset-both-date-and-action-properties we work around a limitation of the API here:

      this.afterAction = 'null';
      const tempExpiryDate = oneYearFromNow();

      return this.simApiService
        .setSimExpiryTime({
          simId: subscriber.simId,
          expiryTime: new ExpiryTime({ expiryTime: tempExpiryDate.getTime(), expiryAction: 'doNothing' }),
        })
        .then(() => this.simApiService.unsetSimExpiryTime({ simId: subscriber.simId }));
    }
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.expiryStatus`);
  }

  /**
   * Sets translation id based on expiry action currently set
   */
  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return LegacyTextContent.translation(`ChangeSubscribersExpiryComponent.expiryActions.${subscriber.expiryAction}`);
  }

  /**
   * Sets translation id based on expiry action to set
   */
  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    //  this.afterAction might be undefined; that is explicitly OK
    // @ts-expect-error (legacy code incremental fix)
    return this.afterAction
      ? LegacyTextContent.translation(`ChangeSubscribersExpiryComponent.expiryActions.${this.afterAction}`)
      : undefined;
  }
}
