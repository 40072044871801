import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import { fixedRateI18n } from '../i18n';
import { RemainingSimUsageData } from '../utils';
import { FixedRateSimRemainingUsageTableValueComponent } from './fixed-rate-sim-remaining-usage-table-value/fixed-rate-sim-remaining-usage-table-value.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: `table[appFixedRateSimRemainingUsageTable]`,
  imports: [
    CommonModule,
    SoracomI18nModule,
    FixedRateSimRemainingUsageTableValueComponent,
  ],
  templateUrl: `./fixed-rate-sim-remaining-usage-table.component.html`,
  host: {
    class: 'ds-datatable',
  },
})
export class FixedRateSimRemainingUsageTableComponent {
  @Input() simUsage?: RemainingSimUsageData;
  i18n = fixedRateI18n;
}
