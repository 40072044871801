import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DsModalContentBase,
  UiDsModalService,
} from '@soracom/shared-ng/ui-ds-modal';
import { UiFixedRateSimDataUsageDetails } from '../../fixed-rate-sim.service';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import { fixedRateI18n } from '../../i18n';
import { FocusMeDirective } from '@soracom/shared-ng/ui-common';
import { CommonModule } from '@angular/common';
import { DsTagSubscriptionPlanNameComponent } from '@soracom/shared-ng/ui-sds';
import { localize } from '@soracom/shared/locale-service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: 'app-top-up-history',
  templateUrl: './top-up-history.component.html',
  imports: [
    CommonModule,
    FocusMeDirective,
    SoracomI18nModule,
    DsTagSubscriptionPlanNameComponent,
  ],
})
export class TopUpHistoryComponent extends DsModalContentBase<
  TopUpHistoryModalInput,
  void
> {
  readonly i18n = fixedRateI18n;
  get history() {
    return this.getInput().fixedRateSimDetails?.history;
  }

  get imsi() {
    return this.getInput().fixedRateSimDetails?.imsi ?? '';
  }

  get plan() {
    return this.getInput().fixedRateSimDetails?.subscriptionPlan;
  }

  get simId() {
    return this.getInput().simId;
  }
}

export interface TopUpHistoryModalInput {
  simId: string;
  fixedRateSimDetails: UiFixedRateSimDataUsageDetails;
}

export function injectOpenTopUpHistoryModal() {
  const uiDsModalService = inject(UiDsModalService);
  return function openTopUpHistoryModal(
    simId: string,
    fixedRateSimDetails: UiFixedRateSimDataUsageDetails
  ) {
    return uiDsModalService.openAndWaitForResult(TopUpHistoryComponent, {
      title: localize(fixedRateI18n.history.title),
      subtitle: localize(fixedRateI18n.history.subTitle),
      headerIconClass: '--icon-time-history',
      data: {
        simId,
        fixedRateSimDetails: fixedRateSimDetails,
      },
    });
  };
}
