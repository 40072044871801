import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteModule } from '@soracom/shared-ng/autocomplete';
import { SimInitialSettingsModule } from '../../sim-initial-settings/sim-initial-settings.module';
import { SoracomUiModule } from '../../soracom-ui/soracom-ui.module';
import { RegisterSimDirectComponent } from './register-sim-direct/register-sim-direct.component';
import { RegisterSimFromOrderComponent } from './register-sim-from-order/register-sim-from-order.component';
import { RegisterSimModalComponent } from './register-sim-modal/register-sim-modal.component';
import { RegisterSimRegistrationKeyComponent } from './register-sim-registration-key/register-sim-registration-key.component';
import { RegisterSimComponent } from './register-sim.component';
import { RegisterVirtualSimComponent } from './register-virtual-sim/register-virtual-sim.component';

@NgModule({
  declarations: [
    RegisterSimComponent, RegisterVirtualSimComponent, RegisterSimModalComponent, RegisterSimFromOrderComponent, RegisterSimDirectComponent, RegisterSimRegistrationKeyComponent
  ],
  exports: [RegisterSimComponent],
  imports: [
    SimInitialSettingsModule,
    SoracomUiModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteModule
  ],
})
export class RegisterSimModule {}
