import { inject, Injectable } from '@angular/core';
import { SimApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import {
  PacketCaptureSession,
  SimApiCreateSimPacketCaptureSessionRequest,
} from '@soracom/shared/soracom-api-typescript-client';
import { BehaviorSubject } from 'rxjs';
import { parseLinkHeader } from '../../utils/parse-link-header';

@Injectable()
export class SimPacketCaptureSessionsService {
  private api = inject(SimApiService);
  // @ts-expect-error (legacy code incremental fix)
  private lastEvaluatedKey: string;

  private _sessions$ = new BehaviorSubject<PacketCaptureSession[]>([]);
  readonly sessions$ = this._sessions$.asObservable();

  fetchSessions = async (options: { simId: string; limit?: number; lastEvaluatedKey?: string }) => {
    const lastEvaluatedKey = options.lastEvaluatedKey;
    const response = await this.api.listSimPacketCaptureSessions({
      simId: options.simId,
      limit: options.limit || 10,
      ...(lastEvaluatedKey && { lastEvaluatedKey }),
    });
    let data = response.data;
    // if lastEvaluatedKey exists means there is previous data.
    if (lastEvaluatedKey) {
      data = this._sessions$.getValue().concat(data);
    }
    this._sessions$.next(data);
    const parsedLink = parseLinkHeader(response.headers['link']);
    this.lastEvaluatedKey = parsedLink && parsedLink.rel === 'next' ? parsedLink.last_evaluated_key : undefined;
    return data;
  };

  fetchNextSessions = async (options: { simId: string; limit?: number }) => {
    return this.fetchSessions({ ...options, lastEvaluatedKey: this.lastEvaluatedKey });
  };

  fetchSimPacketCaptureSession(simId: string, sessionId: string) {
    return this.api.getSimPacketCaptureSession({ simId, sessionId });
  }

  hasNextPage() {
    return !!this.lastEvaluatedKey;
  }

  requestSession = async (
    simId: string,
    packetCaptureSessionRequest: SimApiCreateSimPacketCaptureSessionRequest['packetCaptureSessionRequest']
  ) => {
    const response = await this.api.createSimPacketCaptureSession({ packetCaptureSessionRequest, simId });
    this._sessions$.next([response.data].concat(this._sessions$.getValue() || []));
    return response;
  };

  deleteSession = async (simId: string, sessionId: string) => {
    await this.api.deleteSimPacketCaptureSession({ simId, sessionId });
    const current = this._sessions$.getValue();
    const index = current.findIndex((session) => session.sessionId === sessionId);
    current.splice(index, 1);
    this._sessions$.next(current);
  };

  // NOTE: Because this UI isn't realtime, and we decided not to poll, we can't
  // truly know the status of the session here — there is some delay before it
  // is updated. It will almost surely be STOPPED but if something goes wrong,
  // it might not be. Per [this discussion](https://soracom.slack.com/archives/C011APPU1M5/p1622017937260600),
  // what we decided to do is to set it to POSTPROCESSING and let the user
  // update themselves (because the backend briefly sets it to this state
  // also, before ultimately stopping it.
  stopSession = async (simId: string, sessionId: string) => {
    await this.api.stopSimPacketCaptureSession({ simId, sessionId });
    const current = this._sessions$.getValue();
    const index = current.findIndex((session) => session.sessionId === sessionId);
    current[index].status = 'POST_PROCESSING';
    this._sessions$.next(current);
  };
}
