import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';

import { i18n } from './SimUpdateHistoryI18n';

import { SimStatusHistoryResponseWrapper } from './SimStatusHistoryResponseWrapper';
import { SimUpdateHistoryDataProvider } from './SimUpdateHistoryDataProvider';
import {
  DsNoticesComponent,
  DsTagSubscriptionPlanNameComponent,
} from '@soracom/shared-ng/ui-sds';
import { DsNotice, coerceToDsNotice } from '@soracom/shared/sds-utils';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import { UiSimUpdateHistoryFilterComponent } from './ui-sim-update-history-filter.component';
import { UiSimUpdateHistoryFilterByImsiComponent } from './ui-sim-update-history-filter-by-imsi.component';
import { Any } from '@soracom/shared/core';

@Component({
  selector: 'ui-sim-update-history-table',
  standalone: true,
  imports: [
    CommonModule,
    UiSimUpdateHistoryFilterComponent,
    UiSimUpdateHistoryFilterByImsiComponent,
    DsNoticesComponent,
    DsTagSubscriptionPlanNameComponent,
    SoracomI18nModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui-sim-update-history-table.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      td {
        vertical-align: middle;
      }
      .changed {
        background-color: #fdf9e1;
        font-weight: bold;
      }
    `,
  ],
})
export class UiSimUpdateHistoryTableComponent {
  /**
   * The i18n for this UI.
   */
  i18n = i18n;

  /**
   * Sim ID to load data for. Expected to be passed in by the parent component.
   */
  @Input() simId: string = '';

  /**
   * A signal that emits the data to be displayed in the table. The items are instances of `SimStatusHistoryResponseWrapper`, which is a wrapper around the raw data from the API, with some additional properties and methods to help the UI render more efficiently, and usefully.
   */
  items = signal<SimStatusHistoryResponseWrapper[]>([]);

  /**
   * The data provider that loads data from the API. This is a middleman between this class and SATC, providing a simplified data-loading interface.
   */
  dataProvider = new SimUpdateHistoryDataProvider();

  loadState = signal<'loading' | 'idle' | 'loadingMore' | 'loadedAll'>(
    'loading'
  );

  /**
   * The notices, which for this component include only errors, if any.
   */
  notices = signal<DsNotice[]>([]);

  /**
   * Removes a notice from the list of notices. e.g. because the user clicked the close button on the notice.
   */
  removeNotice(notice: DsNotice) {
    this.notices.update((old) => old.filter((n) => n !== notice));
  }

  /**
   * Add one or more notices to the list of notices. e.g. because the data provider encountered an error. This will update the signal, which will cause the notices component to render the new notices.
   */
  addNotice(...notice: Array<string | Error | DsNotice>) {
    this.notices.update((old) => {
      const newNotices = notice.map((e) => coerceToDsNotice(e));
      return [...old, ...newNotices];
    });
  }

  /**
   * The requested max page size for loading data.
   */
  limit = 100;

  /**
   * The query passed to the data provider. In general we don't vary this ever — it is just the SIM ID and the max records to fetch per "page". But we always use 100 for now, since we just want to load all the data for this UI.
   */
  get query() {
    return {
      simId: this.simId,
      limit: this.limit,
    };
  }

  ngOnInit() {
    this.load();
  }

  /**
   * A single load method that loads the first page, and if more data exists, loads the next page, and so on. This is the only method that should be called to load data. It just appends to the existing data, until there is no more data. So it is used for the initial load, and for any subsequent loads.
   */
  async load() {
    const q = this.query;

    const isFirstLoad = !this.dataProvider.hasLoaded;
    this.loadState.set(isFirstLoad ? 'loading' : 'loadingMore');
    await this.dataProvider.loadMore(q);

    if (this.dataProvider.errors.length > 0) {
      this.notices.update((old) => {
        const newNotices = this.dataProvider.errors.map((e) =>
          coerceToDsNotice(e)
        );
        this.dataProvider.clearErrors();
        return [...old, ...newNotices];
      });
    } else {
      this.items.set(this.dataProvider.wrappedData);
    }

    if (this.dataProvider.canLoadMore) {
      this.loadState.set('idle');
    } else {
      this.loadState.set('loadedAll');
    }

    const possibleImsis: string[] = [];
    for (const item of this.dataProvider.wrappedData) {
      if (item.imsi && !possibleImsis.includes(item.imsi)) {
        possibleImsis.push(item.imsi);
      }
    }
    this.possibleImsis.set(possibleImsis);

    this.showImsiFilter.set(possibleImsis.length > 1 || this.showImsiFilter());
  }

  get canLoadMore() {
    return this.dataProvider.canLoadMore;
  }

  isFiltered = signal<boolean>(false);
  filterMatches = signal<number>(0);
  filterNonMatches = signal<number>(0);

  filterValueChange($event: string) {
    const isFiltered = !!$event;
    this.isFiltered.set(isFiltered);

    if (!isFiltered) {
      this.items.set(this.dataProvider.wrappedData);
      return;
    }

    const filteredItems = this.dataProvider.wrappedData.filter((item) =>
      item.matchesFilter($event)
    );

    this.filterMatches.set(filteredItems.length);
    this.filterNonMatches.set(
      this.dataProvider.wrappedData.length - filteredItems.length
    );
    this.items.set(filteredItems);
  }

  isFilteredByImsi = signal<boolean>(false);
  filterByImsiMatches = signal<number>(0);
  filterByImsiNonMatches = signal<number>(0);

  possibleImsis = signal<string[]>([]);

  filteredImsiChange($event: Any) {
    const isFiltered = $event.length !== this.possibleImsis().length;
    this.isFilteredByImsi.set(isFiltered);

    const filteredItems = this.dataProvider.wrappedData.filter(
      (item) => item.imsi && $event.includes(item.imsi)
    );

    this.filterByImsiMatches.set(filteredItems.length);
    this.filterByImsiNonMatches.set(
      this.dataProvider.wrappedData.length - filteredItems.length
    );
    this.items.set(filteredItems);
  }

  showTextFilter = signal(false);
  showImsiFilter = signal(false);

  showHideTextFilter() {
    this.showTextFilter.set(!this.showTextFilter());
  }

  showHideImsiFilter() {
    this.showImsiFilter.set(!this.showImsiFilter());
  }
}
