import { Directive } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

@Directive()
export class SubscriberBatchGroupUpdater extends SubscriberBatchUpdater {
  // @ts-expect-error (legacy code incremental fix)
  newGroupId: string | false;
  constructor(
    logger: Logger = LoggerService.shared(),
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }

  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    return Sim.isSim(subscriber) // 🌸
      ? this.updateSim(subscriber)
      : this.updateLegacySubscriber(subscriber);
  }

  updateSim(sim: Sim) {
    return this.newGroupId === false // 🌸
      ? this.simApiService.unsetSimGroup({ simId: sim.simId })
      : this.simApiService.setSimGroup({
          simId: sim.simId,
          setGroupRequest: { groupId: this.newGroupId },
        });
  }

  updateLegacySubscriber(subscriber: ExtendedSubscriberInterface) {
    return this.newGroupId === false // 🌸
      ? this.subscriberApiService.unsetGroup({ imsi: subscriber.imsi })
      : this.subscriberApiService.setGroup({
          imsi: subscriber.imsi,
          setGroupRequest: { groupId: this.newGroupId },
        });
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.newGroup`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    // let groupName = this.groupService.get(this.newGroupId)
    // FIXME: Get the group name here once this is marginally working...

    return this.getGroupName(subscriber.groupId);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    if (this.newGroupId === false) {
      return LegacyTextContent.translation('generic.noGroup'); // "(no group)"
    }

    return this.newGroupId ? this.getGroupName(this.newGroupId as string) : undefined;
  }
}
