import { LegacyAny } from '@soracom/shared/core';

import { Injectable } from '@angular/core';
import { LegacyBaseSoracomApiService, SoracomApiParams } from '@user-console/legacy-soracom-api-client';
import { CreatePortMappingRequest } from './shared/CreatePortMappingRequest';
import { PortMapping } from './shared/PortMapping';
import { PortMappingDestination } from './shared/PortMappingDestination';
import { ApiResponse } from '@soracom/shared/soracom-api-typescript-client';
import { TranslateService } from '@ngx-translate/core';

/**Extension of API type that parses destination service */
export type PortMappingUi = PortMapping & { destination: PortMappingDestination & { serviceLabel?: string } };
@Injectable({
  providedIn: 'root',
})
export class PortMappingsApiService {
  constructor(
    public apiService: LegacyBaseSoracomApiService,
    private translateService: TranslateService,
  ) {
    // Hi, Mom!
  }

  /**
   * This is not yet documented for Sim case, but per this comment I believe this should work with either simId or imsi inside the CreatePortMappingRequest's destination object. https://soracom.slack.com/archives/CGY208YFK/p1593422464463200?thread_ts=1593417430.446700&cid=CGY208YFK
   */
  createPortMapping(request: CreatePortMappingRequest): Promise<PortMappingUi> {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: '/v1/port_mappings',
      contentType: 'application/json',
      body: request.toJSON(),
    };
    return this.apiService.callApiWithToken(apiParams).then((response) => {
      const portMapping = response.data;
      const serviceLabel = this.getDestinationServiceStrategyServiceLabel(portMapping);
      return { ...portMapping, destination: { ...portMapping.destination, serviceLabel } };
    });
  }

  deletePortMapping(ipAddress: string, port: number) {
    const apiParams: SoracomApiParams = {
      method: 'delete',
      path: '/v1/port_mappings/' + ipAddress + '/' + port,
    };
    return this.apiService.callApiWithToken(apiParams);
  }

  listPortMappingsForSubscriber(imsi: string) {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/port_mappings/subscribers/' + imsi,
    };
    return this.apiService.callApiWithToken(apiParams);
  }

  listPortMappingsForSim(simId: string): Promise<PortMappingUi[]> {
    //FIXME - update soracom-api so this can be done with SATC
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/port_mappings/sims/' + simId,
    };
    return this.apiService.callApiWithToken(apiParams).then((response) => {
      return response.data.map((portMapping: PortMapping) => {
        const serviceLabel = this.getDestinationServiceStrategyServiceLabel(portMapping);
        return { ...portMapping, destination: { ...portMapping.destination, serviceLabel } };
      });
    });
  }

  /**
   * This is a hopefully-temporary workaround for the issue where the backend does not report `lastPortMappingCreatedTime` for SIMs, but only for subscribers.
   */
  workaroundHackCheckLastPortMappingCreatedTime(imsi: string) {
    const apiParams: SoracomApiParams = {
      path: '/v1/subscribers/' + imsi,
    };

    return this.apiService.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const subscriberData = response.data;
      return subscriberData.lastPortMappingCreatedTime || subscriberData.lastPortMappingCreatedAt;
      // lastPortMappingCreatedAt is a legacy name, to be deleted in future
    });
  }

  private getDestinationServiceStrategyServiceLabel(mapping: PortMapping) {
    if (mapping?.destination?.destinationChoosingStrategy === 'AIR_PRIORITIZED') {
      return this.translateService.instant('on-demand-remote-access.soracomAir');
    } else if (mapping?.destination?.destinationChoosingStrategy === 'ARC_PRIORITIZED') {
      return this.translateService.instant('on-demand-remote-access.soracomArc');
    } else {
      return mapping?.destination?.destinationChoosingStrategy ?? '';
    }
  }
}
