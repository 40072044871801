<section dsDialogContent  [class.--loading]="state === 'submitting' ||  state === 'loading'" [attr.data-ds-message]="$any(loadingMessage) | translate">
    <form #napterConfigForm="ngForm">

      <!-- Show all Napter Connections Screen -->
      <div *ngIf="isListing" class="ds-rows" >
        <button class="ds-button --icon-plus" (click)="onAddMapping()">
          <span>{{'on-demand-remote-access.addMapping' | translate}}</span>
        </button>

        <table class="ds-datatable --embedded">
          <thead class="--sticky">
            <tr>
              <th class="--width-pc-5">{{'on-demand-remote-access.simId' | translate}}</th>
              <th class="--width-pc-5">{{'on-demand-remote-access.destinationService' | translate}}</th>
              <th class="--width-pc-5">{{'on-demand-remote-access.port' | translate}}</th>
              <th class="--width-pc-5">TLS</th>
              <th class="--width-pc-5">{{'on-demand-remote-access.accessTime' | translate}}</th>
              <th>{{'on-demand-remote-access.accessFrom' | translate}}</th>
              <th class="--width-pc-10"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mapping of existingPortMappings; let i = index">
              <td>{{mapping.simId}}</td>
              <td>{{mapping.destination.serviceLabel}}</td>
              <td>
                {{mapping.destination.port}}
              </td>
              <td>
                {{(mapping.tlsRequired ? 'port-mapping-details.tlsYes' : 'port-mapping-details.tlsNo') | translate}}
              </td>
              <td class="--no-wrap">
                {{getTranslatedDuration(mapping.duration)}}
              </td>
              <td class="--code">
                 {{allowedIpRangesForPortMapping(mapping)}}
              </td>
              <td>
                <button class="ds-button --primary --color-celeste-lighter --icon-inspect --hide-label" (click)="viewMappingConfig(mapping)">
                  <span>{{'on-demand-remote-access.viewConfiguration' | translate}}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="ds-notice --alert" translate="on-demand-remote-access.helpTextDontExposeIpAddressAndPortForYourSecurity"></div>
      </div>
  
      <!-- Add New Connection Screen -->
      <div *ngIf="isEditing || isSubmitting" class="x-isEditing ds-rows">
        <p class="ds-text" [innerHtml]="'on-demand-remote-access.asteriskWarning' | translate"></p>
        <dl class="ds-details">
          <dt>
            SIM ID
          </dt>
          <dd>
            <div>{{simId}}</div>
          </dd>
        </dl>
        <div class="ds-text --label">
          {{'on-demand-remote-access.connectUsing' | translate}} <abbr class="--required">*</abbr>
        </div>
        <div class="ds-cols --6">
          <div class="ds-radio --grid --icon-soracom-air" [attr.data-ds-message]="'on-demand-remote-access.airNotAvailable' | translate" *ngIf="airNotAvailable"></div>
          <!-- Cellular  -->
          <label class="ds-radio --grid" [class.--disabled]="!hasOnlineAirSim" *ngIf="!airNotAvailable">
            <input type="radio" name="imsi" [(ngModel)]="napterConnectionType" [value]="'air'" [attr.disabled]="hasOnlineAirSim ? null : true">
            <header class="ds-text --large-bold">Soracom Air</header>
            <i class="ds-icon --icon-soracom-air"></i>
            <section>
              <table appNapterSubscriberDetailList [subscribers]="cellularSims" [imsiOnlineMap]="imsiOnlineMap"></table>
            </section>
            <footer class="ds-text --xxsmall --indent-xsmall --icon-info" *ngIf="hasOnlineAirSim">
              {{'on-demand-remote-access.useCurrentOnline' | translate}}
            </footer>
            <footer class="ds-text --xxsmall --indent-xsmall --icon-warning" *ngIf="!hasOnlineAirSim">
              {{'on-demand-remote-access.noCurrentOnline' | translate}}
            </footer>
          </label>
          <!-- VSIM -->
          <div class="ds-radio --grid --icon-soracom-arc" [attr.data-ds-message]="'on-demand-remote-access.arcNotAvailable' | translate" *ngIf="arcNotAvailable"></div>
          <label class="ds-radio --grid" [class.--disabled]="!hasOnlineVSim" *ngIf="!arcNotAvailable">
            <input type="radio" name="imsi" [(ngModel)]="napterConnectionType" [value]="'arc'" [attr.disabled]="hasOnlineVSim ? null : true">
            <header class="ds-text --large-bold">Soracom Arc</header>
            <i class="ds-icon --icon-soracom-arc"></i>
            <section>
              <table appNapterSubscriberDetailList [subscribers]="virtualSims" [imsiOnlineMap]="imsiOnlineMap"></table>
            </section>
            <footer class="ds-text --xxsmall --indent-xsmall --icon-warning" *ngIf="!hasOnlineVSim">
              {{'on-demand-remote-access.noCurrentOnline' | translate}}
            </footer>
          </label>
        </div>
        <div class="ds-cols --bottom">
          <label class="ds-field">
            <div class="ds-text --label">
              {{'on-demand-remote-access.accessPort' | translate}}
              <span class="--required">*</span>
              <span class="ds-richtip --top-right">
                <i class="ds-icon --icon-question --xxsmall __button"></i>
                <span class="__content ds-rows">
                  <p class="ds-text --small">{{'on-demand-remote-access.portDescription' | translate}}</p>
                </span>
              </span>
            </div>
            <div class="ds-input">
              <input [disabled]="!isEditing" type="number" name="port" required="" aria-required="true" [(ngModel)]="port"
              min="1" max="65535" maxlength="5" class="x-port">
            </div>
          </label>
          <label class="ds-field">
            <div class="ds-text --label">{{'on-demand-remote-access.duration' | translate}}</div>
            <div class="ds-select">
              <select
                [(ngModel)]="duration"
                (ngModelChange)="onDurationChange()"
                class="x-duration"
                name="duration"
                [disabled]="!isEditing">
                <option *ngFor="let d of allUiDefinedDurations" [value]="d.value" [ngClass]="'x-value-' + d.value">
                  {{ d.id | translate }}</option>
              </select>
            </div>
          </label>
          <label class="ds-checkbox">
            <input type="checkbox"
            class="x-tls"
            name="tlsRequired"
            [(ngModel)]="tlsRequired"
            [disabled]="!isEditing">
            <div class="ds-text --label">{{ 'on-demand-remote-access.tlsRequired' | translate }}
              <div class="ds-richtip --top-left">
                <i class="ds-icon --icon-question --xxsmall __button"></i>
                <div class="__content" [innerHtml]="'on-demand-remote-access.tlsHelpText' | translate"></div>
              </div>
            </div>
          </label>
        </div>
        <label class="ds-field">
          <div class="ds-text --label">{{'on-demand-remote-access.ipAddressRange' | translate}}</div>
          <div class="ds-input">
            <input id="accessIp" type="text" pattern="[0-9\s.,\/]*"
            type="text"
            maxlength="1024"
            name="ipAddressRange"
            [(ngModel)]="ipAddressRange"
            (ngModelChange)="onIpRangesChange($event, ipRanges)"
            (keydown.enter)="onIpRangesEnterKey($event, ipRanges)"
            class="x-ipAddressRange"
            [disabled]="!isEditing"
            placeholder="1.2.3.4/32, 5.6.7.8/30"
            appCommaSeparatedCIDR="true"
            #ipRanges="ngModel">
          </div>
          <div
            *ngIf="(!ipRanges.value || ipRanges.errors?.invalidCIDR != null) && (ipRanges.touched || ipRanges.dirty)"
            translate="on-demand-remote-access.validationMessageBogusCIDR"
            class="x-invalidCIDR ds-text --alert --icon-warning"
          ></div>
          <div class="ds-text --small">{{'on-demand-remote-access.ipAddressRangeHint' | translate}}</div>
        </label>

        <div class="ds-notice --info" *ngIf="true === hasPortMappingBeenCreatedThisMonth()">
          <span translate="on-demand-remote-access.subscribedThisMonth"></span>
        </div>
        <div class="ds-notice --info" *ngIf="false === hasPortMappingBeenCreatedThisMonth()">
          <span [innerHtml]="'on-demand-remote-access.napterFeeWarning' | translate"></span>
        </div>
      </div>
  
      <!-- Add Connection Success Screen -->
      <div *ngIf="isDisplaying" class="x-isDisplaying ds-rows">
        <div class="ds-cols --end" *ngIf="!mappingAdded">
          <button class="ds-button --small --plain --alert --icon-delete" (click)="deleteMapping(displayedPortMapping)">
            <span>{{'on-demand-remote-access.deleteMapping' | translate}}</span>
          </button>
        </div>
        <p *ngIf="mappingAdded" class="ds-text --icon-confirm --success" translate="on-demand-remote-access.helpTextForSuccessfullyCreatedPortMapping"></p>
        <app-port-mapping-details [portMapping]="displayedPortMapping"></app-port-mapping-details>
        <div class="ds-notice --alert" translate="on-demand-remote-access.helpTextDontExposeIpAddressAndPortForYourSecurity"></div>
      </div>
      
  
      <app-alerts [alertsManager]="alertsManager"></app-alerts>
    </form>

</section>

<footer dsDialogFooter>
  <ng-container *ngIf="state === 'editing'">
    <button (click)="createPortMapping()" [disabled]="!canEnableRemoteAccess || isLoading || isSubmitting" class="ds-button --primary x-enable-remote-access">
      <span>{{'on-demand-remote-access.saveMapping' | translate}}</span>
    </button>
    <button [disabled]="isLoading || isSubmitting" class="ds-button" (click)="onCancelEditing()">
      <span>{{'common.cancel' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="state === 'listing'">
    <button [disabled]="isLoading || isSubmitting" class="ds-button" (click)="close()">
      <span>{{'common.close' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="state === 'displaying'">
    <button (click)="listExistingPortMappings()" [disabled]="isLoading || isSubmitting" class="ds-button">
      <span>{{'on-demand-remote-access.showList' | translate}}</span>
    </button>
    <button [disabled]="isLoading || isSubmitting" class="ds-button" (click)="close()">
      <span>{{'common.close' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="state === 'error'">
    <button [disabled]="isLoading || isSubmitting" class="ds-button" (click)="close()">
      <span>{{'common.close' | translate}}</span>
    </button>
  </ng-container>
</footer>
