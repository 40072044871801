import { Directive } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';

@Directive()
export class VirtualSessionCreator extends SubscriberBatchUpdater {
  /**
   * This constructor looks useless, but is is required for Angular 15 compatibility. (Because the superclass has legacy dependencies which are not compatible with Angular DI.)
   */
  constructor(logger: Logger, public apiService: SoracomApiService, subscribers: ExtendedSubscriberInterface[]) {
    super(logger, apiService, subscribers);
  }

  // @ts-expect-error (legacy code incremental fix)
  private _publicKey: string;

  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    return subscriber.isSim
      ? this.createSimVirtualSession(subscriber)
      : this.createSubscriberVirtualSession(subscriber);
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.session`);
  }

  set publicKey(value: string) {
    this._publicKey = value;
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    // @ts-expect-error (legacy code incremental fix)
    return undefined;
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return undefined; // prevent ever showing → because it doesn't make sense in this context
  }

  createSubscriberVirtualSession(subscriber: ExtendedSubscriberInterface) {
    return this.apiService.createSubscriberVirtualSession(subscriber.imsi, this._publicKey);
  }

  createSimVirtualSession(sim: ExtendedSubscriberInterface) {
    return this.apiService.createSimVirtualSession(sim.simId, this._publicKey);
  }
}
