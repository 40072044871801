import { Directive } from '@angular/core';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { Logger } from '@soracom/shared/logger';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

@Directive()
export class SubscriberBatchTerminator extends SubscriberBatchUpdater {
  constructor(
    logger: Logger,
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }
  /**
   * A safety guard: if not set to `false` explicitly, then update will fail if a subscriber has TP on */
  terminationProtectionValue = true;

  async updateFunction(subscriber: ExtendedSubscriberInterface) {
    return Sim.isSim(subscriber) ? this.updateSim(subscriber) : this.updateLegacySubscriber(subscriber);
  }

  async updateLegacySubscriber(subscriber: ExtendedSubscriberInterface) {
    if (subscriber.groupId != null) {
      await this.subscriberApiService.unsetGroup({ imsi: subscriber.imsi });
    }

    if (subscriber.terminationProtectionIsOn) {
      if (this.terminationProtectionValue !== false) {
        throw new Error('Termination protection prevented the SIM from being terminated.');
      }
      await this.subscriberApiService.enableTermination({ imsi: subscriber.imsi });
    }

    return this.subscriberApiService.terminateSubscriber({ imsi: subscriber.imsi });
  }

  async updateSim(sim: Sim) {
    if (sim.groupId != null) {
      await this.simApiService.unsetSimGroup({ simId: sim.simId });
    }

    if (sim.terminationProtectionIsOn) {
      if (this.terminationProtectionValue !== false) {
        throw new Error('Termination protection prevented the SIM from being terminated.');
      }
      await this.simApiService.enableSimTermination({ simId: sim.simId });
    }

    return this.simApiService.terminateSim({ simId: sim.simId });
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.terminateSim`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface) {
    return LegacyTextContent.translation(`SubscriberStatus.${subscriber.status}`);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface) {
    return LegacyTextContent.translation('SubscriberBatchUpdater.willBeTerminated');
  }
}
