import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { SimDeleteCaptureSessionComponent } from './sim-delete-capture-session/sim-delete-capture-session.component';
import { SimPacketCaptureSessionsComponent } from './sim-packet-capture-sessions.component';
import { SimSessionDataRowComponent } from './sim-session-data-row/sim-session-data-row.component';
import { SimStartCapturingSessionComponent } from './sim-start-capturing-session/sim-start-capturing-session.component';
import { SimStopCaptureSessionComponent } from './sim-stop-capture-session/sim-stop-capture-session.component';
import {
  UiSdsModule
} from '@soracom/shared-ng/ui-sds';
import {SelectionService} from "./selection.service";
import {SimPacketCaptureSessionsService} from "./sim-packet-capture-sessions.service";

@NgModule({
  declarations: [
    SimPacketCaptureSessionsComponent,
    SimStartCapturingSessionComponent,
    SimDeleteCaptureSessionComponent,
    SimStopCaptureSessionComponent,
    SimSessionDataRowComponent,
  ],
  exports: [SimPacketCaptureSessionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiSdsModule,
    SoracomUiModule,
    TranslateModule.forChild(),
  ],
  providers: [SelectionService, SimPacketCaptureSessionsService]
})
export class SimPacketCaptureSessionsModule {}
