import { Directive } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { BatchUpdateStatus, SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

/** Tag name which was issued after transfer request was made */
export const transferTagName = 'soracom.transfer';

@Directive()
export class SubscriberBatchCancelTransferSimUpdater extends SubscriberBatchUpdater {
  constructor(
    logger: Logger = LoggerService.shared(),
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService
  ) {
    super(logger, apiService, subscribers);
  }
  /**
   * Function which actually calls api to cancel transfer request.
   */
  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<{ status: number }> {
    return this.subscriberApiService.deleteSubscriberTransferToken({
      token: subscriber.tags[transferTagName],
    });
  }

  isSuccess(result: any) {
    // Cancel transfer returns 204
    return result && result.status && result.status === 204;
  }

  /**
   * Internal housekeeping method, that is used each time a promise returns a result.
   */
  processLastResult() {
    const indexToUpdate = this.results.length;
    this.statuses[indexToUpdate] = BatchUpdateStatus.updating;

    if (indexToUpdate > 0) {
      const previousIndex = indexToUpdate - 1;

      const lastResult = this.results[previousIndex];
      const wasSuccessful = this.isSuccess(lastResult);
      const updatedSubscriber = wasSuccessful && this.subscribers[previousIndex]; // Cancel transfer never return subscriber
      const subscriberBeforeUpdate = this.subscribers[previousIndex];

      if (updatedSubscriber && updatedSubscriber.groupId === subscriberBeforeUpdate.groupId) {
        // should always be true, except in group change case?
        if (subscriberBeforeUpdate.group) {
          updatedSubscriber.group = subscriberBeforeUpdate.group;
        } else if (subscriberBeforeUpdate._groupName) {
          updatedSubscriber._groupName = subscriberBeforeUpdate._groupName;
        }
        // This hack simulates the hack that SIM table UI does to keep track of group name
      }

      if (updatedSubscriber) {
        this.subscribers[previousIndex] = updatedSubscriber;
        this.successCount++;
      } else {
        this.failureCount++;
      }

      this.statuses[previousIndex] = this.isSuccess(lastResult)
        ? BatchUpdateStatus.succeeded
        : BatchUpdateStatus.failed;
    }
  }

  /**
   * Cancels transfer request
   */
  cancelTransferRequest() {
    return this.performUpdate();
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.transferOperatorStatus`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    // @ts-expect-error (legacy code incremental fix)
    const key = subscriber.isTransferring
      ? 'SubscriberBatchUpdater.transferRequested'
      : 'SubscriberBatchUpdater.transferNotRequested';
    return LegacyTextContent.translation(key);
  }

  afterDescription(_subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return undefined; // never display a next status for this change type
  }
}
