<div class="ds-rows">
  <div [ngClass]="{ 'ds-cols': idType === 'imsi' }">
    <div [ngClass]="{ 'ds-span --6': idType === 'imsi' }" class="ds-rows">
      <div class="ds-field" *ngIf="idType === 'iccid'">
        <ui-input [content]="iccidFormatted"></ui-input>
      </div>
      <div class="ds-field" *ngIf="idType === 'iccid'">
        <ui-input [content]="puk"></ui-input>
      </div>
      <div class="ds-field" *ngIf="idType === 'imsi'">
        <ui-input class="x-input-imsi" [content]="imsiFormatted"></ui-input>
      </div>
      <div class="ds-field" *ngIf="idType === 'imsi'">
        <ui-input class="x-input-passcode" [content]="password"></ui-input>
      </div>
      <div class="ds-field">
        <ui-input class="x-input-name" [content]="name"></ui-input>
      </div>
      <div class="ds-field">
        <label class="ds-label">{{ 'subscriber_registration.group' | translate }}</label>
        <app-group-autocomplete [formControl]="groupId" valueKey="groupId"> </app-group-autocomplete>
      </div>
    </div>
    <div class="ds-span --6" *ngIf="idType === 'imsi'">
      <div class="agree-terms-notices">
        <div [innerHtml]="'subscriber_registration.agree_terms_jp' | translate"></div>
        <div class="ds-notice  --color-celeste-lightest">
          <label class="ds-checkbox">
            <input type="checkbox" [(ngModel)]="isAgreedToTerms" />
            <div>{{ 'subscriber_registration.agree_jp' | translate }}</div>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div><span class="ds-text --alert">*</span> {{ 'subscriber_registration.required' | translate }}</div>

  <app-alerts [alertsManager]="alertManager"></app-alerts>

  <ui-button-bar [content]="footerButtonBar"></ui-button-bar>

</div>

<ng-template #afterRegisterSuccessModalTemplate>
  <div dsDialogContent>
    <div class="ds-notice --success --icon-online">
      {{ 'subscriber_registration.success.header' | translate }}
    </div>
  </div>

  <footer dsDialogFooter>
    <ui-button-bar [content]="closeSuccessModalButtonBar"></ui-button-bar>
  </footer>

</ng-template>
