import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiProgressIndicator } from '../soracom-ui/ui-progress-indicator/UiProgressIndicator';
import { DsLoadingIndicator, useWithLoading } from '@soracom/shared-ng/ui-sds';
import { slideAnimations } from './slide-animations';
import { SelectionService } from './selection.service';
import { SimPacketCaptureSessionsService } from './sim-packet-capture-sessions.service';

@Component({
  selector: 'app-sim-packet-capture-sessions',
  templateUrl: './sim-packet-capture-sessions.component.html',
  styleUrls: ['./sim-packet-capture-sessions.component.scss'],
  providers: [DsLoadingIndicator],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideAnimations],
})
export class SimPacketCaptureSessionsComponent implements OnInit {
  selection = inject(SelectionService);
  simPacketCaptureSessionService = inject(SimPacketCaptureSessionsService);

  @Input() isActive = false;
  // @ts-expect-error (legacy code incremental fix)
  @Input() subscriberInfo: ExtendedSubscriberInterface;

  loadMoreButton = new UiButton();
  topButtonBar = new UiButtonBar();
  progressIndicator = new UiProgressIndicator();
  alertManager = new AlertsManager();
  withLoading = useWithLoading();

  isFetching = false;

  constructor() {
    this.setupButtons();
    this.progressIndicator.fillParent = true;
    this.progressIndicator.fillMode = 'full';
  }

  async ngOnInit() {
    await this.fetchPacketCaptureSessions();
  }

  setupButtons() {
    this.topButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'simPacketCaptureSessions.button.requestSession';
          button.onClick = () => this.selection.selectPanel('request');
        }),
        UiButton.configure((button) => {
          button.buttonStyle = 'icon';
          button.iconName = 'icon-refresh-ccw';
          button.onClick = () => this.fetchPacketCaptureSessions();
        }),
      ];
    });

    this.loadMoreButton.titleId = 'simPacketCaptureSessions.button.loadMore';
    this.loadMoreButton.onClick = () => this.fetchPacketCaptureSessions(true);
  }

  get simId() {
    return this.subscriberInfo.simId;
  }

  fetchPacketCaptureSessions = async (next = false) => {
    this.alertManager.clear();
    try {
      const apiFn = next
        ? this.simPacketCaptureSessionService.fetchNextSessions
        : this.simPacketCaptureSessionService.fetchSessions;
      await this.withLoading<any>(apiFn({ simId: this.simId }));
    } catch (err) {
      this.alertManager.add(
        Alert.fromApiError(err) || Alert.danger('simPacketCaptureSessions.fetchSessions.commonApiError')
      );
    }
  };
}
