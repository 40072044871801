import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group } from '@soracom/shared/group';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SubscriberBatchGroupUpdater } from '../../shared/SubscriberBatchGroupUpdater';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
@Component({
  selector: 'app-change-subscribers-group',
  templateUrl: './change-subscribers-group.component.html',
})
export class ChangeSubscribersGroupComponent
  extends DsModalContentBase<ChangeSubscribersGroupModalData, ChangeSubscribersGroupCompletionData>
  implements OnInit
{
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchGroupUpdater;
  alertsManager: AlertsManager;

  constructor(
    private logger: Logger = LoggerService.shared(),
    private apiService: SoracomApiService,
    private subscribersApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
    this.alertsManager = new AlertsManager();
  }

  ngOnInit() {
    this.updater = new SubscriberBatchGroupUpdater(
      this.logger,
      this.apiService,
      // @ts-expect-error (legacy code incremental fix)
      this.modalData.subscribers,
      this.subscribersApiService,
      this.simApiService
    );

    this.logger.debug('ngOnInit():', this);
    this.logger.debug('input:', this.modalData);
  }

  onGroupChooserChange($event: LegacyAny) {
    this.logger.debug('groupChooserChange()', $event);
    this.updater.newGroupId = $event.newGroup ? $event.newGroup.groupId : false;
  }

  get atLeastOneSubscriberWillBeChanged() {
    const newGroupId = this.updater.newGroupId;
    // @ts-expect-error (legacy code incremental fix)
    return !this.modalData.subscribers.every((s) => s.groupId === newGroupId);
  }

  get canConfirm() {
    return this.updater.state !== 'busy' && this.atLeastOneSubscriberWillBeChanged;
  }

  override canClose() {
    return this.updater.state !== 'busy';
  }

  async performUpdate() {
    try {
      let group: Group | undefined;
      const groupId = this.updater.newGroupId;

      if (typeof groupId === 'string') {
        group = await groupsService.fetchById(groupId as string);
        if (!group) {
          throw new Error('ChangeSubscribersGroupComponent.error.failedToFetchGroup');
        }
      }

      const updater = await this.updater.performUpdate();
      if (updater.state === 'succeeded') {
        // Only do this if the updater succeeded. Otherwise, we want to just leave the dialog open so the user can Cancel, or try again. (The batch updater will display an error.)
        if (group) {
          const g = group;
          this.output.forEach((subscriber) => {
            subscriber.group = g;
          });
        }

        this.close({
          success: true,
          updatedSubscribers: this.updater.subscribers,
        });
      }
    } catch (error: LegacyAny) {
      this.alertsManager.add(Alert.danger(error.message || `${error}`));
    }
  }

  confirm() {
    this.performUpdate();
    return;
  }

  get output() {
    return this.updater.subscribers;
  }

  get initialGroupId(): string | undefined {
    if (!this.modalData) {
      return undefined;
    }
    if (this.modalData.newGroupId) {
      return this.modalData.newGroupId;
    }
    if (this.modalData.subscribers.length < 1) {
      return undefined;
    }
    const groupId = this.modalData.subscribers[0].groupId;

    for (const subscriber of this.modalData.subscribers) {
      if (subscriber.groupId !== groupId) {
        return undefined; // groupId values were not all same
      }
    }
    return groupId; // they were all this groupId, so return it
  }

  get explanatoryText() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers.length === 1
      ? 'ChangeSubscribersGroupComponent.explanatoryText.single'
      : 'ChangeSubscribersGroupComponent.explanatoryText.multiple';
  }
}
export interface ChangeSubscribersGroupCompletionData {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
}

export interface ChangeSubscribersGroupModalData {
  subscribers: ExtendedSubscriberInterface[];
  newGroupId?: string;
}
