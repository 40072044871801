import { animate, state, keyframes, style, trigger, transition } from '@angular/animations';

export type SlideAnimationState = 'in' | 'out' | 'left' | 'right';

export const slideAnimations = [
  trigger('slide', [
    state(
      'in',
      style({
        display: 'block',
      })
    ),
    state(
      'out',
      style({
        display: 'none',
      })
    ),
    state(
      'left',
      style({
        display: 'block',
      })
    ),
    state(
      'right',
      style({
        display: 'none',
      })
    ),
    transition('* => in', [
      animate(
        '0.5s',
        keyframes([
          style({ display: 'block', transform: 'translateX(-100%)', offset: 0 }),
          style({ transform: 'translateX(0%)', offset: 1 }),
        ])
      ),
    ]),
    transition('* => out', [
      animate(
        '0.5s',
        keyframes([
          style({ transform: 'translateX(0%)', offset: 0 }),
          style({ transform: 'translateX(-100%)', offset: 1 }),
        ])
      ),
    ]),
    transition('* => left', [
      animate(
        '0.5s',
        keyframes([
          style({ display: 'block', transform: 'translateX(100%)', offset: 0 }),
          style({ transform: 'translateX(0%)', offset: 1 }),
        ])
      ),
    ]),
    transition('* => right', [
      animate(
        '0.5s',
        keyframes([
          style({ transform: 'translateX(0%)', offset: 0 }),
          style({ transform: 'translateX(100%)', offset: 1 }),
        ])
      ),
    ]),
  ]),
];
