import { Component, OnInit } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';

import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { VirtualSessionCreator } from '../../shared/VirtualSessionCreator';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { VirtualSimConnectionInfoComponent } from '../virtual-sim-connection-info/virtual-sim-connection-info.component';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { SubscribersContainer } from 'apps/user-console/app/shared/core/SubscribersContainer';

@Component({
  selector: 'app-create-virtual-session',
  templateUrl: './create-virtual-session.component.html',
})
export class CreateVirtualSessionComponent
  extends DsModalContentBase<SubscribersContainer, ExtendedSubscriberInterface[]>
  implements OnInit
{
  className = 'CreateVirtualSessionComponent';
  // @ts-expect-error (legacy code incremental fix)
  updater: VirtualSessionCreator;
  alertManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  publicKey: string;

  constructor(
    private logger: Logger = LoggerService.shared(),
    public apiService: SoracomApiService,
    private uiDsModalService: UiDsModalService
  ) {
    super();
  }

  get output(): ExtendedSubscriberInterface[] {
    return this.updater.subscribers;
  }

  ngOnInit(): void {
    this.updater = new VirtualSessionCreator(this.logger, this.apiService, this.inputSubscribers);
  }

  get inputSubscribers(): ExtendedSubscriberInterface[] {
    return this.getInput().subscribers;
  }

  get canConfirm(): any {
    return this.updater.state !== 'busy';
  }

  get canCancel(): any {
    return this.updater.state !== 'busy';
  }

  confirm() {
    this.alertManager.clear();
    this.updater.publicKey = this.publicKey;
    this.updater.performUpdate().then((updater) => {
      if (updater.state === 'succeeded') {
        this.close(this.output);

        // Instead of giving traditional success message, show this modal so that user can get connection info and copy it for wireguard.
        this.uiDsModalService.open(VirtualSimConnectionInfoComponent, {
          data: {
            connectionInfo: updater.results[0].data,
          },
          title: 'CreateVirtualSessionComponent.successModalTitle',
          subtitle: 'CreateVirtualSessionComponent.successMessage',
        });
      } else {
        this.alertManager.add(
          Alert.fromApiError(updater.results[0]) || Alert.danger('CreateVirtualSessionComponent.unknownError')
        );
      }
    });
  }

  get confirmButtonTitle() {
    return 'CreateVirtualSessionComponent.confirmButtonTitle';
  }
}
