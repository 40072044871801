<div class="ds-rows">
  <div class="ds-notice--alert" *ngIf="!isNewSimUi">
    {{ 'subscriber_registration.vsim.vsimNotSupportedText' | translate }}
    <span [innerHtml]="'subscribers.apiMode.enableSimApiModeHelpTextBeforeLink' | translate"></span>
    <a href="" (click)="enableSimUi($event)" data-ga-event-name="switch_sim_management" data-ga-event-label="Switch to New SIM Management">
      {{ 'subscribers.apiMode.enableSimApiModeLinkText' | translate }}
    </a>
    <span [innerHtml]="'subscribers.apiMode.enableSimApiModeHelpTextAfterLink' | translate"></span>
  </div>
  <ng-container *ngIf="isNewSimUi">
    <div class="ds-notice--warning">
      <div class="notice1" [innerHtml]="'subscriber_registration.vsim.notice1' | translate"></div>
      <div class="notice1" [innerHtml]="'subscriber_registration.vsim.notice2' | translate"></div>
      <div class="notice1" [innerHtml]="'subscriber_registration.vsim.notice3' | translate"></div>
    </div>
    <app-alerts [alertsManager]="alertManager"></app-alerts>
  </ng-container>

  <div style="padding: 0; margin-bottom: -25px" dsDialogFooter>
    <ui-button-bar [content]="footerButtonBar" *ngIf="isNewSimUi"></ui-button-bar>
    <ui-button [content]="cancelButton" *ngIf="!isNewSimUi"></ui-button>
  </div>
</div>
