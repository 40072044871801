import { LegacyAny } from '@soracom/shared/core';

import { Component, Inject, OnInit } from '@angular/core';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { OrdersService } from '../../../../../app/shared/orders/orders.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { MODAL_DATA, UiDsModalRef } from '@soracom/shared-ng/ui-ds-modal';
import { UiTab } from '../../../soracom-ui/ui-tabs/UiTab';
import { UiTabs } from '../../../soracom-ui/ui-tabs/UiTabs';

enum Tabs {
  FROM_ORDER,
  DIRECT,
  VIRTUAL_SIM,
}

@Component({
  templateUrl: './register-sim-modal.component.html',
})
export class RegisterSimModalComponent implements OnInit {
  tabs = new UiTabs();
  tabContents: RegisterSimModalTabs = {
    fromOrder: UiTab.configure((uiTab) => {
      uiTab.labelId = 'subscriber_registration.from_order';
      uiTab.tabContentClasses = ['--no-indent'];
    }),
    direct: UiTab.configure((uiTab) => {
      uiTab.labelId = 'subscriber_registration.direct_input';
    }),
    virtualSim: UiTab.configure((uiTab) => {
      uiTab.labelId = 'subscriber_registration.vsim.title';
    }),
  };
  alertManager = new AlertsManager();
  isLoading = false;

  orders: LegacyAny;

  constructor(
    @Inject(MODAL_DATA) public data: LegacyAny,
    public modalRef: UiDsModalRef,
    private ordersService: OrdersService,
    private coverageService: CoverageTypeService
  ) {
    this.tabs.selectedTabIndex = Tabs.DIRECT;
  }

  ngOnInit() {
    this.setupConditionalTabs();
    this.reload();
  }

  private setupConditionalTabs() {
    if (this.coverageService.isGlobal()) {
      this.tabContents.authenticationPasscode = UiTab.configure((uiTab) => {
        uiTab.labelId = 'registerSimByPasscode.tabTitle';
      });
    }
  }

  close = (isCancel = true) => {
    this.modalRef.close();

    if (!isCancel) {
      this.data.updateSubscribersTable();
    }
  };

  isNewSimUi() {
    return this.data.apiMode === 'sim';
  }

  async reload() {
    try {
      this.isLoading = true;
      this.orders = await this.ordersService.getOrdersWaitingRegistration();

      if (this.orders?.length) {
        this.tabs.selectedTabIndex = Tabs.FROM_ORDER;
      }
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'common.error_messages.unknown_error'));
    } finally {
      this.isLoading = false;
    }
  }

  onOrderUpdated() {
    this.reload();
    this.alertManager.add(Alert.success('simInitialSettings.updateSuccess'));
  }
}

interface RegisterSimModalTabs {
  fromOrder: UiTab;
  direct: UiTab;
  virtualSim: UiTab;
  authenticationPasscode?: UiTab;
}
