<section dsDialogContent>
  <div class="ds-rows">
    <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>
  </div>

  <form class="ds-rows">
    <div class="ds-cols --center">
      <div>&nbsp;&nbsp;<i class="glyphicon glyphicon-arrow-down"></i></div>
    </div>

    <div *ngIf="isLockModal" class="ds-rows">
      <div *ngIf="isSingleSubscriber" class="ds-cols --center">
        <div>{{ 'set_imei_lock.new_status' | translate }}</div>
        <div>{{ 'set_imei_lock.lock_to_designated_IMEI' | translate }}
        </div>
      </div>
      <div *ngIf="!isSingleSubscriber" class="ds-cols --center">
        <div> {{ 'set_imei_lock.new_status' | translate }} </div>
        <div>
            {{ 'set_imei_lock.lock_to_current_IMEI' | translate }}
        </div>
      </div>
    </div>

    <div *ngIf="!isLockModal" class="ds-rows">
      <div class="ds-cols --center">
        <div>{{ 'unset_imei_lock.new_status' | translate }}</div>
        <div>{{ 'unset_imei_lock.remove_imei_lock' | translate }}</div>
      </div>
      <div class="ds-text">
        <p>
          <em>
            <small class="text-muted">
              {{ 'unset_imei_lock.remove_imei_lock_details' | translate }}
            </small>
          </em>
        </p>
      </div>
    </div>

    <!-- Only for "Lock" modal -->
    <div *ngIf="isLockModal">
      <div *ngIf="isSingleSubscriber" class="ds-field">
        <label class="ds-text --label"> IMEI </label>
        <!-- FIXME: Use translate -->
        <div class="ds-input">
          <input
            class="modal-set-imei-lock-imei-input"
            [(ngModel)]="imeiToSet"
            name="imeiToSet"
            placeholder="{{ imeiPlaceholder }}"
            maxlength="16"
            uiFocusMe
          />
        </div>
        <div class="ds-text">
          <p>
            <em>
              <small class="text-muted">
                {{ 'set_imei_lock.lock_to_designated_IMEI_details' | translate }}
              </small>
            </em>
          </p>
        </div>
      </div>

      <div *ngIf="!isSingleSubscriber" class="ds-text">
        <p>
          {{ 'set_imei_lock.lock_to_current_IMEI_details' | translate }}
        </p>
      </div>
    </div>
    <div *ngIf="isLockModal">
      <label class="ds-checkbox">
        <input type="checkbox" [(ngModel)]="allowAnonymousIMEI" [ngModelOptions]="{standalone: true}">
        <div>{{ i18n.allowConnectionsWhenImsiIsNotAvailable | localize }}</div>
      </label>
    </div>
  </form>

</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm" [uiFocusMe]="!(isLockModal && isSingleSubscriber)">
    <span [innerHTML]="confirmButtonTitle | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'generic.button_title.cancel' | translate"></span>
  </button>
</footer>
