import { Injectable, OnDestroy } from '@angular/core';
import { CoverageType } from '@foundation/coverage-type';
import { BroadcastEvent } from '../../../../../libs/shared/core/src';

const EVENT_NAME = 'feature_sims_broadcast_event';

interface EventData {
  updateSimsTable: boolean;
  simId: string;
  coverageType: CoverageType;
}

@Injectable()
export class FeatureSimsBroadcaster extends BroadcastEvent<typeof EVENT_NAME, EventData> implements OnDestroy {
  constructor() {
    super(EVENT_NAME);
  }

  ngOnDestroy() {
    this.disconnect();
  }
}
