<div class="pcs-container">
  <div class="child ds-rows" [@slide]="selection.mainAnimState$ | async" dsLoadingIndicator>
    <header class="ds-banner --plain --no-indent">
      <div>
        <h4>{{ 'simPacketCaptureSessions.title' | translate }}</h4>
      </div>
      <ui-button-bar [content]="topButtonBar"></ui-button-bar>
    </header>

    <ui-progress-indicator [content]="progressIndicator" *ngIf="isFetching"> </ui-progress-indicator>

    <table class="ds-datatable --embedded --compact">
      <thead>
        <tr>
          <th>{{ 'simPacketCaptureSessions.table.columns.createdTime' | translate }}</th>
          <th>{{ 'simPacketCaptureSessions.table.columns.startedTime' | translate }}</th>
          <th class="--min">{{ 'simPacketCaptureSessions.table.columns.status' | translate }}</th>
          <th [innerHTML]="'simPacketCaptureSessions.table.columns.prefix' | translate"></th>
          <th class="--right">
            {{ 'simPacketCaptureSessions.table.columns.duration' | translate }}
          </th>
          <th>{{ 'simPacketCaptureSessions.table.columns.capturedFile' | translate }}</th>
          <th>{{ 'simPacketCaptureSessions.table.columns.packetCaptureAction' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let session of simPacketCaptureSessionService.sessions$ | async"
          simSessionDataRow
          [simId]="simId"
          [session]="session"
        ></tr>
      </tbody>
    </table>

    <div class="ds-span--12" *ngIf="simPacketCaptureSessionService.hasNextPage()">
      <ui-button [content]="loadMoreButton"></ui-button>
    </div>
  </div>

  <div class="child" [@slide]="selection.panelAnimState$ | async">
    <sim-start-capturing-session
      *ngIf="(selection.selectedPanel$ | async) === 'request'"
      [simId]="simId"
    >
    </sim-start-capturing-session>

    <sim-delete-capture-session
      *ngIf="(selection.selectedPanel$ | async) === 'delete'"
      [simId]="simId"
    >
    </sim-delete-capture-session>

    <sim-stop-capture-session
      *ngIf="(selection.selectedPanel$ | async) === 'stop'"
      [simId]="simId"
    >
    </sim-stop-capture-session>
  </div>
</div>
<app-alerts [alertsManager]="alertManager"></app-alerts>
