import { LegacyAny } from '@soracom/shared/core';

import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PortMappingUi } from '../../port-mappings-api.service';

@Component({
  selector: 'app-port-mapping-details',
  templateUrl: './port-mapping-details.component.html',
  styleUrls: ['./port-mapping-details.component.scss'],
})
export class PortMappingDetailsComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() portMapping: PortMappingUi;

  constructor(private sanitizer: DomSanitizer) {
    // Hi, Mom!
  }

  get hostname() {
    return this.portMapping && this.portMapping.hostname;
  }

  get ipAddress() {
    return this.portMapping && this.portMapping.ipAddress;
  }

  get port() {
    return this.portMapping && this.portMapping.destination.port;
  }

  get externalPort() {
    return this.portMapping.port;
  }

  get tls() {
    return this.portMapping && this.portMapping.tlsRequired;
  }

  get allowedIpRanges() {
    let result = '';
    const source = this.portMapping.source;
    if (source && source.ipRanges && source.ipRanges.length > 0) {
      result += source.ipRanges.join(', ');
    }

    return result;
  }

  get sshConnectionExample() {
    return `ssh -p ${this.externalPort} ${this.hostname} -l user`;
  }

  get httpConnectionExample() {
    return `http://${this.hostname}:${this.externalPort}`;
  }

  get httpsConnectionExample() {
    return `https://${this.hostname}:${this.externalPort}`;
  }

  get shouldShowVncConnectionExample() {
    return this.port === 5900;
  }

  get vncConnectionExample() {
    return `vnc://${this.hostname}:${this.externalPort}`;
  }

  // @ts-expect-error (legacy code incremental fix)
  _rdpProfileLink: SafeUrl;

  get rdpProfileRawUrl() {
    const fileContent = ['auto connect:i:1', `full address:s:${this.ipAddress}:${this.externalPort}`].join('\n');
    const data = new Blob([fileContent], { type: 'application/x-rdp' });
    return data;
  }

  get rdpProfileLink() {
    if (!this._rdpProfileLink) {
      this._rdpProfileLink = this.sanitize(window.URL.createObjectURL(this.rdpProfileRawUrl));
    }
    return this._rdpProfileLink;
  }

  get rdpDefaultFileName() {
    return `${this.hostname}-port-${this.port}.rdp`;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * MS browsers, even Edge, can't DL a blob link. But they have this legacy method we can use instead.
   */
  processRdpProfileLinkClickForDefectiveBrowser($event: LegacyAny) {
    const nav = window.navigator as any;
    if (nav && nav.msSaveOrOpenBlob) {
      // console.log("processRdpProfileLinkClickForDefectiveBrowser()...");
      nav.msSaveOrOpenBlob(this.rdpProfileRawUrl, this.rdpDefaultFileName);
      $event.preventDefault();
    }
  }
}
