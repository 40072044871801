import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';

import { i18n } from './SimUpdateHistoryI18n';

@Component({
  selector: 'ui-sim-update-history-filter-by-imsi',
  standalone: true,
  imports: [CommonModule, FormsModule, SoracomI18nModule],
  template: `
    <span class="ds-text --label">
      {{ i18n.message.filterByImsi | localize }}
    </span>
    <label class="ds-tag --medium --outline --no-case">
      <input
        type="checkbox"
        [attr.data-testid]="'imsi-checkbox-all'"
        [(ngModel)]="filterByImsiIsDisabled"
        (change)="filterByImsiChanged($event)"
      /><span>All</span>
    </label>
    <label
      *ngFor="let possibleImsi of possibleImsis"
      class="ds-tag --medium --outline --no-case"
    >
      <input
        type="checkbox"
        [attr.data-testid]="'imsi-checkbox-{{ possibleImsi }}'"
        [(ngModel)]="_selectedImsis[possibleImsi]"
        (change)="selectionChange($event)"
        [disabled]="filterByImsiIsDisabled"
      />
      <span>
        {{ possibleImsi }}
      </span>
    </label>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSimUpdateHistoryFilterByImsiComponent {
  protected i18n = i18n;
  filterByImsiIsDisabled = true;

  protected _selectedImsis: Record<string, boolean> = {};

  private _lastSelectedImsis: Record<string, boolean> = {};

  private _possibleImsis: string[] = [];

  @Input() set possibleImsis(newValue: string[]) {
    this._possibleImsis = newValue;
    this._selectedImsis = {};
    this._lastSelectedImsis = {};
    for (const imsi of newValue) {
      this._selectedImsis[imsi] = true;
      this._lastSelectedImsis[imsi] = true;
    }
  }

  get possibleImsis(): string[] {
    return this._possibleImsis;
  }

  @Output() filteredImsiChange = new EventEmitter<string[]>();

  filterByImsiChanged($event: any) {
    for (const imsi of this.possibleImsis) {
      this._selectedImsis[imsi] =
        this.filterByImsiIsDisabled || this._lastSelectedImsis[imsi];
    }

    this.selectionChange($event);
  }

  selectionChange($event: any) {
    if (this.filterByImsiIsDisabled) {
      const selectedImsis = this.possibleImsis;
      for (const imsi of selectedImsis) {
        this._selectedImsis[imsi] = true;
      }
      this.filteredImsiChange.emit(selectedImsis);
    } else {
      const selectedImsis = Object.keys(this._selectedImsis).filter(
        (imsi) => this._selectedImsis[imsi]
      );
      this.filteredImsiChange.emit(selectedImsis);

      this._lastSelectedImsis = { ...this._selectedImsis };
    }
  }
}
