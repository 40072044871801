<section dsDialogContent>
    <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>
</section>

<footer dsDialogFooter>
    <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
      <span [innerHTML]="'CancelTransferSimOperatorComponent.confirm' | translate"></span>
    </button>
    <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
      <span [innerHTML]="'CancelTransferSimOperatorComponent.cancel' | translate"></span>
    </button>
</footer>
