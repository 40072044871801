import { UiFixedRateSimDataUsageDetails } from '../fixed-rate-sim.service';
import { TopUpAmounts } from '../fixed-rate-sim.service';
import { xYearsFromNow } from './timeUtils';

export function calculateCurrentRemainingFixedRateSimUsage(
  simUsageDetails: UiFixedRateSimDataUsageDetails
): RemainingSimUsageData {
  return {
    imsi: simUsageDetails.imsi ?? '',
    remainingData:
      simUsageDetails.maximumAllowedDataUsage - simUsageDetails.dataUsage,
    remainingSms:
      simUsageDetails.maximumAllowedSmsUsage - simUsageDetails.smsUsage,
    remainingUssd:
      simUsageDetails.maximumAllowedUssdUsage - simUsageDetails.ussdUsage,
    expiry: simUsageDetails.expiryDate,
  };
}

export function calculateRemainingSimUsageAfterTopUp(
  simUsageDetails: UiFixedRateSimDataUsageDetails,
  topUpAmounts: TopUpAmounts
): RemainingSimUsageData {
  const remainingSimUsage =
    calculateCurrentRemainingFixedRateSimUsage(simUsageDetails);
  return {
    imsi: remainingSimUsage.imsi,
    remainingData: remainingSimUsage.remainingData + topUpAmounts.data,
    remainingSms: remainingSimUsage.remainingSms + topUpAmounts.sms,
    remainingUssd: remainingSimUsage.remainingUssd + topUpAmounts.ussd,
    expiry: xYearsFromNow(topUpAmounts.expiry),
  };
}

export interface RemainingSimUsageData {
  imsi: string;
  remainingData: number;
  remainingSms: number;
  remainingUssd: number;
  expiry?: string;
}
