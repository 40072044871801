<div dsDialogContent>
  <div class="ds-rows">
    <div class="ds-notice--success" *ngIf="data?.successMessage">
      {{ data.successMessage | translate }}
    </div>
    <dl class="ds-details" *ngIf="data?.connectionInfo?.simId">
      <dt>{{ 'virtualSimConnectionInfo.vsimId' | translate }}</dt>
      <dd>
        <div>
          {{ data.connectionInfo.simId }}
        </div>
      </dd>
    </dl>

    <div class="ds-notice --info" *ngIf="data && data.connectionInfo && !data.connectionInfo.arcClientPeerPrivateKey">
      {{ 'virtualSimConnectionInfo.privateKeyWontGenerateInfo' | translate }}
    </div>

    <div class="ds-group">
      <p
        *ngIf="!data?.connectionInfo?.arcClientPeerPrivateKey"
        [innerHtml]="'virtualSimConnectionInfo.connectionInfoLabel' | translate"
      ></p>
      <p
        *ngIf="data?.connectionInfo?.arcClientPeerPrivateKey"
        [innerHTML]="'virtualSimConnectionInfo.wireguardQrHelpText' | translate"
      ></p>
      <div class="ds-cols">
        <div class="ds-span" [ngStyle]="{ display: data?.connectionInfo?.arcClientPeerPrivateKey ? 'block' : 'none' }">
          <details class="ds-toggle">
            <summary>
              <span class="ds-tag">{{ 'virtualSimConnectionInfo.showQrCodeLabel' | translate }}</span>
              <span class="--cover"></span>
            </summary>
          </details>
          <canvas
            id="wireguardQrCode"
            [ngStyle]="{ display: !data?.connectionInfo?.arcClientPeerPrivateKey ? 'none' : 'block' }"
          ></canvas>
        </div>

        <div class="ds-span --expand">
          <div class="ds-field">
            <div class="ds-input --copy">
              <details class="ds-toggle" *ngIf="data?.connectionInfo?.arcClientPeerPrivateKey">
                <summary>
                  <span class="ds-tag">{{ 'virtualSimConnectionInfo.showKeysLabel' | translate }}</span>
                  <span class="ds-tag --end --bottom">{{ 'virtualSimConnectionInfo.hideKeysLabel' | translate }}</span>
                </summary>
              </details>
              <pre>{{ textToCopy(data.connectionInfo) }}</pre>
              <pre aria-hidden="true" *ngIf="data?.connectionInfo?.arcClientPeerPrivateKey">{{ hideSecretKeys(data.connectionInfo) }}</pre>
              <button
                class="ds-button --plain --hide-label --icon-clipboard connection-info"
                [attr.data-clipboard-text]="textToCopy(data.connectionInfo)"
                [ngClass]="copyButtonClass"
                [attr.data-ds-texttip]="copyButtonSuccessTip"
                (mouseout)="onCopyButtonMouseOut()"
              >
                <span>{{ 'virtualSimConnectionInfo.copyCodeLabel' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-alerts [alertsManager]="copyAlertManager"></app-alerts>
      <div
        class="ds-notice--warning --icon-hidden"
        [innerHtml]="'virtualSimConnectionInfo.privateKeyInfo' | translate"
        *ngIf="data?.connectionInfo?.arcClientPeerPrivateKey"
      ></div>
    </div>
  </div>
</div>
<footer dsDialogFooter>
  <ui-button [content]="closeButton" class="x-reinitialize-sim-modal-close-button"></ui-button>
</footer>
