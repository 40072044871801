<section dsDialogContent>
  <div class="ds-text" [innerHtml]="explanatoryText | translate"></div>
  <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>
  <div class="ds-text" [innerHtml]="cautionaryText | translate"></div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" [class.--alert]="updater.state === 'failed'" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="confirmButtonTitle | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
