<div class="ds-richtip --bottom">
  <i class="ds-richtip__button ds-icon --xxsmall --icon-info"></i>
  <div class="ds-richtip__content ds-rows">
    <div class="table-speed-class-legend table-responsive">
      <table class="ds-datatable --condensed">
        <thead>
          <tr>
            <th>Speed class</th>
            <th>Upload</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let speedClass of speedClasses" style="background: unset">
            <td>{{ speedClass.label }}</td>
            <td>{{ speedClass.speeds.up }}</td>
            <td>{{ speedClass.speeds.down }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="ds-text --xsmall" [innerHTML]="'speed_class_legend.notice' | translate"></div>
  </div>
</div>
