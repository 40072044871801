import { LegacyAny } from '@soracom/shared/core';

import { Component } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SubscriberBatchCancelTransferSimUpdater } from '../../shared/SubscriberBatchTransferCanceler';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

@Component({
  selector: 'app-cancel-transfer-sim-operator',
  templateUrl: './cancel-transfer-sim-operator.component.html',
})
export class CancelTransferSimOperatorComponent extends DsModalContentBase<
  CancelTransferSimOperatorModalData,
  CancelTransferSimOperatorCompletionData
> {
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchCancelTransferSimUpdater;
  alertsManager: AlertsManager;

  constructor(
    private logger: Logger = LoggerService.shared(),
    private apiService: SoracomApiService,
    private subscriberApiService: SubscriberApiService
  ) {
    super();
    this.alertsManager = new AlertsManager();
  }

  ngOnInit() {
    this.updater = new SubscriberBatchCancelTransferSimUpdater(
      this.logger,
      this.apiService,
      this.subscribers,
      this.subscriberApiService
    );
  }

  get subscribers() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers;
  }

  confirm() {
    this.updater
      .performUpdate()
      .then((result: LegacyAny) => {
        if (result.state === 'succeeded') {
          const completionData: CancelTransferSimOperatorCompletionData = {
            success: true,
            // @ts-expect-error (legacy code incremental fix)
            updatedSubscribers: this.modalData.subscribers,
          };
          this.close(completionData);
        }
        // Otherwise, do nothing. The batch updater handles error messages. The user may retry, or cancel.
      })
      .catch((error: LegacyAny) => {
        this.alertsManager.add(Alert.danger(error.message || `${error}`));
      });
  }

  get canConfirm() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers.every((s) => s.isTransferring());
  }

  override canClose() {
    return this.updater.state !== 'busy';
  }
}

export interface CancelTransferSimOperatorCompletionData {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
}

export interface CancelTransferSimOperatorModalData {
  subscribers: ExtendedSubscriberInterface[];
}
