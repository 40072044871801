<form class="ds-rows" [formGroup]="form" (ngSubmit)="confirm()">
  <div class="ds-rows">
    <div class="ds-field">
      <label class="ds-text --label" for="firstIccid" [innerHtml]="'registerSimByPasscode.firstIccidLabel' | translate"></label>
      <div class="ds-input">
        <input
          id="firstIccid"
          formControlName="firstIccid"
          placeholder="{{'registerSimByPasscode.firstIccidPlaceholder' | translate}}"
          type="text"
        />
      </div>
    </div>
    <div class="ds-field">
      <label class="ds-text --label" for="lastIccid" [innerHtml]="'registerSimByPasscode.lastIccidLabel' | translate"></label>
      <div class="ds-input">
        <input
          id="lastIccid"
          formControlName="lastIccid"
          placeholder="{{'registerSimByPasscode.lastIccidPlaceholder' | translate}}"
          type="text"
        />
      </div>
    </div>
    <div class="ds-field">
      <label class="ds-text --label" for="registrationSecret" [innerHtml]="'registerSimByPasscode.registrationSecretLabel' | translate"></label>
      <div class="ds-input">
        <input
          id="registrationSecret"
          formControlName="registrationSecret"
          placeholder="{{'registerSimByPasscode.registrationSecretPlaceholder' | translate}}"
          type="text"
          maxlength="9"
        />
      </div>
    </div>
    <app-alerts [alertsManager]="alertManager"></app-alerts>
  </div>
  <div dsDialogFooter>
    <button class="ds-button --primary x-confirm" [ngClass]="{'--loading': awaitingResult}" [disabled]="form.invalid || awaitingResult" type="submit">
      <span [innerHTML]="'registerSimByPasscode.registerButton' | translate"></span>
    </button>
    <button class="ds-button" (click)="close()" [disabled]="awaitingResult">
      <span [innerHTML]="'generic.button_title.close' | translate"></span>
    </button>
  </div>
</form>
