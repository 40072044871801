
<tbody>
    <tr *ngFor="let subscriber of subscribers">
    <td>
        <span class="ds-text --xsmall">{{subscriber.imsi}}</span>
    </td>
    <td><span class="ds-text --xsmall">{{subscriber.subscription}}</span></td>
    <td>
        <div class="ds-bar --middle --no-wrap --gap-xxsmall">
        <span class="ds-text --no-wrap --xsmall" [class.--success]="isSubscriberOnline(subscriber)" [class.--icon-active-online]="subscriber.status === 'active' && isSubscriberOnline(subscriber)"
        [class.--icon-active-offline]="subscriber.status === 'active' && !isSubscriberOnline(subscriber)">{{('subscribers.status.' + subscriber.status) | translate}}</span>
        <span class="ds-tag --small" [class.--success]="isSubscriberOnline(subscriber)" [class.--outline]="!isSubscriberOnline(subscriber)"><span>{{(isSubscriberOnline(subscriber) ? 'on-demand-remote-access.online' : 'on-demand-remote-access.offline') | translate}}</span></span>
        </div>
    </td>
    </tr>
</tbody>