import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { formatDateTime } from '@soracom/shared/util-common';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SimDetailsService } from '../sim-details.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Internal notion of what mode the component is in, in terms of the UI that should be displayed. */
enum LocalInfoUiMode {
  submitting = 'submitting',
  ineligible = 'ineligible',
  nonexistent = 'nonexistent',
  pending = 'pending',
  failed = 'failed',
  complete = 'complete',
}

@Component({
  selector: 'app-sim-local-info',
  templateUrl: './sim-local-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimLocalInfoComponent implements OnDestroy {
  // @ts-expect-error (legacy code incremental fix)
  subscriber: ExtendedSubscriberInterface;

  submitting = false;
  /** Human-friendly translation of SIM local info report data, used for UI binding. */
  localInfoDisplayData: any = {};
  alertManager = new AlertsManager();
  /** Keeps track of the promise for polling, so that it can be cancelled. */
  pollTimer: any;

  /** An increasing number of seconds to wait before polling the API again for a pending local info report. */
  pollBackoffFactor = 0;

  constructor(
    private soracomApi: SoracomApiService,
    private simDetailsService: SimDetailsService,
    private cdRef: ChangeDetectorRef
  ) {
    simDetailsService.subscriber.pipe(takeUntilDestroyed()).subscribe((sub) => {
      this.subscriber = sub;
      if (sub) {
        if (this.subscriber.localInfo) {
          this.updateLocalInfoDisplayData(this.subscriber.localInfo);
        }
        this.requestData(false);
      }
    });
  }

  ngOnDestroy() {
    this.stopPolling();
  }

  /** Send API request to trigger the SIM to generate a new local info report. */
  requestReport() {
    this.requestData(true);
  }

  /** Calls API, either to reportLocalInfo() or getSubscriberRecordByIMSI(), to update subscriber's data. We use one primitive method to handle both types of request because the behavior to handle the response is basically identical. */
  async requestData(requestNewReportGeneration: boolean) {
    this.alertManager.clear();
    if (requestNewReportGeneration) {
      this.submitting = true;
      this.stopPolling();
    }

    const apiCallPromise = requestNewReportGeneration
      ? this.soracomApi.reportLocalInfoForSubscriber(this.subscriber)
      : this.soracomApi.getSubscriberRecord(this.subscriber);

    try {
      const response = await apiCallPromise;
      const newLocalInfo = response.data.localInfo;
      this.updateLocalInfoDisplayData(newLocalInfo);
      if (!this.subscriber.localInfo) {
        this.subscriber.localInfo = {};
      }
      this.subscriber.localInfo = structuredClone(newLocalInfo);
      this.startPollIfNeeded();
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
      this.stopPolling();
    } finally {
      this.submitting = false;
    }
    this.cdRef.markForCheck();
  }

  /** Starts polling the API for updates to the subscriber, which is necessary when a SIM local info report is pending. */
  startPollIfNeeded() {
    if (this.pollTimer) {
      clearInterval(this.pollTimer);
    }
    if (!this.subscriber?.localInfo) {
      return;
    }
    if (this.subscriber.localInfo.status !== 'pending') {
      return;
    }
    this.pollBackoffFactor += 2;
    if (this.pollBackoffFactor > 20) {
      this.pollBackoffFactor = 20;
    }
    const pollFunc = () => {
      this.requestData(false);
    };

    this.pollTimer = setInterval(pollFunc, this.pollBackoffFactor * 1000);
    this.cdRef.markForCheck();
  }

  /** Stops the polling loop that checks for updates to a 'pending' local info report. */
  stopPolling() {
    if (this.pollTimer) {
      clearInterval(this.pollTimer);
      this.pollBackoffFactor = 0;
      this.cdRef.markForCheck();
    }
  }

  /**
   * Converts the raw localInfo values to something more suitable for display to end user,
   * and caches the results in this.localInfoDisplayData, which the UI binds to.
   */
  updateLocalInfoDisplayData(localInfo: LegacyAny) {
    const report = localInfo || {};
    const newData: LegacyAny = {};

    for (const key in report) {
      let val = report[key];
      // We will modify this val for the special cases only.

      if (key === 'location') {
        const radioType = val.radioType;

        if (['lte', 'utran', 'gsm'].indexOf(radioType) !== -1) {
          let translatedLocation = '';

          if (val.lac) {
            translatedLocation += `Location area code: ${val.lac}<br>`;
          }
          if (val.eci) {
            translatedLocation += `Cell identification: ${val.eci}<br>`;
          }
          if (val.tac) {
            translatedLocation += `Tracking area code: ${val.tac}<br>`;
          }
          if (val.mnc) {
            translatedLocation += `Mobile network code: ${val.mnc}<br>`;
          }
          if (val.mcc) {
            translatedLocation += `Mobile country code: ${val.mcc}<br>`;
          }
          if (val.ci) {
            translatedLocation += `Cell identity: ${val.ci}<br>`;
          }
          translatedLocation += 'Radio type: ';
          if (radioType === 'lte') {
            translatedLocation += 'LTE (lte)';
          } else if (radioType === 'gsm') {
            translatedLocation += '2G (gsm)';
          } else if (radioType === 'utran') {
            translatedLocation += '3G (utran)';
          } else {
            translatedLocation += radioType;
          }

          val = translatedLocation;
        }
      } else if (key === `createdTime`) {
        val = formatDateTime(val, 'datetime_sec');
      } else if (key === `lastModifiedTime`) {
        val = formatDateTime(val, 'datetime_sec');
      } else {
        // key is not one we translate
      }
      newData[key] = val;
    }
    this.localInfoDisplayData = structuredClone(newData);
    this.cdRef.markForCheck();
  }

  /** Provides the mode to the UI layer to conditionalize display. */
  getUiMode() {
    if (this.submitting) {
      return LocalInfoUiMode.submitting;
    }

    if (!this.subscriber || !this.subscriber.isEligibleForLocalInfoReport()) {
      return LocalInfoUiMode.ineligible;
    } else if (!this.subscriber.localInfo) {
      return LocalInfoUiMode.nonexistent;
    } else if (this.subscriber.localInfo.status === 'pending') {
      return LocalInfoUiMode.pending;
    } else if (this.subscriber.localInfo.status === 'failed') {
      return LocalInfoUiMode.failed;
    } else {
      return LocalInfoUiMode.complete;
    }
  }
}
