import { LegacyAny } from '@soracom/shared/core';

import { Directive } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { assertNever } from '@soracom/shared/core';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

@Directive()
export class SubscriberBatchSimTerminateProtectionUpdater extends SubscriberBatchUpdater {
  newTerminationProtectionState: 'enabled' | 'disabled' = 'disabled';
  constructor(
    logger: Logger,
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }

  /**
   * Function which actually calls api to turn on/off protection.
   * It is used for two operations: turn on and off protection, Which is identified
   * by `operation` and particular api is called accordingly.
   */
  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    return subscriber.isSim ? this.updateSim(subscriber) : this.updateLegacySubscriber(subscriber);
  }

  // @ts-expect-error (legacy code incremental fix)
  updateLegacySubscriber(subscriber: ExtendedSubscriberInterface): Promise<any> {
    switch (this.newTerminationProtectionState) {
      case 'enabled':
        return this.subscriberApiService.disableTermination({ imsi: subscriber.imsi }).then((response: LegacyAny) => {
          subscriber.terminationEnabled = false;
          return response;
        });
      case 'disabled':
        return this.subscriberApiService.enableTermination({ imsi: subscriber.imsi }).then((response: LegacyAny) => {
          subscriber.terminationEnabled = true;
          return response;
        });
      default:
        assertNever(this.newTerminationProtectionState);
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  updateSim(subscriber: ExtendedSubscriberInterface): Promise<any> {
    switch (this.newTerminationProtectionState) {
      case 'enabled':
        return this.simApiService.disableSimTermination({ simId: subscriber.simId }).then((response: LegacyAny) => {
          subscriber.terminationEnabled = false;
          return response;
        });
      case 'disabled':
        return this.simApiService.enableSimTermination({ simId: subscriber.simId }).then((response: LegacyAny) => {
          subscriber.terminationEnabled = true;
          return response;
        });
      default:
        assertNever(this.newTerminationProtectionState);
    }
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.terminateSimProtection`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    const label = subscriber.terminationProtectionIsOn ? 'generic.label.on' : 'generic.label.off';
    return LegacyTextContent.translation(label);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    const proposedNewTPState = this.newTerminationProtectionState === 'enabled';
    if (subscriber.terminationProtectionIsOn === proposedNewTPState) {
      return undefined;
      // This causes the proposed new state to not be displayed, in which case the → is also not displayed. That is suitable for this use case (e.g. it will be like "OFF" reads like "won't be changed" while the ones that will be changed will be like "OFF → ON")
    }

    const label = this.newTerminationProtectionState === 'enabled' ? 'generic.label.on' : 'generic.label.off';
    return LegacyTextContent.translation(label);
  }

  successDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    const label = subscriber.terminationProtectionIsOn ? 'generic.label.on' : 'generic.label.off';
    return LegacyTextContent.translation(label);
  }
}
