
<section dsDialogContent>
  <div [innerHTML]="'TransferSimOperatorComponent.message' | translate"></div>
  <table stTable class="ds-datatable --embedded">

    <ng-container stColumnDef="name">
      <th *stHeaderCell [innerHTML]="'SubscriberBatchChangeComponent.columns.name' | translate"></th>
      <td *stCell="let item" [innerHtml]="item.name"></td>
    </ng-container>

    <ng-container stColumnDef="group">
      <th *stHeaderCell [innerHTML]="'SubscriberBatchChangeComponent.columns.group' | translate"></th>
      <td *stCell="let item">
       <ui-span [content]="groupName(item.groupId)"></ui-span>
     </td>
    </ng-container>

    <ng-container stColumnDef="simid">
      <th *stHeaderCell [innerHTML]="'SubscriberBatchChangeComponent.columns.sim_id' | translate"></th>
      <td *stCell="let item"><uc-simid [simId]="item.simId"></uc-simid></td>
    </ng-container>

    <tr *stRowDef="let item" [stRow]="item"></tr>
  </table>

  <form
     name="transferForm"
     (ngSubmit)="transferForm.valid"
     #transferForm="ngForm"
     class="ds-rows"
   >
     <div class="ds-field">
       <label class="ds-text --label" for="destination-operator-email">
         {{ 'transfer.operator_email' | translate }}
       </label>
       <div class="ds-input">
        <input
          autocomplete="off"
          id="destination-operator-email"
          type="text"
          placeholder="{{ 'transfer.operator_email' | translate }}"
          name="operatorEmail"
          [(ngModel)]="operatorEmail"
          required="true"
          uiFocusMe
          style="max-width: 20em"
        />
       </div>
     </div>
     <div class="ds-field">
       <label class="ds-text --label" for="destination-operator-id">
         {{ 'transfer.operator_id' | translate }}
       </label>
       <div class="ds-input">
        <input
          autocomplete="off"
          id="destination-operator-id"
          type="text"
          placeholder="{{ 'transfer.operator_id' | translate }}"
          name="operatorId"
          [(ngModel)]="operatorId"
          [pattern]="operatorIdPattern"
          required="true"
          style="max-width: 20em"
        />
       </div>
     </div>

   </form>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary x-transfer-sim-operator-confirm" (click)="submit()" [disabled]="busy || !transferForm.valid">
    <span [innerHTML]="'TransferSimOperatorComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()">
    <span [innerHTML]="'TransferSimOperatorComponent.cancel' | translate"></span>
  </button>
</footer>
