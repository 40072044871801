import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prefixed-event-handlers',
  template: `<legacy-prefixed-event-handlers-page></legacy-prefixed-event-handlers-page>`,
})
export class PrefixedEventHandlersPageComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  ngOnInit() {}
}
