import { LegacyAny } from '@soracom/shared/core';

import { Directive } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from '../../../app/shared/components/soracom_api.service';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { ExtendedSubscriber as LegacySubscriber, ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { AbstractObject, LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { SoracomUserConsole } from './SoracomUserConsole';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';

export enum BatchUpdateStatus {
  waiting = 'waiting',
  updating = 'updating',
  succeeded = 'succeeded',
  failed = 'failed',
}

/**
 * Batch updater to perform operations on a set of subscribers where the API supports only one-at-a-time updates.
 *
 * NOTES: This might be better designed as a service, but to avoid annoying problems caused by the hybrid AngularJS/Angular app, for now it is just a plain TypeScript object that you pass dependencies into manually instead of via Angular dependency injection.
 */
@Directive()
export abstract class SubscriberBatchUpdater extends AbstractObject {
  /**
   * We take a `Subscriber[]` as the subscribers parameter here, but it needs to actually be a `LegacySubscriber[]` or a `Sim[]` — not a mix of different types of `Subscriber` implementor.
   */
  constructor(
    logger: Logger = LoggerService.shared(),
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
  ) {
    super(logger);
    this.traceEnabled = true;

    // Setting the `subscribers` property deep-clones the supplied `Subscriber[]` value, so that the original subscribers are not inadvertently modified.
    this._subscribers = subscribers ? subscribers.map((subscriber) => subscriber.clone()) : [];

    const foundSim = this._subscribers.find((x) => x.isSim);
    const foundSubscriber = this._subscribers.find((x) => !x.isSim);

    if (foundSim && foundSubscriber) {
      throw new Error(
        'SubscriberBatchUpdater cannot be initialized with mixed subscribers containing both Sim and LegacySubscriber objects.',
      );
    }

    this.concreteSubscriberClass = foundSim ? Sim : LegacySubscriber;
  }

  concreteSubscriberClass: typeof Sim | typeof LegacySubscriber;

  /**
   * When `performUpdate()` is called, the `updateFunction` is called once per subscriber, sequentially, until it has been called for all subscribers. This should be an API call made via `SoracomApiService` and it should return the promise, like this:
   *
   * ```
   * // Example concrete subclass implementation:
   *
   * updateFunction(subscriber: Subscriber) {
   *   return this.apiService.deactivateSubscriber(subscriber.imsi);
   * }
   * ```
   * NOTE: You may `throw new Error('something');` if you need to, in your implementation of this method. That will cause the update to fail (but other updates may still succeed).
   *
   * NOTE: This is weirdly typed, because the current SoracomApiService is a minimal port of the legacy AngularJS one, and it does not have a typed API. Furthermore, this type is a lie; it actually currently returns `Promise<T>` which is actually implemented by AngularJS promise library because JS promises were not available back in the old days. So this doesn't **really** return Promise<any> but it returns a promise-like object that you can `.then()` and `.catch()`... I kind of debated whether to falsely assert this type, or just use `any`, but in the end did it this way.
   */
  abstract updateFunction(subscriber: ExtendedSubscriberInterface): Promise<{
    status: number;
    [key: string]: any;
  }>; //FIXME - This typing is so that implementing functions using the Typescript-api-service will know that they  need to provide at least a status code in return type

  /**
   * Must return the a `TextContent` instance representing the heading for the table column that shows what is being changed (e.g. should resolve to "Status" or "Speed class" or whatever).
   */
  abstract get changeColumnHeading(): LegacyTextContent;

  /**
   * Must return a `TextContent` instance representing the string describing the state before the change. E.g. one that resolves to "s1.fast" for speed class, or "Inactive" for status, etc. Will be used to construct text displayed in the UI.
   */
  abstract beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent;

  /**
   * Must return `undefined` (meaning we don't yet know the post-change state, perhaps the user needs to input something) or else a `TextContent` instance representing the string describing the state after the change. E.g. a translation that resolves to "s1.fast" for speed class, or "Inactive" for status, etc. Will be used to construct text displayed in the UI.
   */
  abstract afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined;

  /**
   * Default implementation just returns `afterDescription(subscriber)`. Override if your subclass needs something more complex.
   */
  successDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return this.afterDescription(subscriber);
  }

  /**
   * The state of the batch change as a whole. The 'succeeded' state implies 100% success, while the 'failed' state means one or more individual updates failed. (Check `successCount` vs `failureCount` to get the exact numbers if necessary.) The "canceled" state means the change was canceled, though this may not be actually used. The "error" state implies a bug or programming error. The "waiting" state means waiting for user interaction or input — if your subclass can be modified after doing a batch update, and possibly do another one, you should set the `state` back to `"waiting"` when user-input values are changed. (See `SubscriberBatchSpeedClassUpdater` for example.)
   */
  state: 'waiting' | 'busy' | 'succeeded' | 'failed' | 'canceled' | 'error' = 'waiting';

  /**
   * Returns an array of errors that apply to the batch as a whole. This is only useful when the updater has failed or has an unexpected error.
   */
  get errorAlertsThatApplyToTheBatchOperation(): Alert[] {
    if (this.state === 'error') {
      const unknownError = Alert.danger('common.error_messages.unknown_error');
      return [unknownError];
    } else if (this.state === 'failed') {
      const failures = this.failures;

      const errorMessageMap = new Map<string, Array<{ alert: Alert; result: any }>>();

      // Unfortunately, the type of failure is `any` but it is usually an AngularJS HTTP response object. It might sometimes be a string. We need to make a unique key to coalesce errors that are the same. It is possible in theory for there to be multiple different errors, but IRL they are typically all the same. The `Alert` class already handles parsing these various objects into a human-readable message, so we will rely on it here.
      failures.forEach((result: LegacyAny) => {
        const alert = Alert.danger(result);
        const uniqueKey = alert.stringRepresentation;
        const entry = errorMessageMap.get(uniqueKey) ?? [];
        entry.push({ alert, result });
        errorMessageMap.set(uniqueKey, entry);
      });

      const alertsWithCounts: Alert[] = [];

      errorMessageMap.forEach((value, key) => {
        const alert = value[0].alert;
        const result = value[0].result;
        const count = value.length;

        alert.occurrenceCount = count;
        alertsWithCounts.push(alert);
      });

      const alertCount = alertsWithCounts.length;
      if (alertCount < 3) {
        return alertsWithCounts;
      } else {
        return [Alert.danger('Alert.multipleErrorsOccurredPleaseSeeDetailsAbove')];
      }
    }
    return [];
  }

  /**
   * The batch alerts manager is supplied by the owning component, and used to manage alerts that pertain to the batch operation as a whole.
   */
  batchAlertManager: AlertsManager = new AlertsManager();

  /**
   * The number of successful updates completed. Only meaningful **after** the update has been attempted.
   */
  successCount = 0;

  /**
   * The number of updates that failed. Only meaningful **after** the update has been attempted.
   */
  failureCount = 0;

  /**
   * Returns the cached name of the group corresponding to `groupId`.
   */
  getGroupName(groupId: string) {
    return groupsService.getGroupName(groupId);
  }

  /**
   * The array of `Subscriber` objects that will be modified.
   */
  get subscribers(): ExtendedSubscriberInterface[] {
    return this._subscribers;
  }

  protected _subscribers: ExtendedSubscriberInterface[];

  /**
   * Returns true if `result` is an API response with the expected status code, and false if result is any other kind of object or has the wrong status code.
   */
  isSuccess(result: any) {
    return !!(result?.data && result?.status === 200);
  }

  /**
   * Returns all results which were not success results.
   */
  get failures(): any[] {
    return this.results.filter((r) => !this.isSuccess(r));
  }

  /**
   * Internal state: The list of current status info for each subscriber. Used by UI to show status of each subscriber in the batch change.
   */
  statuses: BatchUpdateStatus[] = [];

  /**
   * Internal state: collects results. We maintain this as state so that UI can bind to it, and update the status of individual subscribers as the batch operation progresses.
   *
   * Normally this array will contain API responses (success or failure but in some cases might contain errors of other types as well.
   */
  results: any[] = [];

  /**
   * Internal state: this is the array of promises that will be iterated over when the batch change is run.
   */
  private promises: any[] = [];

  /**
   * Internal housekeeping method, that is used each time a promise returns a result.
   */
  processLastResult() {
    const indexToUpdate = this.results.length;
    this.statuses[indexToUpdate] = BatchUpdateStatus.updating;
    const previousIndex = indexToUpdate - 1;

    try {
      if (indexToUpdate > 0) {
        const lastResult = this.results[previousIndex];
        const wasSuccessful = this.isSuccess(lastResult);
        const updatedSubscriber = wasSuccessful && new this.concreteSubscriberClass(lastResult.data);
        const subscriberBeforeUpdate = this.subscribers[previousIndex];

        if (updatedSubscriber && updatedSubscriber.groupId === subscriberBeforeUpdate.groupId) {
          // should always be true, except in group change case?
          if (subscriberBeforeUpdate.group) {
            updatedSubscriber.group = subscriberBeforeUpdate.group;
          } else if (subscriberBeforeUpdate._groupName) {
            updatedSubscriber._groupName = subscriberBeforeUpdate._groupName;
          }
          // This hack simulates the hack that SIM table UI does to keep track of group name
        }

        if (updatedSubscriber) {
          this.subscribers[previousIndex] = updatedSubscriber;
          this.successCount++;
        } else {
          this.failureCount++;
        }

        this.statuses[previousIndex] = this.isSuccess(lastResult)
          ? BatchUpdateStatus.succeeded
          : BatchUpdateStatus.failed;
      }
    } catch (error) {
      this.statuses[previousIndex] = BatchUpdateStatus.failed;
    }
  }

  /**
   * This just executes the `updateFunction` (which is provided by concrete subclass), but catches any error and wraps that in a promise. This helps simplify the sequential promise execution and results tracking in `performUpdate()`, while letting subclasses throw their own errors in `updateFunction` if necessary (which can then be treated similarly to API errors).
   */
  private executeUpdateFunction(subscriber: LegacyAny, index: LegacyAny): Promise<any> {
    let result;

    try {
      result = this.shouldSimulateErrorResult(index)
        ? this.updateFunctionForSimulatingError(subscriber)
        : this.updateFunction(subscriber);
    } catch (error) {
      result = Promise.resolve(error);
    }
    return result;
  }

  /**
   * Asynchronously run all updates, sequentially performing API calls, and collecting the results in `this.results`
   */
  performUpdate(): Promise<SubscriberBatchUpdater> {
    const MILLISECONDS_TO_WAIT_BETWEEN_API_CALLS = 250; // delay, either for debug or maybe to avoid rate limiting

    this.state = 'busy';

    this.results = []; // will collect both success results and error results
    this.statuses = [];
    this.promises = [];
    this.successCount = 0;
    this.failureCount = 0;
    this.batchAlertManager.clear();

    this.subscribers.forEach((subscriber, index) => {
      this.statuses.push(BatchUpdateStatus.waiting);

      const basePromise = this.executeUpdateFunction(subscriber, index);

      const selfCatchingPromise = basePromise.catch((apiErr) => {
        this.trace('apiErr', apiErr);
        return apiErr;
      });
      // NOTE: Why do we need this "self-catching promise"?
      // The reason is that otherwise, a "Possibly unhandled rejection" error will be logged in
      // the browser console. We have to catch this here to avoid that unwanted error message
      // becoming user visible. At the same time, though, we want to propagate the error to the
      // outer logic that collects successes and failures (below), so we need to catch the error
      // (to avoid the error message) and then return it so that it gets propagated.
      //
      // NOTE 2: There was actually an hour's worth of debugging here that is no longer visible in
      // this source code. My initial implementation *did* in fact catch errors in every possible
      // place... and yet, in some combinations of successful and failed API calls, AngularJS was
      // still emitting that warning. (The warning is an AngularJS thing, introduced in version 1.6).
      // My beard was going white trying to work around this, and failing, and it really seemed like
      // probably an AngularJS bug, actually... so since this code explicitly does not care about
      // the difference between a failed API call promise and a successful one (it wants to collect
      // both types of result in a single collection, in the end), I just suppress the errors by
      // using `catch()` above and then just returning the error normally, thereby short-circuiting
      // the error propagation. (Mason 2019-08-28)

      this.promises.push(selfCatchingPromise);
    });

    /** private little delay utility func */
    const delay = (ms: LegacyAny) => {
      return new Promise((resolve) => {
        setTimeout(resolve.bind(null, 'hi'), ms);
      });
    };

    /** Here we will use the common "promisesArray.reduce()" strategy to run the promises in sequential order, but the accumulator object is just `this` and we let this class handle tracking all the state as we iterate */
    return this.promises
      .reduce((accumulatorPromise, currentPromise) => {
        this.trace('SequentialChanger', 'reduce() step executing...', accumulatorPromise, currentPromise);

        return accumulatorPromise.then((updater: LegacyAny) => {
          this.processLastResult();
          this.trace('SequentialChanger statuses', this.statuses);

          return delay(MILLISECONDS_TO_WAIT_BETWEEN_API_CALLS).then(() => {
            return currentPromise.then((currentResult: LegacyAny) => {
              this.results = [...updater.results, currentResult];
              this.trace('SequentialChanger', 'a promise completed', this.results);
              return this;
            });
          });
        });
      }, Promise.resolve(this))
      .finally(() => {
        this.processLastResult(); // Need to do the last one here

        this.trace('SequentialChanger', 'finally() →', this.results);
        this.trace('SequentialChanger', 'end →', this);

        this.state = this.successCount === this.subscribers.length && this.failureCount === 0 ? 'succeeded' : 'failed';

        const batchErrorAlerts = this.errorAlertsThatApplyToTheBatchOperation;
        batchErrorAlerts.forEach((alert) => {
          this.batchAlertManager?.add(alert);
        });
        return this;
      });
  }

  /**
   * For debugging only.
   */
  shouldSimulateErrorResult(index: LegacyAny) {
    const errorsToSimulate = SoracomUserConsole.simulateErrorsInSubscriberBatchUpdater;
    if (!errorsToSimulate) {
      return false;
    }
    if (typeof errorsToSimulate === 'boolean') {
      return errorsToSimulate;
    }
    return errorsToSimulate.includes(index);
  }

  updateFunctionForSimulatingError(subscriber: ExtendedSubscriberInterface): Promise<any> {
    return this.apiService.generateApiError();
  }
}
