<header class="ds-banner --color-ink-light">
  <i class="ds-icon --icon-browser --large"></i>
  <div>
    <h5>{{'subscriber_registration.register_by_order_header' | translate}}</h5>
    <p
      *ngIf="$any(orders?.length) > 0"
      [innerHtml]="'subscriber_registration.notice_direct_purchase' | translate"
    ></p>
  </div>
</header>

<div class="ds-rows --indent-small">

  <app-alerts [alertsManager]="alertManager"></app-alerts>

  <table class="ds-datatable --embedded" *ngIf="$any(orders?.length) > 0">
    <thead data-label>
      <tr>
        <th>{{ 'orders.list.orderId' | translate }}</th>
        <th class="--max">{{ 'orders.list.orderedDate' | translate }}</th>
        <th class="--center" [innerHtml]="'orders.list.numberOfSims' | translate"></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders">
        <td>{{ order.orderId }}</td>
        <td>{{ formatOrderDate(order) }}</td>
        <td class="--center">{{ getNumberOfSims(order) }}</td>
        <td>
          <button
            class="ds-button --small --primary"
            [ngClass]="{
              'ds-button --icon-refresh-cw --spin': order.registering,
              'ds-button --icon-confirm': !order.registering
            }"
            (click)="registerOrder(order)"
            [disabled]="order.registering || order.registered"
            *ngIf="!order.registered"
          >
            <span>{{ 'orders.list.register' | translate }}</span>
          </button>
          <p class="ds-text --label --success" *ngIf="order.registered">
            <i class="ds-icon --icon-confirm --xsmall"></i>
            {{ 'orders.list.registered' | translate }}
          </p>
        </td>
        <td>
          <button ucSimInitialSettingsButton [fromOrder]="order" (settingsUpdated)="onSimInitialSettingsUpdated()"></button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="ds-notice --info" *ngIf="!orders || orders?.length === 0">
    {{ 'orders.list.no_data_to_register' | translate }}
  </div>

  <ui-button-bar [content]="footerButtonBar"></ui-button-bar>
</div>
