import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiSdsModule } from '@soracom/shared-ng/ui-sds';
import { SuperTableModule } from '@soracom/shared-ng/ui-super-table';
import { WebStorageModule } from '@soracom/forks/ngx-store';

import { TextContentPipeModule } from '@soracom/shared-ng/util-common';
import { AutocompleteModule } from '@soracom/shared-ng/autocomplete';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomGroupsModule } from '../soracom-groups/soracom-groups.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { CancelTransferSimOperatorComponent } from './cancel-transfer-sim-operator/cancel-transfer-sim-operator.component';
import { ChangeSubscribersBundlesComponent } from './change-subscribers-bundles/change-subscribers-bundles.component';
import { ChangeSubscribersExpiryComponent } from './change-subscribers-expiry/change-subscribers-expiry.component';
import { ChangeSubscribersGroupComponent } from './change-subscribers-group/change-subscribers-group.component';
import { ChangeSubscribersImeiLockComponent } from './change-subscribers-imei-lock/change-subscribers-imei-lock.component';
import { ChangeSubscriberSpeedClassComponent } from './change-subscribers-speed-class/change-subscribers-speed-class.component';
import { ChangeSubscribersStatusComponent } from './change-subscribers-status/change-subscribers-status.component';
import { ChangeSubscriberTerminationProtectionComponent } from './change-subscribers-termination-protection/change-subscribers-termination-protection.component';
import { CreateVirtualSessionComponent } from './create-virtual-session/create-virtual-session.component';
import { DeleteSubscribersSessionComponent } from './delete-subscribers-session/delete-subscribers-session.component';
import { OnDemandRemoteAccessComponent } from './on-demand-remote-access/on-demand-remote-access.component';
import { NapterSubscriberDetailListComponent } from './on-demand-remote-access/napter-subscriber-detail-list/napter-subscriber-detail-list.component';
import { PingSubscribersComponent } from './ping-subscribers/ping-subscribers.component';
import { PortMappingDetailsComponent } from './port-mapping-details/port-mapping-details.component';
import { RegisterSimModule } from './register-sim/register-sim.module';
import { SimAddVirtualSimComponent } from './sim-add-virtual-sim/sim-add-virtual-sim.component';
import { SpeedClassLegendComponent } from './speed-class-legend/speed-class-legend.component';
import { SubscriberBatchChangeComponent } from './subscriber-batch-change/subscriber-batch-change.component';
import { SubscribersTableComponent } from './subscribers-table/subscribers-table.component';
import { TerminateSubscribersComponent } from './terminate-subscribers/terminate-subscribers.component';
import { TransferSimOperatorComponent } from './transfer-sim-operator/transfer-sim-operator.component';
import { UcSimidComponent } from './uc-simid/uc-simid.component';
import { VirtualSimConnectionInfoComponent } from './virtual-sim-connection-info/virtual-sim-connection-info.component';
import { VsimDetailsComponent } from './vsim-details/vsim-details.component';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';

@NgModule({
    declarations: [
        CancelTransferSimOperatorComponent,
        ChangeSubscribersBundlesComponent,
        ChangeSubscribersExpiryComponent,
        ChangeSubscribersGroupComponent,
        ChangeSubscribersImeiLockComponent,
        ChangeSubscriberSpeedClassComponent,
        ChangeSubscribersStatusComponent,
        ChangeSubscriberTerminationProtectionComponent,
        ChangeSubscriberSpeedClassComponent,
        CreateVirtualSessionComponent,
        DeleteSubscribersSessionComponent,
        OnDemandRemoteAccessComponent,
        NapterSubscriberDetailListComponent,
        PingSubscribersComponent,
        PortMappingDetailsComponent,
        SpeedClassLegendComponent,
        SubscriberBatchChangeComponent,
        TerminateSubscribersComponent,
        TransferSimOperatorComponent,
        SubscribersTableComponent,
        SimAddVirtualSimComponent,
        VsimDetailsComponent,
        VirtualSimConnectionInfoComponent,
        UcSimidComponent
    ],
    exports: [
        CancelTransferSimOperatorComponent,
        ChangeSubscribersBundlesComponent,
        ChangeSubscribersExpiryComponent,
        ChangeSubscribersGroupComponent,
        ChangeSubscribersImeiLockComponent,
        ChangeSubscriberSpeedClassComponent,
        ChangeSubscribersStatusComponent,
        ChangeSubscriberTerminationProtectionComponent,
        ChangeSubscriberSpeedClassComponent,
        DeleteSubscribersSessionComponent,
        OnDemandRemoteAccessComponent,
        NapterSubscriberDetailListComponent,
        PingSubscribersComponent,
        PortMappingDetailsComponent,
        SpeedClassLegendComponent,
        SubscriberBatchChangeComponent,
        TerminateSubscribersComponent,
        TransferSimOperatorComponent,
        SubscribersTableComponent,
        VsimDetailsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        // FIXME: Remove unused modules
        ReactiveFormsModule,
        TranslateModule.forChild(),
        WebStorageModule,
        AutocompleteModule,
        SoracomCommonModule,
        SoracomUiModule,
        SoracomGroupsModule,
        UiSdsModule,
        SuperTableModule,
        RegisterSimModule,
        TextContentPipeModule,
        SoracomI18nModule
    ]
})
export class SubscribersModule {}
