import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { PingSubscriberParams, SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchPingWorker } from '../../shared/SubscriberBatchPingWorker';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

export interface PingSubscribersComponentInput {
  subscribers: ExtendedSubscriberInterface[];
}

@Component({
  selector: 'app-ping-subscribers',
  templateUrl: './ping-subscribers.component.html',
  styleUrls: ['./ping-subscribers.component.scss'],
})
export class PingSubscribersComponent
  extends DsModalContentBase<PingSubscribersComponentInput, null>
  implements OnInit
{
  modalData: PingSubscribersModalData = this.getInput();

  get canConfirm(): boolean {
    return this.form.valid && this.pingWorker.state !== 'busy';
  }
  canClose(): boolean {
    return this.pingWorker.state !== 'busy';
  }

  // @ts-expect-error (legacy code incremental fix)
  pingWorker: SubscriberBatchPingWorker;
  pingSubscriberParams: PingSubscriberParams = {
    numberOfPingRequests: 3,
    timeoutSeconds: 1,
  };

  form = this.fb.group({
    numberOfPingRequests: [
      this.pingSubscriberParams.numberOfPingRequests,
      [Validators.required, Validators.max(5), Validators.min(1)],
    ],
    timeoutSeconds: [
      this.pingSubscriberParams.timeoutSeconds,
      [Validators.required, Validators.max(3), Validators.min(1)],
    ],
  });

  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    private fb: FormBuilder,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.pingWorker = new SubscriberBatchPingWorker(
      this.logger,
      this.apiService,
      this.modalData.subscribers,
      this.pingSubscriberParams,
      this.subscriberApiService,
      this.simApiService
    );
  }

  confirm() {
    // @ts-expect-error (legacy code incremental fix)
    this.pingSubscriberParams.numberOfPingRequests = this.form.value.numberOfPingRequests;
    // @ts-expect-error (legacy code incremental fix)
    this.pingSubscriberParams.timeoutSeconds = this.form.value.timeoutSeconds;
    this.pingWorker.performUpdate().then((finishedUpdater) => {
      // Don't close dialog
    });
  }

  result(subscriber: ExtendedSubscriberInterface) {
    return this.pingWorker.getResult(subscriber);
  }

  close() {
    this.modalRef.close();
  }
}

export interface PingSubscribersModalData {
  subscribers: ExtendedSubscriberInterface[];
}
