import deepEqual from 'fast-deep-equal';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

import { Directive } from '@angular/core';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { Logger } from '@soracom/shared/logger';
import { ApiResponse } from '@soracom/shared/soracom-api-typescript-client';
import { SubscriberBundleId, SubscriptionPlan } from '@soracom/shared/subscription-plan';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';

@Directive()
export class SubscriberBatchBundlesUpdater extends SubscriberBatchUpdater {
  newBundles: SubscriberBundleId[] = [];
  constructor(
    logger: Logger,
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }

  /**
   * Bundles are a subscriber-level property, not a SIM property. This implementation in `SubscriberBatchBundlesUpdater` is therefore slightly weird, because it first makes the `setBundles()` call via the `/v1/subscribers` API, and then (if first call succeeds) just fetches the SIM structure with the `/v1/sims` API. This is because the core `SubscriberBatchUpdater` expects the update function to return the same type of `Subscriber` as was passed in — so if a `Sim` instance was passed in, the update function must return a `Sim`, and not a `LegacySubscriber`. That is why we chain the extra `GET /v1/sims/${sim.id}` call onto the end.
   */
  async updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    // First, use the subscriber API to set the bundles (regardless of whether `subscriber` is a `Sim` or `LegacySubscriber`):
    let result: ApiResponse<any> = await this.subscriberApiService.putBundles({
      imsi: subscriber.imsi,
      requestBody: this.newBundles,
    });

    // After that, we do need to care if it is a new Sim or LegacySubscriber:
    const isSim = Sim.isSim(subscriber);

    let speedClassChangeResult;

    if (this.newBundles.length === 1 && this.newBundles[0] === 'D-300MB') {
      // special case: update speed class too after change to D-300MB

      const speedClass = 's1.4xfast';
      const speedClassPromise = isSim
        ? this.simApiService.updateSimSpeedClass({
            simId: subscriber.simId,
            updateSpeedClassRequest: { speedClass },
          })
        : this.subscriberApiService.updateSpeedClass({
            imsi: subscriber.imsi,
            updateSpeedClassRequest: { speedClass },
          });

      speedClassChangeResult = await speedClassPromise;

      // If that failed, then we are done:
      if (!this.isSuccess(speedClassChangeResult)) {
        return speedClassChangeResult;
      }
    }

    // If that failed, then we are done:
    if (!this.isSuccess(result)) {
      return result;
    }

    // If we are processing a `Sim` then we need to make one additional API call, to re-fetch the SIM so that we return the same type of object as we received as input:
    if (Sim.isSim(subscriber)) {
      // isSim() is a user-defined type guard, so if we get here then `subscriber` type is `Sim`
      result = await this.simApiService.getSim({ simId: subscriber.id });
    }

    // Finally, return the updated result:
    return result;
  }

  get changeColumnHeading() {
    const bundleTerminology = SubscriptionPlan.bundleTerminologyId(this.subscribers);

    return LegacyTextContent.translation(`SubscriberBatchUpdater.bundles.${bundleTerminology}`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return subscriber.bundlesDescription
      ? LegacyTextContent.string(subscriber.bundlesDescription)
      : LegacyTextContent.translation('SubscriberBatchUpdater.noBundles');
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    if (this.subscribers.every((s) => deepEqual(s.bundles, this.newBundles))) {
      // @ts-expect-error (legacy code incremental fix)
      return undefined; // since no changes will be made to any SIM
    }
    // @ts-expect-error (legacy code incremental fix)
    return this.newBundles ? LegacyTextContent.string(`${this.newBundles}`) : undefined;
  }

  successDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return LegacyTextContent.string(`${subscriber.bundles}`);
  }

  get canUpdate() {
    return this.newBundles && this.newBundles[0];
  }
}
