import { AbstractModel } from '@soracom/shared/soracom-platform';
import { PortMappingDestination, PortMappingDestinationRequestForSimId } from './PortMappingDestination';
import { PortMappingSource } from './PortMappingSource';

export class CreatePortMappingRequest extends AbstractModel {
  constructor(
    public tlsRequired: boolean,
    public duration: number,
    public destination: PortMappingDestinationRequestForSimId,
    public source: PortMappingSource
  ) {
    super();
  }
}
