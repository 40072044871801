import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDateTime, parseDateTime } from '@soracom/shared/util-common';
import { Order } from '../../../../../app/shared/core/order';
import { OrdersService } from '../../../../../app/shared/orders/orders.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-register-sim-from-order',
  templateUrl: './register-sim-from-order.component.html',
})
export class RegisterSimFromOrderComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() closeModal: () => void;
  footerButtonBar: UiButtonBar;
  alertManager = new AlertsManager();

  // @ts-expect-error (legacy code incremental fix)
  @Input() orders: Order[];
  @Output() orderUpdated = new EventEmitter<void>();

  constructor(private ordersService: OrdersService, private translate: TranslateService) {
    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'subscriber_registration.close';
          button.onClick = () => this.closeModal();
        }),
      ];
    });
  }

  formatOrderDate(order: Order): string {
    const epochMs = parseDateTime(order?.orderDateTime, 'YYYYMMDDhhmmss');
    // @ts-expect-error (legacy code incremental fix)
    return formatDateTime(epochMs, 'date');
  }

  getNumberOfSims(order: Order): string {
    if (order.subscribers) {
      if (order.subscribers.links.next) {
        return this.translate.instant('orders.list.numberOfSimsDisplayMoreThanXSims', {
          number: order.subscribers.data.length,
        });
      } else {
        return `${order.subscribers.data.length}`;
      }
    } else {
      return this.translate.instant('table.loading');
    }
  }

  async registerOrder(order: Order) {
    order.registering = true;
    order.registered = false;
    this.alertManager.clear();

    try {
      await this.ordersService.registerSubscribers(order.orderId);
      order.registered = true;
      order.orderStatus = 'received';
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'common.error_messages.unknown_error'));
    } finally {
      order.registering = false;
    }
  }

  onSimInitialSettingsUpdated() {
    this.orderUpdated.emit();
  }
}
