<section dsDialogContent class="ds-rows --gap-small">
    <app-alerts [alertsManager]="alertsManager" />
    <label class="ds-radio --panel">
        <input type="radio" value="AUTO" [(ngModel)]="newOperatorTopUpDefault">
        <div class="ds-rows --gap-xsmall">
        <p class="--label">{{i18n.operatorTopUpDefaults.autoTopUpLabel | localize}}</p>
        <p>
            {{i18n.operatorTopUpDefaults.autoTopUpDescription | localize}}
        </p>
        </div>
    </label>
    <label class="ds-radio --panel">
        <input type="radio" value="MANUAL" [(ngModel)]="newOperatorTopUpDefault">
        <div class="ds-rows --gap-xsmall">
        <p class="--label">{{i18n.operatorTopUpDefaults.manualTopUpLabel | localize}}</p>
        <p>
            {{i18n.operatorTopUpDefaults.manualTopUpDescription | localize}}
        </p>
        </div>
    </label>

</section>
<footer dsDialogFooter>
    <button class="ds-button --primary" [class.--loading]="loading" (click)="submit()" [disabled]="!canSubmit()">{{i18n.operatorTopUpDefaults.submit | localize}}</button>
    <button class="ds-button" (click)="close()" [disabled]="!canClose()">{{i18n.operatorTopUpDefaults.cancel | localize}}</button>
</footer>