import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StFilter, StFilterModule } from '@soracom/shared-ng/ui-super-table';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * @deprecated This component may be removed. I think we decided not to use it, and only offer IMSI-based filtering.
 */
@Component({
  selector: 'ui-sim-update-history-filter',
  standalone: true,
  imports: [CommonModule, StFilterModule],
  providers: [
    {
      provide: StFilter,
      useExisting: UiSimUpdateHistoryFilterComponent,
    },
  ],
  template: `
    <st-text-filter filterId="sim-update-history-filter"></st-text-filter>
  `,
  styles: [
    `
      :host {
        display: block;
        margin: 4px;
        max-width: 40em;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSimUpdateHistoryFilterComponent extends StFilter<string> {
  @Output() filterValueChange = new EventEmitter<string>();
  @Input() set filterValue(newValue: string) {
    this._filterValue$.next(newValue);
  }

  // These are just to satisfy the requirements for using st-text-filter
  _filterValue$ = new BehaviorSubject<string>('');
  filterValue$ = this._filterValue$.asObservable();

  override getFilterValue$(filterId: string): Observable<string> {
    // console.log('getFilterValue$', filterId);
    return this.filterValue$;
  }

  override setFilterValue(filterId: string, filterValue: string | null): void {
    // console.log('setFilterValue', filterId, filterValue);
    this._filterValue$.next(filterValue ?? '');

    this.filterValueChange.emit(filterValue ?? '');
  }
}
