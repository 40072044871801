import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StringTransformationCache } from '@soracom/shared/core';

@Component({
  templateUrl: './uc-simid.component.html',
  selector: 'uc-simid',
})
export class UcSimidComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() simId: string;
  // @ts-expect-error (legacy code incremental fix)
  simIdUi: SafeHtml;

  private simIdCache = new StringTransformationCache((simId) => {
    if (!simId || !simId.length) {
      return '';
    }
    const html =
      "<span style='margin-right: 0.5em;'>" +
      simId.substring(0, 7) + // This is the sim provider number
      "</span><wbr><span style='margin-right: 0.5em;'></span>" +
      simId.substring(7);

    return this.sanitizer.bypassSecurityTrustHtml(html);
  });

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.simIdUi = this.simIdCache.get(this.simId);
  }
}
