<div class="ds-rows slider-content">
  <div class="ds-heading--regular-bold" style="line-height: 40px">
    {{ 'simPacketCaptureSessions.stopCaptureModal.title' | translate }}
  </div>
  <div class="ds-text">
    {{ 'simPacketCaptureSessions.stopCaptureModal.confirmMessage' | translate }}
  </div>
  <app-alerts [alertsManager]="alertManager"></app-alerts>
  <div>
    <ui-button-bar [content]="bottomButtonBar"></ui-button-bar>
  </div>
</div>
