import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SlideAnimationState } from './slide-animations';

export type Panels = 'request' | 'delete' | 'stop';

@Injectable()
export class SelectionService {
  // @ts-expect-error (legacy code incremental fix)
  private _selectedSessionId$ = new BehaviorSubject<string>(undefined);
  readonly selectedSessionId$ = this._selectedSessionId$.asObservable();

  private _selectedPanel$ = new Subject<Panels | undefined>();
  readonly selectedPanel$ = this._selectedPanel$.asObservable();

  private _mainAnimState$ = new Subject<SlideAnimationState>();
  readonly mainAnimState$ = this._mainAnimState$.asObservable();

  private _panelAnimState$ = new Subject<SlideAnimationState>();
  readonly panelAnimState$ = this._panelAnimState$.asObservable();

  selectSessionId(id: string) {
    this._selectedSessionId$.next(id);
  }

  selectPanel(panel: Panels) {
    this._selectedPanel$.next(panel);
    this._mainAnimState$.next('out');
    this._panelAnimState$.next('left');
  }

  closePanel(isForSuccess?: boolean) {
    // TODO: Use animation event
    setTimeout(() => {
      this._selectedPanel$.next(undefined);
    }, 500);
    this._mainAnimState$.next(isForSuccess ? 'left' : 'in');
    this._panelAnimState$.next(isForSuccess ? 'out' : 'right');
  }
}
