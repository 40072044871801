import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fixedRateI18n } from '../../i18n';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: `td[appFixedRateSimRemainingUsageTableValue]`,
  imports: [CommonModule, SoracomI18nModule],
  templateUrl: `./fixed-rate-sim-remaining-usage-table-value.component.html`,
  host: {
    class: 'ds-datatable',
  },
})
export class FixedRateSimRemainingUsageTableValueComponent {
  @Input() value?: number | string;
  @Input() suffix: string = '';
  i18n = fixedRateI18n;

  get isNegative(): boolean {
    return (
      this.value !== undefined &&
      typeof this.value === 'number' &&
      this.value < 0
    );
  }

  get isPositive(): boolean {
    return !this.isNegative;
  }

  get displayValue(): string | number {
    if (this.value === undefined || this.value === null) {
      return '';
    }
    return this.value + this.suffix;
  }
}
