import { DsDialChartDataDMYY } from '@soracom/shared-ng/ng-charts';
import dayjs from 'dayjs';

/**
 *
 * @param endDate yyyy-mm-dd
 * @param totalYears
 */
export function calculateRemainingDayMonthYearBreakdownDialChartData(
  endDate: string,
  totalYears: number
): DsDialChartDataDMYY {
  const dayjsEndDate = dayjs(endDate);
  const curDate = dayjs();
  if (curDate.isAfter(dayjsEndDate)) {
    return {
      day: 0,
      month: 0,
      year: 0,
      totalYears,
    };
  }
  const yearsRemaining = dayjsEndDate.diff(curDate, 'years');
  curDate.add(yearsRemaining, 'years');
  const offsetMonthsRemaining = dayjsEndDate.diff(curDate, 'months');
  curDate.add(offsetMonthsRemaining, 'months');
  const offsetDaysRemaining = dayjsEndDate.diff(curDate, 'days');

  return {
    day: offsetDaysRemaining,
    month: offsetMonthsRemaining,
    year: yearsRemaining,
    totalYears,
  };
}

/**
 *
 * @param simPlanExpiryDate yyyy-mm-dd
 * @param contractYears
 */
export function calculateRemainingDMYYChartDateForSimPlan(
  simPlanExpiryDate: string,
  contractYears: number
): DsDialChartDataDMYY {
  return calculateRemainingDayMonthYearBreakdownDialChartData(
    simPlanExpiryDate,
    contractYears
  );
}
