<td>{{ formatDateTime(session.createdTime, 'datetime_sec') }}</td>
<td>{{ formatDateTime(session.startedTime, 'datetime_sec') }}</td>
<td>
  <span class="ds-tag" [ngClass]="sessionStatusClass">
    {{ session.status }}
  </span>
  <i
    *ngIf="session.status === 'FAILED' && session.failedReason"
    [attr.data-ds-texttip]="session.failedReason"
    class="ds-icon--icon-info ds-icon--alert ds-icon--xsmall status-fail-icon"
  >
  </i>
</td>
<td>{{ session.prefix }}</td>
<td class="ds-datatable__col--right">{{ session.duration }}</td>
<td>
  <div>
    <div *ngIf="isFetchingSessionInfo">
      {{ 'simPacketCaptureSessions.gettingLink' | translate }}
      <img src="https://assets.soracom.io/images/async-working.gif" />
    </div>

    <div>
      <i
        *ngIf="!isFetchingSessionInfo && sessionInfoErrorMsg"
        [attr.data-ds-texttip]="sessionInfoErrorMsg"
        class="ds-icon--icon-info ds-icon--alert ds-icon--xsmall"
      >
      </i>
      <a
        href
        (click)="fetchCaptureSession($event)"
        *ngIf="showGetLink()"
        class="ds-button--plain ds-button--icon-cloud-download"
      >
        <span translate="simPacketCaptureSessions.getLink"></span>
      </a>
    </div>

    <div>
      <a
        *ngIf="sessionInfo"
        class="ds-button--plain ds-button--success ds-button--icon-download"
        [href]="sessionInfo.url"
      >
        <span translate="simPacketCaptureSessions.downloadFile"></span>
      </a>
    </div>
  </div>
</td>
<td>
  <a
    *ngIf="showStopButton"
    href
    (click)="stopPacketCaptureSessionHandler($event)"
    class="ds-button--icon-offline ds-button--alert ds-button--plain"
  >
    <span translate="simPacketCaptureSessions.stopCaptureModal.button.stop"></span>
  </a>
  <a
    *ngIf="showDeleteButton()"
    href
    (click)="deletePacketCaptureSessionHandler($event)"
    class="ds-button--icon-delete ds-button--alert ds-button--plain"
  >
    <span translate="simPacketCaptureSessions.deleteCaptureModal.button.delete"></span>
  </a>
</td>
