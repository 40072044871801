import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDateTime } from '@soracom/shared/util-common';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

@Component({
  selector: 'app-sim-description',
  templateUrl: './sim-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimDescriptionComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() subscriber: ExtendedSubscriberInterface;

  formatDateTime = formatDateTime;

  constructor(private translate: TranslateService) {}

  shouldShowRenewFeeTrackingDate() {
    return (
      this.subscriber &&
      ['plan01s', 'plan01s-low_data_volume', 'planX3', 'planX3-EU', 'planP1', 'plan-KM1', 'plan-NA1-package', 'planGLK1', 'planJPK1'].includes(
        this.subscriber.subscription
      )
    );
  }

  get renewalFeeTrackingDate() {
    if (this.subscriber && ['ready', 'suspended', 'standby'].includes(this.subscriber.status)) {
      return formatDateTime(this.subscriber.renewalFeeStatusSetTime, 'datetime');
    }
  }

  get renewalFeeTrackingNoFeeMsg() {
    const status = this.translate.instant(`subscribers.status.${this.subscriber.status}`);
    return this.translate.instant('subscriber_details.renewFeeTrackingNoFee', { status });
  }
}
