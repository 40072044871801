<button class="ds-button --primary --icon-circle-plus" [ngClass]="customClasses" id="sim-reg-button" (click)="openRegisterSimModal()">
  <span>{{ 'subscribers.register' | translate }}</span>
</button>

<ng-template #paymentNoticeModalTemplate>
  <div dsDialogContent>
    <div class="ds-rows">
      <span [innerHtml]="paymentNotice"></span>
    </div>
  </div>
  <footer dsDialogFooter>
    <ui-button-bar [content]="paymentNoticeButtonBar"></ui-button-bar>
  </footer>
</ng-template>
