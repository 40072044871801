import { LegacyAny } from '@soracom/shared/core';

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CoverageTypeService, LoginUserDataService } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalRef } from '@soracom/shared-ng/ui-ds-modal';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { UiInput } from '../../../soracom-ui/ui-input/UiInput';

const ICCID_PATTERN =
  /^([0-9]{6}\s[0-9]{2}\s[0-9]{5}\s[0-9]{5}\s[0-9]{1})|([0-9]{5}\s[0-9]{5}\s[0-9]{5}\s[0-9]{3}\s[0-9]{1})$/;
const PUK_PATTERN = /^[0-9]{8}$/;
const IMSI_PATTERN = /^[0-9]{5}\s[0-9]{5}\s[0-9]{5}$/;
const PASSWORD_PATTERN = /^[0-9]{5}$/;

const inputMaxLengths = {
  iccidLength: 19,
  pukLength: 8,
  imsiLength: 15,
  passwordLength: 5,
};

@Component({
  selector: 'app-register-sim-direct',
  templateUrl: './register-sim-direct.component.html',
})
export class RegisterSimDirectComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() closeModal: (isCancel?: boolean) => void;
  @ViewChild('afterRegisterSuccessModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  afterRegisterSuccessModalTemplate: TemplateRef<any>;

  footerButtonBar: UiButtonBar;
  iccidFormatted = new UiInput();
  puk = new UiInput();
  imsiFormatted = new UiInput();
  password = new UiInput();
  name = new UiInput();
  groupInput = new UiInput();
  formGroup: FormGroup;
  isAgreedToTerms = false;
  alertManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  afterRegisterSuccessModalRef: UiDsModalRef;
  closeSuccessModalButtonBar;

  isRegistering = false;
  isRegistered = false;

  // @ts-expect-error (legacy code incremental fix)
  idType: 'imsi' | 'iccid';

  constructor(
    private coverageTypeService: CoverageTypeService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private loginUserDataService: LoginUserDataService,
    private soracomApiService: SoracomApiService,
    private uiDsModalService: UiDsModalService
  ) {
    this.footerButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'subscriber_registration.close';
          button.onClick = () => this.closeModal();
        }),
        UiButton.configure((button) => {
          button.titleId = 'subscriber_registration.register_sim_card';
          button.buttonStyle = 'primary';
          button.classes = ['x-register'];
          button.isDisabled_ƒ = this.isRegisterButtonDisabled;
          button.onClick = this.registerSim;
        }),
      ];
    });

    this.closeSuccessModalButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'subscriber_registration.close';
          button.onClick = () => this.afterRegisterSuccessModalRef.close();
          button.classes = ['x-sim-registration-confirmation-close'];
        }),
      ];
    });

    this.formGroup = this.fb.group({
      name: new FormControl(),
      groupId: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.idType = this.coverageTypeService.isJapan() ? 'imsi' : 'iccid';

    if (this.idType === 'imsi') {
      this.formGroup.addControl(
        'imsiFormatted',
        new FormControl('', [
          Validators.required,
          Validators.maxLength(inputMaxLengths.imsiLength + 2),
          Validators.minLength(inputMaxLengths.imsiLength + 2),
        ])
      );
      this.formGroup.addControl('imsi', new FormControl('', [Validators.required]));
      this.formGroup.addControl(
        'password',
        new FormControl('', [
          Validators.required,
          Validators.maxLength(inputMaxLengths.passwordLength),
          Validators.minLength(inputMaxLengths.passwordLength),
        ])
      );
    } else if (this.idType === 'iccid') {
      this.formGroup.addControl(
        'iccidFormatted',
        new FormControl('', [
          Validators.required,
          Validators.maxLength(inputMaxLengths.iccidLength + 4),
          Validators.minLength(inputMaxLengths.iccidLength + 4),
        ])
      );
      this.formGroup.addControl('iccid', new FormControl('', [Validators.required]));
      this.formGroup.addControl(
        'puk',
        new FormControl('', [
          Validators.required,
          Validators.maxLength(inputMaxLengths.pukLength),
          Validators.minLength(inputMaxLengths.pukLength),
        ])
      );
    }

    this.setupInputs();
  }

  setupInputs() {
    if (this.idType === 'iccid') {
      this.iccidFormatted = UiInput.configure((input) => {
        input.label = LegacyTextContent.translation('subscriber_registration.iccid', {
          iccidLength: String(inputMaxLengths.iccidLength),
        });
        input.required = true;
        input.placeholder_ƒ = () => this.translate.instant('subscriber_registration.iccid_placeholder');
        input.pattern = ICCID_PATTERN;
        // @ts-expect-error (legacy code incremental fix)
        input.formControl = this.formGroup.get('iccidFormatted');
        input.onKeyup = this.formatIccid;
      });

      this.puk = UiInput.configure((input) => {
        input.label = LegacyTextContent.translation('subscriber_registration.puk', {
          pukLength: String(inputMaxLengths.pukLength),
        });
        input.required = true;
        input.maxLength = inputMaxLengths.pukLength;
        input.placeholder_ƒ = () => this.translate.instant('subscriber_registration.puk_placeholder');
        input.pattern = PUK_PATTERN;
        // @ts-expect-error (legacy code incremental fix)
        input.formControl = this.formGroup.get('puk');
      });
    } else if (this.idType === 'imsi') {
      this.imsiFormatted = UiInput.configure((input) => {
        input.label = LegacyTextContent.translation('subscriber_registration.imsi', {
          imsiLength: String(inputMaxLengths.imsiLength),
        });
        input.required = true;
        input.placeholder_ƒ = () => this.translate.instant('subscriber_registration.imsi_placeholder');
        input.pattern = IMSI_PATTERN;
        // @ts-expect-error (legacy code incremental fix)
        input.formControl = this.formGroup.get('imsiFormatted');
        input.onKeyup = this.formatImsi;
      });

      this.password = UiInput.configure((input) => {
        input.label = LegacyTextContent.translation('subscriber_registration.password', {
          passwordLength: String(inputMaxLengths.passwordLength),
        });
        input.required = true;
        input.maxLength = inputMaxLengths.passwordLength;
        input.placeholder_ƒ = () => this.translate.instant('subscriber_registration.password_placeholder');
        input.pattern = PASSWORD_PATTERN;
        // @ts-expect-error (legacy code incremental fix)
        input.formControl = this.formGroup.get('password');
      });
    }

    this.name = UiInput.configure((input) => {
      input.label = LegacyTextContent.translation('subscriber_registration.name');
      // @ts-expect-error (legacy code incremental fix)
      input.formControl = this.formGroup.get('name');
    });
  }

  isRegisterButtonDisabled = () => {
    if (!this.formGroup.valid) {
      return true;
    }

    if (this.idType === 'imsi' && !this.isAgreedToTerms) {
      return true;
    }

    if (this.isRegistering || this.isRegistered) {
      return true;
    }

    return false;
  };

  formatImsi = () => {
    if (!this.imsiFormatted.value) {
      // @ts-expect-error (legacy code incremental fix)
      this.imsi.setValue('');
      return;
    }

    const imsi = this.imsiFormatted.value.replace(/[^0-9]/g, '');
    const segments: LegacyAny[] = [];
    const grouping = [5, 5, 5];
    grouping.forEach((group, idx) => {
      const offset = grouping.slice(0, idx).reduce((a: LegacyAny, b: LegacyAny) => a + b, 0);
      segments.push(imsi.substr(offset, group));
    });
    const finalSegments = segments.filter((s) => s.length > 0);
    // @ts-expect-error (legacy code incremental fix)
    this.imsi.setValue(finalSegments.join(''));
    this.imsiFormatted.value = finalSegments.join(' ');
  };

  formatIccid = () => {
    if (!this.iccidFormatted.value) {
      // @ts-expect-error (legacy code incremental fix)
      this.iccid.setValue('');
      return;
    }

    const iccid = this.iccidFormatted.value.replace(/[^0-9]/g, '');
    const iccidSegments: LegacyAny[] = [];
    let grouping = [19];
    // We can detect the SIM card format (SG001 / SG002) at the eighth digit.
    if (iccid.length >= 8) {
      if (iccid[7] === '0') {
        grouping = [6, 2, 5, 5, 1]; // SG001
      } else {
        grouping = [5, 5, 5, 3, 1]; // SG002
      }
    }

    grouping.forEach((group, idx) => {
      const offset = grouping.slice(0, idx).reduce((a: LegacyAny, b: LegacyAny) => a + b, 0);
      iccidSegments.push(iccid.substr(offset, group));
    });
    const finalSegments = iccidSegments.filter((s) => s.length > 0);
    // @ts-expect-error (legacy code incremental fix)
    this.iccid.setValue(finalSegments.join(''));
    this.iccidFormatted.value = finalSegments.join(' ');
  };

  registerSim = async () => {
    console.log(this.formGroup.value);
    try {
      this.isRegistering = true;
      this.isRegistered = false;
      this.alertManager.clear();

      const imsiMode = this.idType === 'imsi';
      const formValue = this.formGroup.value;
      const subscriber: any = {
        imsi: imsiMode ? formValue.imsi : formValue.iccid,
        registrationSecret: imsiMode ? formValue.password : formValue.puk,
        operatorId: this.loginUserDataService.getLoginUser()?.operatorId,
      };
      if (formValue.name) {
        subscriber.tags = {
          name: formValue.name,
        };
      }

      if (formValue.groupId) {
        subscriber.groupId = formValue.groupId;
      }

      await this.soracomApiService.registerSubscriber(subscriber);

      this.isRegistered = true;
      this.closeModal(false);
      this.afterRegisterSuccessModalRef = this.uiDsModalService.open(this.afterRegisterSuccessModalTemplate, {
        title: 'subscriber_registration.header',
      });
    } catch (e: LegacyAny) {
      const error = e.data;
      if (error && error.code === 'SEM0001' && error.message && typeof error.message === 'string') {
        // This convoluted check would be unneeded if we had proper error object...
        const hintId = this.idType === 'imsi' ? 'please_check_imsi_and_pin' : 'please_check_iccid_and_puk';
        const errorMessageWithHint = `${error.message} — ${this.translate.instant('subscribers.hints.' + hintId)}`;
        this.alertManager.add(Alert.danger(errorMessageWithHint));
      } else {
        this.alertManager.add(Alert.fromApiError(e, 'common.error_messages.unknown_error'));
      }
    } finally {
      this.isRegistering = false;
    }
  };

  get imsi() {
    return this.formGroup.get('imsi');
  }
  get iccid() {
    return this.formGroup.get('iccid');
  }

  get groupId() {
    return this.formGroup.get('groupId') as FormControl;
  }
}
