import { LegacyAny } from '@soracom/shared/core';

import dayjs from 'dayjs';

import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { PortMappingUi, PortMappingsApiService } from '../../port-mappings-api.service';
import { isValidCIDR } from '../../shared/CommaSeparatedCIDRValidator';
import { CreatePortMappingRequest } from '../../shared/CreatePortMappingRequest';
import { PortMapping } from '../../shared/PortMapping';
import { PortMappingDestinationRequestForSimId } from '../../shared/PortMappingDestination';
import { PortMappingSource } from '../../shared/PortMappingSource';
import { Sim, SimplifiedSubscriber } from '@soracom/shared/soracom-api-typescript-client';
import { ExtendedSubscriberApiService } from '@soracom/shared-ng/subscriber-services';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedSim } from '@soracom/shared/sim';
import { DurationUtils } from '@soracom/shared/util-common';

type ComponentState = 'loading' | 'listing' | 'editing' | 'submitting' | 'displaying' | 'error';

@Component({
  selector: 'app-on-demand-remote-access',
  templateUrl: './on-demand-remote-access.component.html',
  styleUrls: ['./on-demand-remote-access.component.scss'],
  encapsulation: ViewEncapsulation.None, // this & styles required or Material menu appears BEHIND $uibModal
})
export class OnDemandRemoteAccessComponent
  extends DsModalContentBase<OnDemandRemoteAccessModalData, void>
  implements OnInit
{
  /**
   * The states for this component are a bit too convoluted; it should be converted to multiple, simpler child components.
   */
  state: ComponentState = 'loading';
  /**sub state needed to know if to have a delete button in the mapping display screen */
  mappingAdded = false;

  // @ts-expect-error (legacy code incremental fix)
  @Input() imsi: string;

  // @ts-expect-error (legacy code incremental fix)
  @Input() lastPortMappingCreatedTime: number;

  // @ts-expect-error (legacy code incremental fix)
  @ViewChild('napterConfigForm', { static: true }) napterConfigForm: NgForm;

  // The UI edits the controller properties below. Then they (may) get propagated to a model object:
  port = 22;
  duration = 60 * 30;
  ipAddressRange = '';
  tlsRequired = false;
  napterConnectionType: 'air' | 'arc' | undefined = undefined;
  imsiOnlineMap: { [imsi: string]: boolean } = {};

  alertsManager = new AlertsManager();

  existingPortMappings: PortMappingUi[] = [];

  // @ts-expect-error (legacy code incremental fix)
  displayedPortMapping: PortMappingUi;

  loadingMessage: string | null = null;

  hasOnlineAirSim: boolean = false;
  hasOnlineVSim: boolean = false;
  airNotAvailable: boolean | undefined = false;
  arcNotAvailable: boolean | undefined = false;

  constructor(
    private logger: Logger = LoggerService.shared(),
    private apiService: PortMappingsApiService,
    private subscriberService: ExtendedSubscriberApiService,
    private translateService: TranslateService,
  ) {
    super();
  }

  async ngOnInit() {
    // A test like this allows the component to be used with bindings, in the normal Angular 8 way, but also support being used in a modal hosted by legacy AngularJS code that needs to use the SoracomUserConsole singleton to pass data into the modal. If normal Angular bindings were being used, `this.imsi` etc would already be set.
    if (this.modalData) {
      if (!this.imsi) {
        this.imsi = this.modalData?.mostLikelyMappingImsi;
      }
      if (!this.lastPortMappingCreatedTime) {
        // If the current name doesn't point to a value, check for the legacy name, and if present copy its value to the current name. After this, we never have to think about the legacy name again, as far as this component is concerned.
        this.lastPortMappingCreatedTime = this.modalData.lastPortMappingCreatedTime;
      }
    }

    await this.initImsiOnlineStatus();
    await this.listExistingPortMappings();

    if (!this.lastPortMappingCreatedTime) {
      // Unfortunately, due to https://soracom.atlassian.net/browse/SS-8859 the backend always returns NULL for this when using the /v1/sims API. So, we have to check using the /v1/subscribers API to be sure:
      // @ts-expect-error (legacy code incremental fix)
      this.lastPortMappingCreatedTime = undefined; // undefined means 'not sure yet' to the template
      this.workaroundHackCheckLastPortMappingCreatedTime();
    }
  }

  workaroundHackCheckLastPortMappingCreatedTime() {
    this.apiService.workaroundHackCheckLastPortMappingCreatedTime(this.imsi).then((time: LegacyAny) => {
      this.lastPortMappingCreatedTime = time || false;
    });
  }

  /**
   * We sometimes have to check this asynchronously, so `undefined` means the check hasn't completed yet.
   */
  hasPortMappingBeenCreatedThisMonth(): boolean | undefined {
    if (this.lastPortMappingCreatedTime === undefined) {
      return undefined;
    }

    let lastCreateTime: dayjs.Dayjs;
    if (this.lastPortMappingCreatedTime) {
      lastCreateTime = dayjs(this.lastPortMappingCreatedTime);
    } else {
      return false;
    }

    const now = dayjs();
    return now.year() === lastCreateTime.year() && now.month() === lastCreateTime.month();
  }

  async onAddMapping() {
    const newMapping = new PortMapping();
    this.displayedPortMapping = newMapping;
    this.state = 'editing';
    await this.initImsiOnlineStatus();
  }

  /**
   * Cancels editing. If there were no existing port mappings, this dismisses the modal (because we go directly to editing state if the modal opens and there are no existing mappings).
   */
  onCancelEditing() {
    if (this.existingPortMappings.length > 0) {
      this.state = 'listing';
    } else {
      this.onCancel();
    }
  }

  async listExistingPortMappings() {
    try {
      this.state = 'loading';
      this.loadingMessage = null;
      const response = await this.apiService.listPortMappingsForSim(this.simId ?? '');
      this.existingPortMappings = response;

      if (this.existingPortMappings.length > 0) {
        this.state = 'listing';
      } else {
        this.onAddMapping();
      }
    } catch (error: LegacyAny) {
      this.logger.debug(' listExistingPortMappings() → catch', error);
      this.state = 'error';
      this.alertsManager.add(Alert.danger(error.data));
    }
  }

  /**
   * Parse the user input string (potentially comma-separated) into array of CIDR ip address ranges..
   */
  get ipAddressRanges() {
    let ranges = this.ipAddressRange
      .trim()
      .split(',')
      .map((e) => e.trim());

    if (ranges.length === 1 && ranges[0] === '') {
      ranges = [];
    }
    return ranges;
  }

  createPortMapping() {
    const ipRanges = this.ipAddressRanges;

    let canProceed = true; // FIXME: replace this hack with real custom validator

    ipRanges.forEach((e) => {
      if (!isValidCIDR(e)) {
        canProceed = false;
      }
    });

    if (!canProceed) {
      this.alertsManager.add(Alert.danger('invalid IP address range'));
      return;
    }

    this.state = 'submitting';
    this.loadingMessage = 'on-demand-remote-access.message.submitting';

    const destination = new PortMappingDestinationRequestForSimId(
      this.port,
      this.simId || '',
      this.napterConnectionType ?? 'air',
    );
    const source = ipRanges.length > 0 ? new PortMappingSource(ipRanges) : undefined;
    // @ts-expect-error (legacy code incremental fix)
    const req = new CreatePortMappingRequest(false, Number(this.duration), destination, source);

    req.tlsRequired = this.tlsRequired;

    this.apiService
      .createPortMapping(req)
      .then((response: LegacyAny) => {
        this.logger.debug('GOT RESPONSE: ', response);
        this.state = 'displaying';
        this.mappingAdded = true;
        this.displayedPortMapping = response;
      })
      .catch((error: LegacyAny) => {
        this.logger.debug('CAUGHT ERROR: ', error);
        this.alertsManager.add(Alert.danger(error.data));
        this.state = 'editing'; // dont change, let user try again?
        if (this.existingPortMappings.length > 0 && error.data.code === 'SEM0160') {
          this.state = 'listing';
        }
      })
      .finally(() => {
        this.loadingMessage = null;
        this.logger.debug('FINALLY... ', this);
      });
  }

  get canEnableRemoteAccess() {
    const isValid = this.napterConnectionType && this.napterConfigForm.valid;

    return this.state === 'editing' && isValid;
  }

  deleteMapping(mapping: PortMapping) {
    if (!mapping) {
      this.alertsManager.add(Alert.danger('FIXME: oh no a bug: no target mapping to delete'));
      return;
    }
    const ipAddress = mapping.ipAddress;
    const port = mapping.port;

    if (!ipAddress || !port) {
      this.alertsManager.add(Alert.danger('FIXME: oh no a bug: !ipAddress || !port'));
      return;
    }

    this.state = 'loading';
    this.loadingMessage = 'on-demand-remote-access.message.deleting';

    this.apiService
      .deletePortMapping(ipAddress, port)
      .then((response: LegacyAny) => {
        this.logger.debug('Deleted port mapping successfully.', response);
        this.listExistingPortMappings();
        // this.alertsManager.add(Alert.success('on-demand-remote-access.thePortMappingWasDeletedSuccessfully'));
      })
      .catch((errResponse: LegacyAny) => {
        this.state = 'error';
        this.alertsManager.add(Alert.danger(errResponse));
        this.loadingMessage = null;
      });
  }

  close() {
    this.modalInstance.close();
  }

  onCancel() {
    this.modalInstance.close();
  }

  onDurationChange() {
    this.logger.debug('onDurationChange()');
  }

  onIpRangesChange($event: LegacyAny, ipRanges: LegacyAny) {
    this.logger.debug('onIpRangesChange($event, ipRanges)', $event, ipRanges);
  }

  onIpRangesEnterKey($event: LegacyAny, ipRanges: LegacyAny) {
    this.logger.debug('onIpRangesEnterKey($event, ipRanges)', $event, ipRanges);
    ipRanges.control.markAsTouched();

    if (this.canEnableRemoteAccess) {
      this.createPortMapping();
    }
  }

  get modalInstance() {
    return this.modalRef;
  }

  allUiDefinedDurations = [
    // {value: 0, id:"on-demand-remote-access.durations.one_time"},
    { value: 60 * 30, id: 'on-demand-remote-access.durations.thirty_minutes' },
    { value: 60 * 60, id: 'on-demand-remote-access.durations.one_hour' },
    { value: 60 * 60 * 2, id: 'on-demand-remote-access.durations.two_hours' },
    { value: 60 * 60 * 4, id: 'on-demand-remote-access.durations.four_hours' },
    { value: 60 * 60 * 8, id: 'on-demand-remote-access.durations.eight_hours' },
  ];

  getTranslatedDuration(duration: number) {
    return DurationUtils.getTranslatedDurationUnsafe(duration, {
      lang: this.translateService.currentLang,
    });
  }

  get helpTextIdForEditMode() {
    return this.existingPortMappings.length < 1
      ? 'on-demand-remote-access.helpTextForEditingFirstPortMapping'
      : 'on-demand-remote-access.helpTextForEditingAdditionalPortMapping';
  }

  get isLoading() {
    return this.state === 'loading';
  }

  get isListing() {
    return this.state === 'listing';
  }

  get isEditing() {
    return this.state === 'editing';
  }

  get isSubmitting() {
    return this.state === 'submitting';
  }

  get isDisplaying() {
    return this.state === 'displaying';
  }

  get isError() {
    return this.state === 'error';
  }

  get simId(): string | undefined {
    return this.modalData?.sim.simId;
  }

  getAccessibilityParams(mapping: PortMapping) {
    let sourceRange = '';
    const source = mapping.source;
    if (source && source.ipRanges && source.ipRanges.length > 0) {
      sourceRange += '<code>';
      sourceRange += source.ipRanges.join('</code>, <code>');
      sourceRange += '</code>';
    }

    const tls = mapping.tlsRequired ? 'TLS: <strong>ON</strong>' : "TLS: <span class='darkgray'>OFF</span>";

    return {
      sourceRange,
      tls,
      hostname: '<code>' + mapping.hostname + '</code>',
      ipAddress: '<code>' + mapping.ipAddress + '</code>',
      port: '<code>' + mapping.port + '</code>',
    };
  }

  viewMappingConfig(mapping: PortMapping) {
    this.displayedPortMapping = mapping;
    this.state = 'displaying';
    this.mappingAdded = false;
  }

  canClose(): boolean {
    return !this.isLoading && !this.isSubmitting;
  }

  allowedIpRangesForPortMapping(portMapping: PortMapping) {
    let result = '';
    const source = portMapping.source;
    if (source && source.ipRanges && source.ipRanges.length > 0) {
      result += source.ipRanges.join(', ');
    }

    return result;
  }

  get cellularSims(): SimplifiedSubscriber[] | undefined {
    return this.modalData?.sim.allSubscribersWithPrimaryFirstThenSortedByImsi.filter(
      (subscriber) => subscriber.subscription !== 'planArc01',
    );
  }

  get virtualSims(): SimplifiedSubscriber[] | undefined {
    return this.modalData?.sim.allSubscribersWithPrimaryFirstThenSortedByImsi.filter(
      (subscriber) => subscriber.subscription === 'planArc01',
    );
  }

  hasOnlineCellularSubscribers(): boolean {
    return !!this.cellularSims?.find((cellularSubscriber) => this.isSubscriberOnline(cellularSubscriber));
  }

  hasOnlineVirtualSubscribers(): boolean {
    return !!this.virtualSims?.find((virtualSubscriber) => this.isSubscriberOnline(virtualSubscriber));
  }

  get sim() {
    return this.modalData?.sim;
  }

  private async initImsiOnlineStatus() {
    const prevState = this.state;
    this.state = 'loading';
    try {
      const imsis = this.sim?.imsis ?? [];
      for (let i = 0; i < imsis.length; i++) {
        const response = await this.subscriberService.getSubscriber(imsis[i]);
        this.imsiOnlineMap[imsis[i]] = response?.isOnline();
      }
    } catch (e) {
      this.alertsManager.add(Alert.fromApiError(e));
    } finally {
      this.setupInitialConnectionTypeSelection();
      this.state = prevState;
    }
  }

  isSubscriberOnline(subscriber: SimplifiedSubscriber) {
    const imsi = subscriber.imsi;
    if (imsi) {
      return this.imsiOnlineMap[imsi];
    } else {
      return false;
    }
  }

  private setupInitialConnectionTypeSelection() {
    this.hasOnlineAirSim = this.hasOnlineCellularSubscribers();
    this.hasOnlineVSim = this.hasOnlineVirtualSubscribers();
    this.airNotAvailable = this.sim?.isStandaloneVSIM();
    this.arcNotAvailable = !this.sim?.subscriptions.includes('planArc01');
    const airAvailable = this.hasOnlineAirSim && !this.airNotAvailable;
    const arcAvailable = this.hasOnlineVSim && !this.arcNotAvailable;
    if (!airAvailable && arcAvailable) {
      this.napterConnectionType = 'arc';
    } else if (!arcAvailable && airAvailable) {
      this.napterConnectionType = 'air';
    } else {
      this.napterConnectionType = undefined;
    }
  }
}
export interface OnDemandRemoteAccessModalData {
  mostLikelyMappingImsi: string;
  lastPortMappingCreatedTime: number;
  sim: ExtendedSim;
}
