import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import {
  FixedRateSimViewDataService,
  FixedRateTopUpSettingOption,
  FixedRateTopUpType,
  TopUpAmounts,
  UiFixedRateSimDataUsageDetails,
} from '../fixed-rate-sim.service';
import {
  Alert,
  AlertsComponent,
  AlertsManager,
} from '@soracom/shared-ng/soracom-ui-legacy';
import { injectOpenManualTopUpModal } from '../manual-top-up/manual-top-up-modal.component';
import { fixedRateI18n } from '../i18n';
import { DEFAULT_LOCALES, I18nItem } from '@soracom/shared/i18n';
import { FormsModule } from '@angular/forms';
import { injectOpenOperatorTopUpDefaultsComponent } from '../operator-top-up-defaults/operator-top-up-defaults.component';
import {
  DsDialChartComponent,
  DsDialChartComponentColorConfigFunc,
  DsDialChartDataDMYY,
  DsDialChartDisplayData,
} from '@soracom/shared-ng/ng-charts';
import { calculateRemainingDMYYChartDateForSimPlan } from '../utils/calculateRemainingDayMonthYearBreakdown';
import { injectOpenTopUpHistoryModal } from './history/top-up-history.component';

import {
  hasReachedTopUpLimit,
  fixedRateSimApproachingTopUpLimit,
  isApproachingTopUpLimit,
  fixedRateSimHasReachedTopUpLimit,
} from '../utils/limitCalculations';
import { yearsToDays } from '@soracom/shared/util-common';
import { localize } from '@soracom/shared/locale-service';

@Component({
  selector: 'div[appFixedRateSimDetails]',
  templateUrl: './fixed-rate-sim-details.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    SoracomI18nModule,
    AlertsComponent,
    DsDialChartComponent,
  ],
  host: {
    class: 'ds-group --gap-small',
  },
})
export class FixedRateSimDetailsComponent implements OnInit {
  @Input() imsi?: string;
  @Input() simId?: string;
  i18n = fixedRateI18n;
  fixedRateSimDetails?: UiFixedRateSimDataUsageDetails;
  alertsManager = new AlertsManager();
  @HostBinding('class.--loading') loading = false;
  private readonly openManualTopUpModal = injectOpenManualTopUpModal();
  private readonly openOperatorTopUpDefaultsComponent =
    injectOpenOperatorTopUpDefaultsComponent();
  private readonly openTopUpHistoryModal = injectOpenTopUpHistoryModal();
  topUpOptions: UiFixedRateTopUpSettingSelectOption[] = [
    {
      key: 'OPERATOR_DEFAULT',
      label: fixedRateI18n.topUpSettingOptions.operatorDefault,
    },
    { key: 'SIM-AUTO', label: fixedRateI18n.topUpSettingOptions.autoTopUp },
    { key: 'SIM-MANUAL', label: fixedRateI18n.topUpSettingOptions.manualTopUp },
  ];
  appliedTopUpOption?: UiFixedRateTopUpSettingSelectOption;
  operatorTopUpSetting?: FixedRateTopUpType;
  topUpAmounts?: TopUpAmounts;
  //
  dataChartConfig?: DsDialChartDisplayData;
  smsChartConfig?: DsDialChartDisplayData;
  ussdChartConfig?: DsDialChartDisplayData;
  remainingTimeChartConfig?: DsDialChartDataDMYY;

  constructor(
    private fixedRateSimDataService: FixedRateSimViewDataService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadDataUsage();
  }

  private loadDataUsage() {
    this.loading = true;
    this.resetChartAndSimDetails();
    if (this.imsi) {
      this.fixedRateSimDataService
        .fetchFixedRateSimComprehensiveDetails(this.imsi)
        .then(
          ({ simLevelDetails, operatorTopUpDefaultSetting, topUpAmounts }) => {
            this.topUpAmounts = topUpAmounts;
            this.fixedRateSimDetails = simLevelDetails;
            this.appliedTopUpOption = this.topUpOptions.find(
              (topUpOption) => topUpOption.key === simLevelDetails.topUpSetting
            );
            this.operatorTopUpSetting = operatorTopUpDefaultSetting;
            this.setupChartData();
          }
        )
        .catch((err) => {
          this.alertsManager.add(Alert.fromApiError(err));
        })
        .finally(() => {
          this.loading = false;
          this.cdRef.markForCheck();
        });
    }
  }

  switchTopUpSetting() {
    if (
      this.imsi &&
      this.appliedTopUpOption &&
      this.fixedRateSimDetails &&
      this.appliedTopUpOption.key !== this.fixedRateSimDetails?.topUpSetting
    ) {
      this.loading = true;
      this.fixedRateSimDataService
        .switchTopUpSettings(this.imsi, this.appliedTopUpOption.key)
        .then(() => {
          this.alertsManager.add(
            Alert.success(localize(fixedRateI18n.topUpSettingSwitchSuccess))
          );
        })
        .catch((err) => {
          this.alertsManager.add(Alert.fromApiError(err));
        })
        .finally(() => {
          this.loadDataUsage(); //Reload datausage settings after switching, regardless of success or error
        });
    }
  }

  manualTopUp() {
    if (this.imsi) {
      this.openManualTopUpModal(
        this.simId ?? '',
        this.imsi,
        this.fixedRateSimDetails
      )
        .then((result) => {
          if (result?.success) {
            this.alertsManager.add(
              Alert.success(localize(fixedRateI18n.manualTopupSuccess))
            );
            this.loadDataUsage();
          }
        })
        .catch((err) => {
          this.alertsManager.add(Alert.fromApiError(err)); //Shouldn't happen, but just incase.
        })
        .finally(() => {
          this.cdRef.markForCheck();
        });
    }
  }

  operatorDefaultsEdit() {
    this.openOperatorTopUpDefaultsComponent(this.operatorTopUpSetting)
      .then((result) => {
        if (result?.success) {
          this.alertsManager.add(
            Alert.success(
              localize(fixedRateI18n.operatorTopUpDefaultsEditSuccess)
            )
          );
          this.loadDataUsage();
        }
      })
      .catch((err) => {
        this.alertsManager.add(Alert.fromApiError(err)); //Shouldn't happen, but just incase.
      })
      .finally(() => {
        this.cdRef.markForCheck();
      });
  }

  viewHistory() {
    if (this.fixedRateSimDetails) {
      this.openTopUpHistoryModal(this.simId ?? '', this.fixedRateSimDetails);
    }
  }

  get isOperatorDefaultTopUpSetting() {
    return this.appliedTopUpOption?.key === 'OPERATOR_DEFAULT';
  }

  get isAutoTopUp() {
    return (
      this.fixedRateSimDetails?.topUpSetting === 'SIM-AUTO' ||
      (this.fixedRateSimDetails?.topUpSetting === 'OPERATOR_DEFAULT' &&
        this.operatorTopUpSetting === 'AUTO')
    );
  }

  get isManualTopUp() {
    return (
      this.fixedRateSimDetails?.topUpSetting === 'SIM-MANUAL' ||
      (this.fixedRateSimDetails?.topUpSetting === 'OPERATOR_DEFAULT' &&
        this.operatorTopUpSetting === 'MANUAL')
    );
  }

  private setupChartData() {
    this.dataChartConfig = {
      current: { value: this.fixedRateSimDetails?.dataUsage ?? 0, label: 'MB' },
      total: {
        value: this.fixedRateSimDetails?.maximumAllowedDataUsage ?? 0,
        label: 'MB',
      },
    };
    this.smsChartConfig = {
      current: { value: this.fixedRateSimDetails?.smsUsage ?? 0, label: ' ' },
      total: {
        value: this.fixedRateSimDetails?.maximumAllowedSmsUsage ?? 0,
        label: ' ',
      },
    };
    this.ussdChartConfig = {
      current: { value: this.fixedRateSimDetails?.ussdUsage ?? 0, label: ' ' },
      total: {
        value: this.fixedRateSimDetails?.maximumAllowedUssdUsage ?? 0,
        label: ' ',
      },
    };
    if (this.fixedRateSimDetails?.expiryDate) {
      this.remainingTimeChartConfig = {
        ...calculateRemainingDMYYChartDateForSimPlan(
          this.fixedRateSimDetails?.expiryDate,
          this.fixedRateSimDetails?.contractYears
        ),
        isRemainder: true,
      };
    }
  }

  private resetChartAndSimDetails() {
    this.fixedRateSimDetails = undefined;
    this.dataChartConfig = undefined;
    this.smsChartConfig = undefined;
    this.ussdChartConfig = undefined;
    this.remainingTimeChartConfig = undefined;
  }

  get isTerminated(): boolean {
    return this.fixedRateSimDetails?.currentStatus === 'terminated';
  }

  get isApproachingLimit(): boolean {
    return (
      !!this.fixedRateSimDetails &&
      !!this.topUpAmounts &&
      !fixedRateSimHasReachedTopUpLimit(this.fixedRateSimDetails) &&
      fixedRateSimApproachingTopUpLimit(
        this.fixedRateSimDetails,
        this.topUpAmounts
      )
    );
  }

  get hasReachedLimit(): boolean {
    return (
      !!this.fixedRateSimDetails &&
      !!this.topUpAmounts &&
      fixedRateSimHasReachedTopUpLimit(this.fixedRateSimDetails)
    );
  }

  get dataChartColorConfig(): DsDialChartComponentColorConfigFunc | undefined {
    return this.topUpAmounts
      ? this.dialChartColorConfigFunctionForFixedRate(this.topUpAmounts?.data)
      : undefined;
  }

  get smsChartColorConfig(): DsDialChartComponentColorConfigFunc | undefined {
    return this.topUpAmounts
      ? this.dialChartColorConfigFunctionForFixedRate(this.topUpAmounts?.sms)
      : undefined;
  }

  get ussdChartColorConfig(): DsDialChartComponentColorConfigFunc | undefined {
    return this.topUpAmounts
      ? this.dialChartColorConfigFunctionForFixedRate(this.topUpAmounts?.ussd)
      : undefined;
  }

  get remainingTimeChartColorConfig():
    | DsDialChartComponentColorConfigFunc
    | undefined {
    return this.topUpAmounts
      ? this.dialChartColorConfigFunctionForFixedRate(
          yearsToDays(this.topUpAmounts?.expiry)
        )
      : undefined;
  }

  private dialChartColorConfigFunctionForFixedRate(
    topUpAmount: number
  ): DsDialChartComponentColorConfigFunc {
    return (current: number, total: number) => {
      if (hasReachedTopUpLimit(current, total)) {
        return { leftColor: 'red', rightColor: 'red' };
      } else if (isApproachingTopUpLimit(current, total, topUpAmount)) {
        return { leftColor: 'orange', rightColor: 'yellow' };
      } else {
        return { leftColor: 'blue', rightColor: 'celesteTint' };
      }
    };
  }
}

interface UiFixedRateTopUpSettingSelectOption {
  key: FixedRateTopUpSettingOption;
  label: I18nItem<DEFAULT_LOCALES>;
}
