import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SimApiListSimSessionEventsRequest } from '@soracom/shared/soracom-api-typescript-client';
import { CellInfo } from '@soracom/shared/soracom-platform';
import { formatDateTime, getDefaultUtcOffsetString } from '@soracom/shared/util-common';
import { Observable } from 'rxjs';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberSessionLog } from '../../../../app/shared/core/subscriber_session_log';
import { VirtualPrivateGatewaysService } from '../../../../app/shared/virtual_private_gateways/virtual_private_gateways.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';

type PaginationOptions = Omit<SimApiListSimSessionEventsRequest, 'simId'>;

@Component({
  selector: 'app-sim-session-logs',
  templateUrl: './sim-session-logs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimSessionLogsComponent {
  private simService = inject(SimApiService);
  private subscriberService = inject(SubscriberApiService);
  private vpgService = inject(VirtualPrivateGatewaysService);
  public coverageType = inject(CoverageTypeService);

  // @ts-expect-error (legacy code incremental fix)
  @Input() subscriber$: Observable<ExtendedSubscriberInterface>;
  // @ts-expect-error (legacy code incremental fix)
  subscriber: ExtendedSubscriberInterface;
  // @ts-expect-error (legacy code incremental fix)
  private imsi: string;
  alertManager = new AlertsManager();
  isLoading = false;
  // @ts-expect-error (legacy code incremental fix)
  sessionLogs: SubscriberSessionLog[];
  // @ts-expect-error (legacy code incremental fix)
  reachedEnd: boolean;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriber$.subscribe((subscriber) => {
      this.subscriber = subscriber;
      if (subscriber) {
        this.imsi = subscriber.imsi;
        this.fetchLogs();
      }
    });
  }

  async fetchLogs() {
    const options: PaginationOptions = {
      limit: 10,
    };

    this.alertManager.clear();
    this.reachedEnd = false;
    this.isLoading = true;

    try {
      this.sessionLogs = await this.getSessionLogs(options);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
      this.updateVpgNames();
      this.cdRef.markForCheck();
    }
  }

  async loadNext() {
    const lastItem = this.sessionLogs[this.sessionLogs.length - 1];
    const options = {
      limit: 50,
      lastEvaluatedKey: String(lastItem.time),
    };
    this.alertManager.clear();
    this.isLoading = true;

    try {
      const sessionLogs = await this.getSessionLogs(options);
      if (sessionLogs.length > 0) {
        this.sessionLogs.push(...sessionLogs);
      }
      if (sessionLogs.length < options.limit) {
        this.reachedEnd = true;
      }
      this.updateVpgNames();
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isLoading = false;
      this.cdRef.markForCheck();
    }
  }

  hasSessionLogs(): boolean {
    return !this.isLoading && this.sessionLogs?.length > 0;
  }

  shouldShowSubscriptionColumn(): boolean {
    // We don't get the subscription field when using old /v1/subscribers API... hence this check
    return this.hasSessionLogs() && !!this.sessionLogs[0].subscription;
  }

  getTimezoneString(): string {
    return '(UTC ' + getDefaultUtcOffsetString() + ')';
  }

  formatLogTime(time: number) {
    return formatDateTime(time, 'datetime_sec');
  }

  protected getVpgUrl(vpgId: string): string {
    return '/virtual_private_gateways/' + vpgId;
  }

  protected getVpgUrlQueryParams() {
    // If we don't put on the coverage_type, the link will sometimes fail if in new tab (and the VPG is in the non-home coverage type of the authenticated user)
    const coverage_type = this.coverageType.getCoverageType();
    const query = coverage_type === 'jp' || coverage_type === 'g' ? { coverage_type } : {};
    return query;
  }

  /**
   * Used after loading the session list, to rehydrate the VPG names from the VPG IDs. (The table will briefly show the VPG IDs, instead of the name if this takes a while.)
   */
  private updateVpgNames() {
    if (!this.sessionLogs) {
      return;
    }

    const vpgIds = new Set(this.sessionLogs.map((log) => log.vpgId).filter((id) => !!id));

    const vpgNameGets = Array.from(vpgIds).map((id) => {
      // @ts-expect-error (legacy code incremental fix)
      return { id: id, promise: this.vpgService.get(id) };
    });

    // Mason 2022-11-09: forEach in forEach is a bit lame, but it's simple, and the size of this table is capped.
    vpgNameGets.forEach(({ id, promise }) => {
      promise
        .then((vpg) => {
          this.sessionLogs.forEach((log) => {
            if (log.vpgId === vpg.id) {
              log.vpgName = vpg.name;
              log.vpgIsLinkable = true;
            }
          });
        })
        .catch((e: LegacyAny) => {
          // This is normal because it happens with PublicGate... and PublicGate-XXX and PrivateGarden... should we hardcode these to avoid the extra unneeded API calls? OTOH, what if some customer names their VPG like "PublicGate-sucks-use-this-instead"? For now, don't be clever:
          this.sessionLogs.forEach((log) => {
            if (log.vpgId === id) {
              log.vpgIsLinkable = false;
            }
          });
        })
        .finally(() => {
          // @masonmark: not the most efficient thing to do, but I am just wedging this into how we already do it in the non-async/await style.
          this.cdRef.markForCheck();
        });
    });

    // NOTE: I wanted to use Promise.allSettled() to do this, but as of 2022-11-09 at least we cannot use Promise.allSettled() in our codebase.
  }

  private getSessionLogs(options: PaginationOptions) {
    const isSim = this.subscriber?.isSim;
    const mainPromise = isSim
      ? this.simService.listSimSessionEvents({ simId: this.subscriber.simId, ...options })
      : this.subscriberService.listSessionEvents({ imsi: this.imsi, ...options });

    const promise = mainPromise.then((res: LegacyAny) => {
      const sessionLogs: SubscriberSessionLog[] = res.data.map((elem: any) => new SubscriberSessionLog(elem));
      sessionLogs.forEach((sessionLog) => {
        if (sessionLog.cell) {
          sessionLog.cellInfo = new CellInfo(sessionLog.cell);
        }
      });
      return sessionLogs;
    });

    return promise;
  }
}
