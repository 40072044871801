import { Injectable } from '@angular/core';
import {
  FixedRatePlanApiService,
  FixedRateSimDataUsageApiResponse,
  FixedRateSimLifecycleEvent,
  FixedRateSimLifecycleEventName,
} from './fixed-rate-plan-api.service';
import { SubscriptionPlanName } from '@foundation/soracom-platform';
import { formatDateTime } from '@soracom/shared/util-common';
import { addYearsToTimestamp } from './utils';
import { fixedRateI18n } from './i18n';
import { convertByteUnits } from '@soracom/shared-ng/ui-common';
import { localize } from '@soracom/shared/locale-service';

export interface UiFixedRateSimDataUsageDetails {
  imsi: string | undefined;
  subscriptionPlan: SubscriptionPlanName;
  dataUsage: number;
  maximumAllowedDataUsage: number;
  smsUsage: number;
  maximumAllowedSmsUsage: number;
  ussdUsage: number;
  maximumAllowedUssdUsage: number;
  contractYears: number;
  startDate: string;
  expiryDate: string;
  topUpSetting: FixedRateTopUpSettingOption;
  calculatedTime?: string;
  history: UiTopupHistoryRecord[];
  currentStatus: FixedRateSimLifecycleEventName;
}

export interface UiComprehensiveFixedRateSimUsageDetails {
  simLevelDetails: UiFixedRateSimDataUsageDetails;
  operatorTopUpDefaultSetting: FixedRateTopUpType;
  topUpAmounts: TopUpAmounts;
}

export type FixedRateTopUpType = 'AUTO' | 'MANUAL';

export interface UiTopupHistoryRecord {
  date: string;
  name: string;
  reason: string;
}

export type FixedRateTopUpSettingOption =
  | 'OPERATOR_DEFAULT'
  | 'SIM-AUTO'
  | 'SIM-MANUAL';

export interface TopUpAmounts {
  data: number;
  sms: number;
  ussd: number;
  /**Number in years */
  expiry: number;
}

/** Calls necessary API calls, converts response data to view data for fixed rate sim. */
@Injectable({
  providedIn: 'root',
})
export class FixedRateSimViewDataService {
  private i18n = fixedRateI18n;
  constructor(private fixedRateApiService: FixedRatePlanApiService) {}

  getSimDataUsageDetails(
    imsi: string,
    subsciptionPlan: SubscriptionPlanName = 'planFX1'
  ): Promise<UiFixedRateSimDataUsageDetails> {
    const apiCallTime = formatDateTime(new Date(), 'datetime_sec_tz');
    return this.fixedRateApiService
      .getFixedRateSimDataUsage(imsi)
      .then((res) => {
        return {
          imsi: imsi,
          subscriptionPlan: subsciptionPlan,
          maximumAllowedDataUsage: convertByteUnits(
            res.basicInformation.dataBytes,
            'MiB',
            {
              nDecimals: 0,
            }
          ),
          maximumAllowedSmsUsage: res.basicInformation.numberOfSms,
          maximumAllowedUssdUsage: res.basicInformation.numberOfUssd,
          dataUsage: convertByteUnits(res.usage.dataBytes, 'MiB', {
            nDecimals: 0,
          }),
          smsUsage: res.usage.numberOfSms,
          ussdUsage: res.usage.numberOfUssd,
          contractYears: res.basicInformation.contractYears,
          startDate: this.getStartDateFromApiResponse(res) ?? '',
          expiryDate: this.getEndDateFromApiResponse(res) ?? '',
          topUpSetting: this.getTopUpSettingFromApiResponse(res),
          calculatedTime: apiCallTime,
          history: this.getTopUpHistoryViewDataFromApiResponse(res),
          currentStatus: res.status,
        };
      });
  }

  requestManualTopUp(imsi: string): Promise<void> {
    return this.fixedRateApiService.requestManualTopup(imsi);
  }

  switchTopUpSettings(
    imsi: string,
    topUpSetting: FixedRateTopUpSettingOption
  ): Promise<void> {
    switch (topUpSetting) {
      case 'SIM-AUTO':
        return this.setSimLevelAutoTopUpOn(imsi);
      case 'SIM-MANUAL':
        return this.setSimLevelAutoTopUpOff(imsi);
      case 'OPERATOR_DEFAULT':
        return this.disableSimLevelAutoTopUp(imsi);
      default:
        throw new Error(`Unknown topup setting: ${topUpSetting}`);
    }
  }

  getOperatorTopUpDefaults(): Promise<FixedRateTopUpType> {
    return this.fixedRateApiService
      .getOperatorFixedRatePlanConfiguration()
      .then((res) => {
        if (res?.configuration?.global?.automaticTopUpEnabled === undefined) {
          return 'MANUAL'; //Default configuration is manual, so if opConfig is not defined, assume manual.
        } else {
          return res.configuration.global.automaticTopUpEnabled
            ? 'AUTO'
            : 'MANUAL';
        }
      });
  }

  setOperatorTopUpDefaults(topUpSetting: FixedRateTopUpType) {
    return this.fixedRateApiService.setOperatorFixedRatePlanConfiguration(
      topUpSetting === 'AUTO'
    );
  }

  /**
   * Some operators can have customized initial data/sms/ussd values, as well as
   * customized top-up amounts. In the case of custom or default top-up amounts, the same API contains that information.
   * @returns TopUpAmounts
   */
  getTopUpAmounts(imsi: string): Promise<TopUpAmounts> {
    return this.fixedRateApiService
      .getFixedRateSimTopUpUnit(imsi)
      .then((res) => {
        return {
          data: convertByteUnits(res.additionalDataBytes, 'MiB', {
            nDecimals: 0,
          }),
          sms: res.additionalNumberOfSms,
          ussd: res.additionalNumberOfUssd,
          expiry: res.contractYears,
        };
      });
  }

  /**
   * Compiles all API calls necessary to get all information related to Fixed Rate SIMs.
   * Return object includes data-usage/fixed rate settings for the SIM, and the operator's top-up defaults.
   * @param imsi
   * @returns Promise<UiComprehensiveFixedRateSimUsageDetails>
   */
  fetchFixedRateSimComprehensiveDetails(
    imsi: string
  ): Promise<UiComprehensiveFixedRateSimUsageDetails> {
    return Promise.all([
      this.getSimDataUsageDetails(imsi),
      this.getOperatorTopUpDefaults(),
      this.getTopUpAmounts(imsi),
    ]).then(([simLevelDetails, operatorTopUpDefaultSetting, topUpAmounts]) => {
      return {
        simLevelDetails,
        operatorTopUpDefaultSetting,
        topUpAmounts,
      };
    });
  }

  /**This method corresponds to having a SIM use operator defaults, as its sim-level auto top settings will be set to undefined (disabled). (Use Operator defaults) */
  private disableSimLevelAutoTopUp(imsi: string): Promise<void> {
    return this.fixedRateApiService.removeSimLevelAutoTopUp(imsi);
  }

  /**This method will set a SIM to use auto-top-up settings, set to auto-top-up. (Operator defaults ignored) */
  private setSimLevelAutoTopUpOn(imsi: string): Promise<void> {
    return this.fixedRateApiService.setSimLevelAutoTopUpTrue(imsi);
  }

  /**This method will set a SIM to use auto-top-up settings, set to NOT auto-top-up. (Operator defaults ignored). SIM should be manually topped up. */
  private setSimLevelAutoTopUpOff(imsi: string): Promise<void> {
    return this.fixedRateApiService.setSimLevelAutoTopUpFalse(imsi);
  }

  private getStartDateFromApiResponse(
    apiResponse: FixedRateSimDataUsageApiResponse
  ): string | undefined {
    const startDate = apiResponse.events?.find(
      (event) => event.eventName === 'contracted'
    )?.eventTime;
    return startDate ? formatDateTime(startDate, 'date') : undefined;
  }

  private getEndDateFromApiResponse(
    apiResponse: FixedRateSimDataUsageApiResponse
  ): string | undefined {
    const topUpEvents =
      apiResponse.events?.filter(
        (event) =>
          event.eventName === 'toppedUp' || event.eventName === 'contracted'
      ) ?? [];
    const mostRecentEvent = topUpEvents.reduce((currentMaxEvent, event) => {
      if (!currentMaxEvent || event.eventTime > currentMaxEvent.eventTime) {
        return event;
      } else {
        return currentMaxEvent;
      }
    }, null as FixedRateSimLifecycleEvent | null);
    const yearsToAdd = apiResponse.basicInformation.contractYears;
    const expiryDate = mostRecentEvent
      ? addYearsToTimestamp(mostRecentEvent.eventTime, yearsToAdd)
      : undefined;

    return formatDateTime(expiryDate, 'date');
  }

  private getTopUpSettingFromApiResponse(
    apiResponse: FixedRateSimDataUsageApiResponse
  ): FixedRateTopUpSettingOption {
    if (apiResponse.automaticTopUpEnabled === undefined) {
      return 'OPERATOR_DEFAULT';
    } else if (apiResponse.automaticTopUpEnabled) {
      return 'SIM-AUTO';
    } else {
      return 'SIM-MANUAL';
    }
  }

  private getTopUpHistoryViewDataFromApiResponse(
    apiResponse: FixedRateSimDataUsageApiResponse
  ): UiTopupHistoryRecord[] {
    const topUpHistory = apiResponse.events ?? [];
    return topUpHistory.map((event) => {
      return {
        date: formatDateTime(event.eventTime, 'datetime') ?? '',
        name: localize(this.i18n.history.eventName[event.eventName]),
        reason:
          event.eventName === 'contracted' || event.eventName === 'transferred'
            ? '-'
            : localize(this.i18n.history.eventReason[event.eventReason]),
      };
    });
  }
}
