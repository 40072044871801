import { formatDateTime } from '@soracom/shared/util-common';

export function addYearsToTimestamp(timestamp: number, yearsToAdd: number) {
  const date = new Date(timestamp);
  date.setFullYear(date.getFullYear() + yearsToAdd);
  const newTimestamp = date.getTime();
  return newTimestamp;
}

/**Returns date x years from now */
export function xYearsFromNow(yearsFromNow: number): string {
  const futureTimeStamp = addYearsToTimestamp(
    new Date().getTime(),
    yearsFromNow
  );
  return formatDateTime(futureTimeStamp, 'date') ?? '';
}
