import { Directive, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// allow $scope to be provided to ng1
const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: (injector: Injector) => injector.get('$rootScope').$new(),
};

@Directive({
  selector: 'appPrefixedEventHandlerByImsi',
  providers: [ScopeProvider],
})
export class PrefixedEventHandlersByImsiDirective extends UpgradeComponent {
  @Input() imsi!: string;
  @Input() groupId!: string;
  @Input() closeModal!: () => void;

  constructor(elemRef: ElementRef, injector: Injector) {
    super('scPrefixedEventHandlersByImsi', elemRef, injector);
  }
}
