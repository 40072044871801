import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { CoverageTypeService, getOperatorData } from '@soracom/shared/data-access-auth';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SpeedClass } from '../../shared/SpeedClass';
import { SubscriberBatchSpeedClassUpdater } from '../../shared/SubscriberBatchSpeedClassUpdater';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiSelect } from '../../soracom-ui/ui-select/UiSelect';

export interface ChangeSubscriberSpeedClassComponentInput {
  subscribers: ExtendedSubscriberInterface[];
  specifiedNextSpeedClass?: SpeedClass;
}

@Component({
  selector: 'app-change-subscribers-speed-class',
  templateUrl: 'change-subscribers-speed-class.component.html',
})
export class ChangeSubscriberSpeedClassComponent
  extends DsModalContentBase<ChangeSubscriberSpeedClassComponentInput, ChangeSubscribersSpeedClassCompletionData>
  implements OnInit
{
  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    public coverageTypeService: CoverageTypeService,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
  }

  ngOnInit() {
    // @ts-expect-error (legacy code incremental fix)
    this.nextSpeedClass = this.modalData.specifiedNextSpeedClass; // if any
    // @ts-expect-error (legacy code incremental fix)
    this.speedClassUpdaters = this.makeUpdatersForSubscribers(this.modalData.subscribers);

    // This next section is not yet used but it is preparing for https://app.clubhouse.io/soracom/story/20751/conditionalize-help-text-in-change-speed-class-ui-based-on-actual-changeability :

    let editability: 'all' | 'some' | 'none' = 'some';

    if (this.speedClassUpdaters.every((u) => u.availableSpeedClasses.length < 2)) {
      editability = 'none';
    } else if (this.speedClassUpdaters.every((u) => u.availableSpeedClasses.length > 1)) {
      editability = 'all';
    }
    this.logger.debug(
      `Editability is: ${editability}. Someday we will use this to improve help text: https://app.clubhouse.io/soracom/story/20751/conditionalize-help-text-in-change-speed-class-ui-based-on-actual-changeability`
    );
  }

  get outputType(): ExtendedSubscriberInterface[] {
    return [];
  }

  get output(): ExtendedSubscriberInterface[] {
    const result: ExtendedSubscriberInterface[] = [];
    this.speedClassUpdaters.forEach((updater) => {
      updater.subscribers.forEach((subscriber) => {
        result.push(subscriber);
      });
    });
    return result;
  }

  /**
   * This is only available sometimes (when this modal is invoked with a predetermined next speed class already decided).
   */
  // @ts-expect-error (legacy code incremental fix)
  nextSpeedClass: SpeedClass;

  /**
   * When initialized, this modal parses its input into groups. The reason for this is that different kinds of SIMs are compatible with different speed classes. So the SIMs are organized into (potentially) multiple groups, and the UI lets the user pick the speed class for each group. Probably often there will just be one group, however. (The AngularJS version of this code just showed an error message in the case where not all selected SIMs could be set to the same new speed class.)
   */
  // @ts-expect-error (legacy code incremental fix)
  speedClassUpdaters: SubscriberBatchSpeedClassUpdater[];

  /**
   * Since we have an array of updaters we also need an array of UiSelects.
   */
  // @ts-expect-error (legacy code incremental fix)
  speedClassSelects: UiSelect[];

  getSpeedClassSelect(index: number) {
    return this.speedClassSelects[index];
  }

  /**
   * Utility method to group the subscribers by compatible speed class, and create a batch updater for each group.
   */
  makeUpdatersForSubscribers(subscribers: ExtendedSubscriberInterface[]) {
    // As of 2019-08-23 anyway, u1.xxx family SIMs have 2 u1 speed classes, and all other SIMs get the traditional s1 speed classes
    // UPDATE 2020-12-12 that is wrong now and was actually wrong then, too. There is an additional special case: plan-KM1. So we need to just divide the subscribers into groups generically, based on what possible speed classes they can be set to, and then render a batch updater for each group.

    // Since speed class names don't contain spaces, I will just use a space-separated list of IDs as the string representation of a group of speed classes here.
    const subscribersByPossibleSpeedClasses: { [key: string]: ExtendedSubscriberInterface[] } = {};

    const necContractCode = getOperatorData().getNecContractCode();

    subscribers.forEach((subscriber) => {
      const possibleSpeedClasses = SpeedClass.possibleSpeedClassesForSubscriber(subscriber, necContractCode);
      const stringIdForGroup = possibleSpeedClasses.join(' ');
      if (!subscribersByPossibleSpeedClasses[stringIdForGroup]) {
        subscribersByPossibleSpeedClasses[stringIdForGroup] = [];
      }
      subscribersByPossibleSpeedClasses[stringIdForGroup].push(subscriber);
    });

    const batchUpdaters: LegacyAny[] = [];
    const keys = Object.keys(subscribersByPossibleSpeedClasses);
    const coverageType = this.coverageTypeService.getCoverageType();

    keys.forEach((key) => {
      const updater = new SubscriberBatchSpeedClassUpdater(
        this.logger,
        this.apiService,
        subscribersByPossibleSpeedClasses[key],
        // @ts-expect-error (legacy code incremental fix)
        coverageType,
        necContractCode,
        this.subscriberApiService,
        this.simApiService
      );
      batchUpdaters.push(updater);
    });

    // Handle the case where we were invoked from the menu that pre-selects the next speed class:
    batchUpdaters.forEach((updater) => {
      if (updater.availableSpeedClasses.includes(this.nextSpeedClass)) {
        updater.newSpeedClass = this.nextSpeedClass;
      }
    });

    const result = batchUpdaters.filter((u) => u.subscribers.length > 0);

    this.speedClassSelects = [];
    let index = 0;

    result.forEach((u) => {
      const select = new UiSelect(u.availableSpeedClasses);
      select.labelId = 'ChangeSubscriberSpeedClassComponent.setNewSpeedClassTo';
      select.value = u.newSpeedClass;
      select.optionFormatter = this.speedClassOptionFormatter;
      select.classes = [`x-select-speed-class x-${index++}`];
      select.onValueChange = (s) => {
        u.newSpeedClass = s.value;
      };
      this.speedClassSelects.push(select);
    });

    return result;
  }

  get uiModalExplanatoryText() {
    // @ts-expect-error (legacy code incremental fix)
    const suffix = this.modalData.subscribers.length === 1 ? 'single' : 'multiple';
    const translationKey = `ChangeSubscriberSpeedClassComponent.explanatoryText.${suffix}`;
    return translationKey;
  }

  /**
   * Simple formatter to display speed class objects by label.
   */
  speedClassOptionFormatter = (x: SpeedClass) => x.label;

  get canConfirm() {
    const newSpeedClassPresent = this.speedClassUpdaters.every((u) => !!u.newSpeedClass);
    return newSpeedClassPresent;
  }

  canClose() {
    return this.speedClassUpdaters.every((u) => u.state !== 'busy');
  }

  confirm() {
    this.speedClassUpdaters.forEach((updater) => {
      // 🌸
      updater.performUpdate().then((finishedUpdater) => {
        // 🌸
        if (finishedUpdater.state === 'succeeded') {
          this.close({
            updatedSubscribers: this.output,
            success: true,
          });
        } else {
          // The updater is responsible for showing error messages, so we do nothing.
        }
        this.logger.debug(finishedUpdater);
      });
    });
  }
}

export interface ChangeSubscribersSpeedClassModalInput {
  specifiedNextSpeedClass: SpeedClass;
  subscribers: ExtendedSubscriberInterface[];
}

export interface ChangeSubscribersSpeedClassCompletionData {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
}
