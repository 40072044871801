import { Directive } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SetImeiLockRequest } from '@soracom/shared/soracom-api-typescript-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { localize } from '@soracom/shared/locale-service';

const i18n = {
  onlyOnlineSimsCanBeLockedUnlessOneAtATime: {
    en: 'SIM is offline. To enable IMEI lock for multiple SIMs, they must all be online. IMEI lock can be enabled for SIMs that are offline, but only one at a time.',
    ja: 'SIM がオフラインです。複数の SIM に IMEI ロックを有効にするには、すべての SIM がオンラインである必要があります。 IMEI ロックはオフラインの SIM に対しても有効にできますが、その場合は 1 つずつ操作する必要があります。',
  },
};
@Directive()
export class SubscriberBatchImeiLockUpdater extends SubscriberBatchUpdater {
  constructor(
    logger: Logger,
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }
  /**
   * What operation we want to do ?
   */
  private operation: 'lock' | 'unlock' = 'lock';

  /**
   * Optional. Set this only in the case of updating a single subscriber. (It is possible via the API to lock multiple SIMs to the same IMEI, but that does not generally make sense, so console only supports specifying the IMEI when locking a single SIM. (IMEI-Locking a SIM without specifying an IMEI means "lock to current IMEI"))
   */
  // @ts-expect-error (legacy code incremental fix)
  private imei: string;

  allowAnonymousIMEI = false;

  /**
   * Function which actually calls api to lock/unlock sim.
   * It is used for two operations: lock and unlock. which is identified
   * by `operation` and particular api is called accordingly.
   */
  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    if (!subscriber.isSim) {
      throw new Error('Invalid subscriber type: LegacySubscriber is no longer supported');
    }
    return this.updateSim(subscriber);
  }

  async updateSim(subscriber: ExtendedSubscriberInterface): Promise<any> {
    if (this.operation === 'lock') {
      // falsy newImei here means 'lock to current IMEI'
      let newImei = this.subscribers.length === 1 && this.imei;
      if (this.allowAnonymousIMEI) {
        if (typeof newImei === 'string' && newImei.length > 0) {
          newImei += '|ANONYMOUS';
        } else {
          newImei = '|ANONYMOUS';
        }
      }

      // Only do this if the IMEI is blank and the anonymous IMEI is allowed. That is because blank IMEI doesn't work anymore if the new anonymous is used. Note that the UI doesn't let the user enter the IMEI unless they are only updating one SIM. And the UI also forces the user to enter an IMEI in that case. So this change will only affect multi-SIM changes, when anonymous IMEI is allowed.
      if (newImei === '|ANONYMOUS') {
        const sim = (await this.simApiService.getSim({ simId: subscriber.simId })).data;
        const currentImei = sim?.sessionStatus?.imei;
        if (!currentImei) {
          throw new Error(localize(i18n.onlyOnlineSimsCanBeLockedUnlessOneAtATime));
        }
        newImei = currentImei + '|ANONYMOUS';
      }

      const newImeiRequest = new SetImeiLockRequest();
      if (newImei) {
        newImeiRequest.imei = newImei;
      }

      return this.simApiService.setSimImeiLock({ simId: subscriber.simId, setImeiLockRequest: newImeiRequest });
    } else if (this.operation === 'unlock') {
      return this.simApiService.unsetSimImeiLock({ simId: subscriber.simId });
    }
  }

  /**
   * Wrapper for `updateFunction` to simplify update call.
   * Sets operation and imei and calls `performUpdate`.
   */
  lockImei(imei: string, allowAnonymousIMEI: boolean): Promise<SubscriberBatchUpdater> {
    this.operation = 'lock';
    this.imei = imei;
    this.allowAnonymousIMEI = allowAnonymousIMEI;
    return this.performUpdate();
  }

  /**
   * Wrapper for `updateFunction` to simplify update call.
   * Sets operation and calls `performUpdate`.
   */
  unlockImei(): Promise<SubscriberBatchUpdater> {
    this.operation = 'unlock';
    return this.performUpdate();
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.imeiLock`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return subscriber.isImeiLocked()
      ? LegacyTextContent.translation('SubscriberBatchUpdater.locked')
      : LegacyTextContent.translation('SubscriberBatchUpdater.unlocked');
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return undefined;
    // Weird case; we don't want next status to show in the table because UI is slightly different and it is shown below the table
  }
}
