<div class="ds-rows">
  <app-alerts [alertsManager]="alertsManager"></app-alerts>

  <dl class="ds-details">
    <dt>Virtual SIM IMSI</dt>
    <dd>
      <div>
        {{ activeVSIM?.imsi }}
        <span *ngIf="!activeVSIM">
          {{ 'VsimDetailsComponent.noVirtualSIM' | translate }}
        </span>
      </div>
    </dd>
  </dl>

  <ng-container *ngIf="apiMode !== 'sim'">
    <div class="ds-notice--alert">
      {{ 'subscriber_registration.vsim.vsimNotSupportedText' | translate }}
      <span [innerHtml]="'subscribers.apiMode.enableSimApiModeHelpTextBeforeLink' | translate"></span>
      <a href="" (click)="enableSimApiMode($event)" data-ga-event-name="switch_sim_management" data-ga-event-label="Switch to New SIM Management">
        {{ 'subscribers.apiMode.enableSimApiModeLinkText' | translate }}
      </a>
      <span [innerHtml]="'subscribers.apiMode.enableSimApiModeHelpTextAfterLink' | translate"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="activeVSIM && apiMode === 'sim'" id="vsim-exists">
    <div class="ds-tabs --panel --no-indent">
      <!-- Tab bar -->
      <input id="vsim-details-tab1" type="radio" name="vsim-details" checked="checked" />
      <input id="vsim-details-tab2" type="radio" name="vsim-details" />
      <input id="vsim-details-tab3" type="radio" name="vsim-details" />
      <ul>
        <li>
          <label for="vsim-details-tab1" class="ds-text --icon-session x-vsim-session-tab">{{
            'VsimDetailsComponent.session' | translate
          }}</label>
        </li>
        <li>
          <label for="vsim-details-tab2" class="ds-text --icon-credentials x-vsim-credentials-tab">{{
            'VsimDetailsComponent.credentials' | translate
          }}</label>
        </li>
        <li>
          <label for="vsim-details-tab3" class="ds-text --icon-settings x-vsim-advanced-tab">{{
            'VsimDetailsComponent.advanced' | translate
          }}</label>
        </li>
      </ul>

      <!-- First tab -->
      <div class="__content --1">
        <div class="ds-banner --color-ink --indent-small">
          <ng-container *ngIf="arcSessionStatus.arcClientPeerIpAddress">
            <i class="ds-icon --large --icon-session --success"></i>
            <h4>{{ 'VsimDetailsComponent.sessionStatus' | translate }} <span class="ds-tag --success">Online</span></h4>
          </ng-container>
          <ng-container *ngIf="!arcSessionStatus.arcClientPeerIpAddress">
            <i class="ds-icon --large --icon-session"></i>
            <h4>
              {{ 'VsimDetailsComponent.sessionStatus' | translate }} <span class="ds-tag --outline">Offline</span>
            </h4>
          </ng-container>
        </div>

        <div class="ds-rows --indent-small">
          <dl class="ds-details">
            <dt translate="VsimDetailsComponent.clientPeerIpAddress"></dt>
            <dd>
              <div>
                <span
                  class="virtual-sim-param"
                  [class.virtual-sim-param-highlight]="oldArcSessionStatus && arcSessionStatus.arcClientPeerIpAddress !== oldArcSessionStatus.arcClientPeerIpAddress"
                >
                  {{ arcSessionStatus.arcClientPeerIpAddress || '--' }}
                </span>
              </div>
            </dd>
            <dt translate="VsimDetailsComponent.clientPeerPublicKey"></dt>
            <dd>
              <div>
                <span
                  class="virtual-sim-param x-arc-client-peer-public-key"
                  [class.virtual-sim-param-highlight]="oldArcSessionStatus && arcSessionStatus.arcClientPeerPublicKey !== oldArcSessionStatus.arcClientPeerPublicKey"
                >
                  {{ arcSessionStatus.arcClientPeerPublicKey || '--' }}
                </span>
              </div>
            </dd>
            <dt>{{ 'VsimDetailsComponent.serverPeerPublicKey' | translate }}</dt>
            <dd>
              <div>
                <span
                  class="virtual-sim-param x-arc-server-peer-public-key"
                  [class.virtual-sim-param-highlight]="oldArcSessionStatus && arcSessionStatus.arcServerPeerPublicKey !== oldArcSessionStatus.arcServerPeerPublicKey"
                >
                  {{ arcSessionStatus.arcServerPeerPublicKey || '--' }}
                </span>
              </div>
            </dd>
            <dt translate="VsimDetailsComponent.serverEndpoint"></dt>
            <dd>
              <div>
                <span
                  class="virtual-sim-param x-arc-server-endpoint"
                  [class.virtual-sim-param-highlight]="oldArcSessionStatus && arcSessionStatus.arcServerEndpoint !== oldArcSessionStatus.arcServerEndpoint"
                >
                  {{ arcSessionStatus.arcServerEndpoint || '--' }}
                </span>
              </div>
            </dd>
          </dl>

          <div class="ds-text"
            [innerHtml]="'virtualSimConnectionInfo.connectionInfoLabel' | translate"
          ></div>
          <div class="ds-field">
            <div class="ds-input --copy">
              <pre>{{ textToCopy }}</pre>
              <button [appCopyButton]="textToCopy">
                <span><span>{{ 'virtualSimConnectionInfo.copyCodeLabel' | translate }}</span></span>
              </button>
            </div>
          </div>
        </div>

        <details class="ds-disclosure--simple">
          <summary translate="VsimDetailsComponent.resetVirtualSimSessionDotDotDot"></summary>
          <div class="ds-text">
            {{ 'VsimDetailsComponent.resetVirtualSimSessionHelpText' | translate }}
          </div>
          <app-alerts
            *ngIf="alertsManagerForResetSession.alerts?.length"
            [alertsManager]="alertsManagerForResetSession"
          ></app-alerts>

          <button class="ds-button --icon-rotate-ccw --alert" (click)="deleteSession()">
            <span translate="VsimDetailsComponent.resetVirtualSimSession"></span>
          </button>
        </details>
      </div>

      <!-- Second tab -->
      <div class="__content --2">
        <div class="ds-banner --color-ink --indent-small">
          <ng-container *ngIf="arcSessionStatus.arcClientPeerPublicKey">
            <i class="ds-icon --large --icon-credentials-ticked --success"></i>
            <h4>{{ 'VsimDetailsComponent.credentials' | translate }} <span class="ds-tag --success">Set</span></h4>
          </ng-container>
          <ng-container *ngIf="!arcSessionStatus.arcClientPeerPublicKey">
            <i class="ds-icon --large --icon-credentials"></i>
            <h4>{{ 'VsimDetailsComponent.credentials' | translate }} <span class="ds-tag --outline">Not set</span></h4>
          </ng-container>
        </div>

        <div class="ds-rows --indent-small">
          <dl class="ds-details">
            <dt translate="VsimDetailsComponent.clientPeerPublicKey"></dt>
            <dd>
              <div class="x-arc-credentials-client-public-key">{{ arcSessionStatus.arcClientPeerPublicKey || '--' }}</div>
            </dd>
            <dt translate="VsimDetailsComponent.serverPeerPublicKey"></dt>
            <dd>
              <div>{{ arcSessionStatus.arcServerPeerPublicKey || '--' }}</div>
            </dd>
          </dl>
        </div>

        <details class="ds-disclosure--simple">
          <summary translate="VsimDetailsComponent.updateCredentials" class="x-update-credentials-button"></summary>
          <app-alerts
            *ngIf="alertsManagerForAttachCredentials.alerts?.length"
            [alertsManager]="alertsManagerForAttachCredentials"
          ></app-alerts>
          <div class="ds-notice--info">{{ 'VsimDetailsComponent.updateCredentialsHelpText' | translate }}</div>
          <div class="ds-cols">
            <div class="ds-group --indent-small ds-span --6">
              <div class="ds-text">{{ 'VsimDetailsComponent.attachPublicKeyHelpText' | translate }}</div>
              <div class="ds-text --label">{{ 'VsimDetailsComponent.publicKey' | translate }}</div>
              <div class="ds-input">
                <input
                  class="x-arc-public-key-input"
                  type="text"
                  placeholder="{{ 'VsimDetailsComponent.publicKeyPlaceholder' | translate }}"
                  [(ngModel)]="arcClientPeerPublicKey"
                />
                <button
                  class="ds-button --addon-light --icon-credentials-add x-submit-arc-public-key"
                  (click)="attachCredentials(arcClientPeerPublicKey)"
                >
                  <span translate="VsimDetailsComponent.addCredentials"></span>
                </button>
              </div>
            </div>
            <div class="ds-group --indent-small ds-span --6">
              <div class="ds-text">{{ 'VsimDetailsComponent.autogenerateCredentialsHelpText' | translate }}</div>
              <div class="ds-text --label" translate="VsimDetailsComponent.useAutoGeneratedCredentials"></div>
              <button class="ds-button --icon-credentials x-generate-credentials-button" (click)="attachCredentials()">
                <span translate="VsimDetailsComponent.generateCredentials"></span>
              </button>
            </div>
          </div>
          <div class="ds-notice--info" *ngIf="credentialsResponseSummary">
            <p translate="VsimDetailsComponent.followingKeysAreDisplayedOnceOnly"></p>
          </div>
          <dl class="ds-details" *ngIf="credentialsResponseSummary">
            <dt translate="VsimDetailsComponent.clientPeerPrivateKey"></dt>
            <dd>
              <div>{{ credentialsResponseSummary.arcClientPeerPrivateKey || '--' }}</div>
            </dd>
            <dt translate="VsimDetailsComponent.clientPeerPublicKey"></dt>
            <dd>
              <div>{{ credentialsResponseSummary.arcClientPeerPublicKey || '--' }}</div>
            </dd>
          </dl>
          <div class="ds-notice--warning" *ngIf="credentialsResponseSummary?.arcClientPeerPrivateKey">
            {{ 'VsimDetailsComponent.autogenerateCredentialsPrivateKeyWarning' | translate }}
          </div>
        </details>
        <!-- </div> -->
      </div>

      <!-- Third tab -->
      <div class="__content --3">
        <details class="ds-disclosure--simple">
          <summary translate="VsimDetailsComponent.reinitialize" class="x-reinitialize-sim-summary"></summary>

          <p class="ds-text" [innerHtml]="'VsimDetailsComponent.reinitializeHelpText' | translate"></p>

          <app-alerts
            *ngIf="alertsManagerForReinitializeVirtualSim.alerts?.length"
            [alertsManager]="alertsManagerForReinitializeVirtualSim"
          ></app-alerts>

          <button (click)="reinitializeVirtualSim()" class="ds-button --icon-rotate-ccw x-reinitialize-sim-button">
            <span translate="VsimDetailsComponent.reinitialize"></span>
          </button>
        </details>
        <details class="ds-disclosure--simple">
          <summary translate="VsimDetailsComponent.terminateVirtualSimDotDotDot"></summary>
          <p class="ds-text" translate="VsimDetailsComponent.terminateHelpText"></p>

          <app-alerts
            *ngIf="alertsManagerForDeleteVirtualSim.alerts?.length"
            [alertsManager]="alertsManagerForDeleteVirtualSim"
          ></app-alerts>

          <button (click)="terminateVirtualSimConfirm()" class="ds-button --icon-delete --alert">
            <span translate="VsimDetailsComponent.terminateVirtualSim"></span>
          </button>
        </details>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="debugMode">
    <h1>DEBUG</h1>
    <code>
      <pre>{{ debugInfo }}</pre>
    </code>
  </ng-container>
</div>

<ng-template #terminateVsimConfirmModalTemplate>
  <div>
    {{ 'VsimDetailsComponent.terminateVsimConfirmModal.message' | translate }}
  </div>
</ng-template>
