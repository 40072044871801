import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
} from '@angular/core';
import { Feature, Map, Overlay, View } from 'ol';
import { FullScreen } from 'ol/control';
import { click, pointerMove } from 'ol/events/condition';
import { Point } from 'ol/geom';
import Select, { SelectEvent } from 'ol/interaction/Select';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { MapMarkerData } from '../map.type';

const FEATURE_DATA_KEY = '__data__';

const uniqueId = (() => {
  let counter = 0;
  return () => counter++;
})();

/**
 * DEPRECATION NOTE: This component is deprecated and will be removed in a future release, in favor of `libs/shared-ng/ui-map/src/lib/ui-map-markers.component.ts`.
 *
 * I (mason 2022-12-05) ran out of time to consolidate these changes before the v2 SIM Management UI release. That newer component is based on this one, just with a few changes mainly around moving to a lib and enabling --strict mode. So it should not be too hard to remove this and use that instead.
 *
 * Story for removing this: https://app.shortcut.com/soracom/story/74966/replace-all-user-console-map-components-with-soracom-shared-ng-ui-map
 */
@Component({
  selector: 'app-marker-map',
  templateUrl: './marker-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }
      .map-container {
        position: relative;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})

// NOTE (Cc @akirakono): Mason 2022-11-30: there is a TS problem here that I think only doesn't cause an issue because current user-console lacks strict templates. (I mean, it is a TypeScript error but it is not being caught because the code in the template isn't checked.) The class generic type <T extends MapMarkerData> doesn't work well because of the error described below:
//
// interface HogeMarker extends MapMarkerData {
//   hoge: number;
// }
//
// dummyMarkers: HogeMarker[] = [];
//
// Then, if you do this in the class definition:
//
// _markers: T[] = dummyMarkers;
//
// ...you will get this error:
// Type 'HogeMarker[]' is not assignable to type 'T[]'.
//   Type 'HogeMarker' is not assignable to type 'T'.
//     'HogeMarker' is assignable to the constraint of type 'T', but 'T' could be instantiated with a different subtype of constraint 'MapMarkerData'.ts(2322)
//
// This is changed to a slightly different way of representing ("represented object", "location") pairs in the `UiMapMarkersComponent` class I created (derived from this class)
//
export class MarkerMapComponent<T extends MapMarkerData> implements OnChanges, AfterViewInit {
  _markers: T[] = [];
  @Input() set markers(v: T[] | undefined | null) {
    this._markers = v || [];
  }
  get markers(): T[] {
    return this._markers;
  }

  /* function to get tooltip content (innerHtml of div element). */
  @Input() tooltipHtml: ((T: any) => string) | undefined;

  @Output() onClick = new EventEmitter<T>();

  @Input() zoom?: number;

  mapContainerId: string;

  constructor(private zone: NgZone) {
    this.mapContainerId = `ol-markers-map-${uniqueId()}`;
  }

  ngAfterViewInit() {
    // runOutsideAngular avoids useless changeDetection on user's interaction with map
    this.zone.runOutsideAngular(() => {
      this.initMap();
      this.updateMap();
    });
  }

  ngOnChanges(): void {
    if (this._map) {
      this.zone.runOutsideAngular(() => {
        this.updateMap();
      });
    }
  }

  // @ts-expect-error (legacy code incremental fix)
  _map: Map;
  // @ts-expect-error (legacy code incremental fix)
  _markerSource: VectorSource;

  initMap() {
    //marker
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayer({ source: markerSource });

    const map = new Map({
      target: this.mapContainerId,
      layers: [new TileLayer({ source: new OSM() }), markerLayer],
      view: new View({
        zoom: 2,
        center: fromLonLat([0, 0]),
      }),
    });

    map.addControl(new FullScreen());

    // tooltip
    const tooltipElm = document.createElement('div');
    tooltipElm.style.minWidth = '300px';
    tooltipElm.style.visibility = 'visible';

    const tooltipOverlay = new Overlay({
      element: tooltipElm,
      autoPan: true, // automatically move map to show overlay panel
    });
    map.addOverlay(tooltipOverlay);

    const hoverInteraction = new Select({
      condition: pointerMove,
      layers: [markerLayer],
    });

    hoverInteraction.on('select', (e: SelectEvent) => {
      if (!this.tooltipHtml) {
        return;
      }
      if (e?.selected?.length) {
        const data = e.selected[0].get(FEATURE_DATA_KEY);
        tooltipElm.innerHTML = this.tooltipHtml(data);
        tooltipOverlay.setPosition(e?.mapBrowserEvent?.coordinate);
      } else {
        // @ts-expect-error (legacy code incremental fix)
        tooltipOverlay.setPosition(null);
      }
    });
    map.addInteraction(hoverInteraction);

    // click handler
    const clickInteraction = new Select({
      condition: click,
      layers: [markerLayer],
    });
    clickInteraction.on('select', (e: SelectEvent) => {
      if (e?.selected.length) {
        const data = e.selected[0].get(FEATURE_DATA_KEY);
        if (data) {
          // map scripts are run outside angular so we have to get the context back into the Angular world
          this.zone.run(() => {
            this.onClick.emit(data);
          });
        }
      }
    });
    map.addInteraction(clickInteraction);

    this._map = map;
    this._markerSource = markerSource;
  }

  updateMap() {
    this._markerSource.clear(true);

    if (this.markers?.length > 0) {
      const points = this.markers.map((m) => {
        const f = new Feature({
          geometry: new Point(fromLonLat([m.long, m.lat])),
          [FEATURE_DATA_KEY]: m,
        });
        f.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 8,
              fill: new Fill({ color: [104, 221, 228] }),
              stroke: new Stroke({
                color: [255, 255, 255],
                width: 2,
              }),
            }),
          })
        );
        return f;
      });
      this._markerSource.addFeatures(points);
      this._map.getView().fit(this._markerSource.getExtent(), { maxZoom: 18 });
      if (this.zoom) {
        this._map.getView().setZoom(this.zoom);
      }
    }
  }
}
