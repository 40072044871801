import { Component } from '@angular/core';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { Logger } from '@soracom/shared/logger';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';

import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';

import { SubscriberBatchTerminator } from '../../shared/SubscriberBatchTerminator';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiOnOffSwitch } from '@soracom/shared-ng/soracom-ui-legacy';

@Component({
  selector: 'app-terminate-subscribers',
  templateUrl: './terminate-subscribers.component.html',
})
export class TerminateSubscribersComponent extends DsModalContentBase<
  TerminateSubscribersModalData,
  TerminateSubscribersModalResult
> {
  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
  }

  /** Updater which will actually terminate sim */
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchTerminator;

  /** Terminate protection switch */
  terminationProtectionSwitch = UiOnOffSwitch.default;

  ngOnInit() {
    // Initialize updater
    this.updater = new SubscriberBatchTerminator(
      this.logger,
      this.apiService,
      this.subscribers,
      this.subscriberApiService,
      this.simApiService
    );

    // Set initial value of switch. If any of subscriber has protection on, switch will be on
    this.terminationProtectionSwitch.isOn = this.subscribers.some((subscriber) => !subscriber.terminationEnabled);
  }

  get subscribers() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers;
  }

  get shouldShowTerminationProtectionSwitch() {
    return this.subscribers.some((s) => s.terminationProtectionIsOn);
  }

  // @ts-expect-error (legacy code incremental fix)
  outputType: ExtendedSubscriberInterface[];

  get output() {
    return this.updater.subscribers;
  }

  get canConfirm() {
    return !this.terminationProtectionSwitch.isOn;
  }

  canClose() {
    return this.updater.state !== 'busy';
  }

  close() {
    this.modalRef.close();
  }

  confirm() {
    this.updater.terminationProtectionValue = this.terminationProtectionSwitch.isOn;

    this.updater.performUpdate().then((updater) => {
      if (updater.state === 'succeeded') {
        const updatedSubscribers = this.updater.subscribers;
        const modalResult: TerminateSubscribersModalResult = {
          success: true,
          updatedSubscribers: updatedSubscribers,
        };
        this.modalRef.close(modalResult);
      }
    });
  }
}

export interface TerminateSubscribersModalData {
  subscribers: ExtendedSubscriberInterface[];
}

export interface TerminateSubscribersModalResult {
  success?: boolean;
  updatedSubscribers?: ExtendedSubscriberInterface[];
}
