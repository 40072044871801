<section dsDialogContent>
  <p>
    <ui-span [content]="helpText"></ui-span>
  </p>

  <div class="x-current-subscriptions">
    <p>
      <ui-span [content]="currentSubscriptionsHelpText"></ui-span>
    </p>

    <ul>
      <li *ngFor="let planName of currentSubscriptions">
        <code>{{ planName }}</code>
      </li>
    </ul>
  </div>

  <div class="x-add-plans" style="margin-top: 1em; margin-bottom: 1em">
    <form>
      <label *ngFor="let planName of subscriptionsThatCanBeAdded" class="ds-radio--panel">
        <input
          class="x-add-plan x-add-plan-{{ planName }}"
          type="radio"
          id="sim-add-subscription-checkbox-{{ planName }}"
          name="radio"
          checked="checked"
        />
        <div>
          <p style="font-weight: bold">{{ planName }}</p>
          <div>
            A <code>{{ planName }}</code> subscription can be added to this SIM.
          </div>
        </div>
      </label>
    </form>
  </div>

  <div *ngIf="subscriptionsThatCanBeAdded.length < 1" class="x-no-plans-available-to-add">
    <p translate="SimAddVirtualSimComponent.noPlansAvailableToAdd"></p>
  </div>

  <app-alerts [alertsManager]="alertsManager"></app-alerts>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm" [class.--loading]="loading">
    <span [innerHTML]="'SimAddVirtualSimComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'CancelTransferSimOperatorComponent.cancel' | translate"></span>
  </button>
</footer>

