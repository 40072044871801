import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Clipboard from 'clipboard';
import * as qrCode from 'qrcode';

import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

export function getConnectionInfoTextToCopy(connectionInfo: LegacyAny) {
  if (!connectionInfo) {
    return '';
  }

  return (
    '[Interface]\n' +
    `PrivateKey = ${connectionInfo.arcClientPeerPrivateKey || '<YOUR_PRIVATE_KEY>'}\n` +
    `Address = ${connectionInfo.arcClientPeerIpAddress}/32` +
    '\n\n' +
    '[Peer]\n' +
    `PublicKey = ${connectionInfo.arcServerPeerPublicKey}\n` +
    `${connectionInfo.arcAllowedIPs ? `AllowedIPs = ${connectionInfo.arcAllowedIPs.join(', ')}\n` : ''}` +
    `Endpoint = ${connectionInfo.arcServerEndpoint}`
  );
}

export function hideVSIMConnectionSecretKeys(connectionInfo: LegacyAny) {
  if (!connectionInfo) {
    return '';
  }

  return getConnectionInfoTextToCopy({
    ...connectionInfo,
    ...(connectionInfo.arcClientPeerPrivateKey && { arcClientPeerPrivateKey: '*************' }),
  });
}

@Component({
  templateUrl: './virtual-sim-connection-info.component.html',
  styles: [
    `
      #wireguardQrCode {
        width: 150px !important;
        height: 150px !important;
      }
    `,
  ],
})
export class VirtualSimConnectionInfoComponent extends DsModalContentBase<any, null> implements OnInit {
  closeButton = new UiButton();
  copyAlertManager = new AlertsManager();
  clipboard: LegacyAny;
  copyButtonClass: LegacyAny;
  copyButtonSuccessTip: LegacyAny;
  data = this.getInput();

  constructor(private translate: TranslateService) {
    super();
    this.closeButton.titleId = 'security.credentials.modals.show.close';
    this.closeButton.onClick = () => {
      this.close();
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    };
  }

  ngOnInit(): void {
    this.clipboard = new Clipboard('.connection-info');
    this.clipboard.on('success', this.connectionInfoCopied);
    this.clipboard.on('error', this.connectionInfoCopyError);

    if (this.data?.connectionInfo?.arcClientPeerPrivateKey) {
      this.generateWireGuardQRCode();
    }
  }

  connectionInfoCopied = () => {
    this.copyButtonClass = {
      '--success': true,
    };
    this.copyButtonSuccessTip = this.translate.instant('virtualSimConnectionInfo.copied');
  };

  connectionInfoCopyError = () => {
    this.copyAlertManager.clear();
    this.copyAlertManager.add(Alert.danger('virtualSimConnectionInfo.copyFailed'));
  };

  onCopyButtonMouseOut() {
    this.copyButtonClass = {
      '--success': false,
    };
    this.copyButtonSuccessTip = '';
  }

  textToCopy(connectionInfo: LegacyAny) {
    return getConnectionInfoTextToCopy(connectionInfo);
  }

  hideSecretKeys(connectionInfo: LegacyAny) {
    return hideVSIMConnectionSecretKeys(connectionInfo);
  }

  generateWireGuardQRCode() {
    const text = this.textToCopy(this.data.connectionInfo);
    qrCode.toCanvas(document.getElementById('wireguardQrCode'), text);
  }
}
