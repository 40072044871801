import { SimStatusHistoryResponse } from '@soracom/shared/soracom-api-typescript-client';
import { utcToLocal } from '@soracom/shared/util-common';

/**
 * The UI requirements for the SIM update history table aren't well suited for using the raw API structure as-is. This wrapper class adds additional properties that the UI can use to prevent a more meaningful visualization of what actually changed.
 *
 * UPDATE: This is less true than it was when the feature was originally implemented. It was decided not to color-highlight changed fields. But, we still have to do other similar data-enhancement, such as fetch the group name from the ID if possible.
 *
 * NOTE: This class has helpers to let the filter work via text basis, but we decided not to ship that for now. Leaving the capability in place in case we want to add it later, though, because our CRE team has additional enhancement requests for this feature coming.
 */
export class SimStatusHistoryResponseWrapper {
  changes: string[] = [];

  dateString: string;
  timeString: string;

  groupName?: string;

  previousEvent?: SimStatusHistoryResponse;

  constructor(
    readonly event: SimStatusHistoryResponse,
    readonly previous?: SimStatusHistoryResponse[]
  ) {
    if (previous != null) {
      const previousEvent = previous.find((e) => e.imsi === event.imsi);
      if (previousEvent) {
        for (const [key, value] of Object.entries(event)) {
          const previousValue =
            previousEvent[key as keyof SimStatusHistoryResponse];

          const areEqual =
            value === previousValue ||
            (value?.toString &&
              previousValue?.toString &&
              value.toString() === previousValue?.toString());

          if (!areEqual) {
            this.changes.push(key);

            // special case:
            if (key === 'groupId') {
              this.changes.push('group');
            }
          }
        }
      }
    }

    if (!event.applyDateTimeEpochSec) {
      this.dateString = 'N/A';
      this.timeString = 'N/A';
    } else {
      const newDate = new Date(event.applyDateTimeEpochSec * 1000);
      const result = utcToLocal(
        newDate.toISOString().substring(0, 16).replace('T', ' ')
      );
      this.dateString =
        result.error != null ? `${event.applyDateTimeEpochSec}` : result.date;
      this.timeString =
        result.error != null ? `${event.applyDateTimeEpochSec}` : result.time;
    }
  }

  private _concatenatedFilterValues: string | null = null;

  /**
   * We want to filter on the values that the UI shows only.
   */
  matchesFilter(val: string) {
    if (this._concatenatedFilterValues == null) {
      this._concatenatedFilterValues =
        this.dateString +
        this.timeString +
        this.imsi +
        this.group +
        this.groupName +
        this.groupId +
        this.simId +
        this.speedClass +
        this.status +
        this.subscription +
        this.bundles.join('').toLocaleLowerCase();
    }
    return this._concatenatedFilterValues.includes(val.toLowerCase());
  }

  get group() {
    return this.groupName ?? this.groupId ?? '';
  }

  get simId(): string {
    return this.event.simId ?? 'ERR_MISSING_SIMID';
  }

  get bundles(): string[] {
    return this.event.bundles ?? ['—'];
  }

  get groupId() {
    if (this.isPrimaryOrNull) {
      return this.event.groupId;
    } else {
      return '—';
    }
  }

  get isPrimaryOrNull() {
    return this.isPrimary || this.isPrimary == null;
  }
  get imsi() {
    return this.event.imsi;
  }

  get isPrimary() {
    return this.event.isPrimary;
  }

  get primaryImsi() {
    return this.event.primaryImsi;
  }

  get speedClass() {
    if (this.isPrimaryOrNull) {
      return this.event.speedClass;
    } else {
      return '—';
    }
  }

  get status() {
    return this.event.status;
  }

  get subscription() {
    return this.event.subscription;
  }

  toString() {
    return JSON.stringify(this, null, 2);
  }
}
