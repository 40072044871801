<div class="table-wrapper">
  <table class="ds-datatable--embedded ds-datatable--compact subscriber-batch-change-table x-batch-change">
    <thead>
      <tr>
        <th class="th-name" translate="SubscriberBatchChangeComponent.columns.name"></th>
        <th *ngIf="showGroup" class="th-group" translate="SubscriberBatchChangeComponent.columns.group"></th>
        <th *ngIf="showBundle" translate="SubscriberBatchChangeComponent.columns.bundle"></th>
        <th
          *ngIf="subscribers && subscribers.length > 0 && subscribers[0].isSim === false"
          class="th-imsi"
          translate="SubscriberBatchChangeComponent.columns.imsi"
        ></th>
        <th
          *ngIf="subscribers && subscribers.length > 0 && subscribers[0].isSim === true"
          class="th-imsi"
          translate="SubscriberBatchChangeComponent.columns.sim_id"
        ></th>
        <th class="th-update-summary">
          <ui-span [content]="changeColumnHeading"></ui-span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let subscriber of subscribers; let index = index" class="x-{{ index }}">
        <td *ngIf="subscriber.name" [innerHTML]="subscriber.name" class="x-name"></td>
        <td *ngIf="!subscriber.name" translate="SubscriberBatchChangeComponent.noName" style="color: darkgray"></td>
        <td *ngIf="showGroup">
          <ui-span [content]="groupName(subscriber.groupId)"></ui-span>
        </td>
        <td *ngIf="showBundle">{{$any(subscriber.bundles)?.[0] ?? ''}}</td>

        <td *ngIf="!subscriber.isSim" [innerHTML]="makeImsiWithWbr(subscriber.imsi)" class="imsi"></td>
        <td *ngIf="subscriber.isSim" class="simid"><uc-simid [simId]="subscriber.simId"></uc-simid></td>
        <td class="x-change-description">
          <div class="x-change-status" *ngIf="!statusAt(index)">
            <ui-span [content]="beforeDescription(subscriber)"></ui-span>
            <i
              class="ds-icon --icon-arrow-right --xsmall"
              *ngIf="afterDescription(subscriber)"
              style="margin-left: 0.5em; margin-right: 0.5em"
            ></i>
            <ui-span *ngIf="afterDescription(subscriber)" [content]="$any(afterDescription(subscriber))"></ui-span>
          </div>

          <div class="x-change-waiting ds-text" *ngIf="waitingAt(index)">
            <i class="ds-icon --icon-rotate-cw --spin"></i>
            <span>{{ i18nPrefix + '.waiting' | translate }}</span>
          </div>

          <div class="x-change-updating ds-text" *ngIf="updatingAt(index)">
            <i class="ds-icon --icon-rotate-cw --spin"></i>
            <span>{{ i18nPrefix + '.updating' | translate }}</span>
          </div>

          <div class="x-change-succeeded ds-text" *ngIf="succeededAt(index)">
            <ng-container *ngIf="succeededTemplateRef">
              <ng-container
                [ngTemplateOutlet]="succeededTemplateRef"
                [ngTemplateOutletContext]="{ $implicit: subscriber, updater: updater }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="!succeededTemplateRef">
              <i class="ds-icon --icon-online --success"></i>
              <ui-span *ngIf="successDescription(subscriber)" [content]="$any(successDescription(subscriber))"></ui-span>
            </ng-container>
          </div>

          <div class="x-change-failed ds-text" *ngIf="failedAt(index)">
            <ng-container *ngIf="failedTemplateRef">
              <ng-container
                [ngTemplateOutlet]="failedTemplateRef"
                [ngTemplateOutletContext]="{ $implicit: subscriber, updater: updater }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="!failedTemplateRef">
              <div class="subscribers-change-table-failure-column-group">
                <i class="ds-icon--icon-circle-x ds-icon--alert"></i>
                <span>{{ i18nPrefix + '.updateFailed' | translate }}</span>
                <ui-rich-tip [content]="errorInfoPopoverAt(index)" style="margin-left: 0.5em"></ui-rich-tip>
              </div>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-container *ngIf="showAlertManager">
  <app-alerts [alertsManager]="updater.batchAlertManager"></app-alerts>
</ng-container>
