import { Component, OnInit } from '@angular/core';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { Logger } from '@soracom/shared/logger';
import { getDefaultUtcOffsetString } from '@soracom/shared/util-common';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { ExpiryActions, SubscriberBatchExpiryUpdater } from '../../shared/SubscriberBatchExpiryUpdater';
import { Translation } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiSelect, UiSelectDelegate } from '../../soracom-ui/ui-select/UiSelect';

@Component({
  selector: 'app-change-subscribers-expiry',
  templateUrl: './change-subscribers-expiry.component.html',
})
export class ChangeSubscribersExpiryComponent
  extends DsModalContentBase<ChangeSubscribersExpiryModalData, ChangeSubscribersExpiryCompletionData>
  implements OnInit, UiSelectDelegate<ExpiryActions>
{
  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
  }

  /** Expiry action select box */
  // @ts-expect-error (legacy code incremental fix)
  expiryActionSelect: UiSelect<ExpiryActions>;

  /** Default Selected action */
  private get defaultAction(): ExpiryActions {
    let result: ExpiryActions = 'UNSET';
    if (!this.subscribers) {
      return result;
    }
    const first = this.subscribers[0];

    if (this.subscribers.every((s) => s.expiredAt && s.expiryAction === first.expiryAction)) {
      // Mason 2019-11-27: I noticed that the backend doesn't clear the expiryAction field when the expiry is cleared; it seems to just clear the expiredAt field. So we should not interpret expiryAction as being meaningful without expiredAt also being present.
      // 2020-10-21: Oops! The backend does in fact interpret that field having meaning, which is the cause of https://app.clubhouse.io/soracom/story/12765/unsetting-sim-expiry-action-should-unset-both-date-and-action-properties
      //
      // We can work around this in user-console, though, by setting the expiry action to none first when we unset expiredAt.

      result = first.expiryAction as ExpiryActions;
      // FIXME: should validate that the API data conforms to what we think an ExpiryAction is
    }

    result = result || 'UNSET';
    return result;
  }

  expiryDateTime: number | null = null;

  utcOffset = getDefaultUtcOffsetString();

  onExpiryDateChange() {
    // @ts-expect-error (legacy code incremental fix)
    this.updater.expiryDate = this.expiryDateTime !== null ? new Date(this.expiryDateTime) : null;
  }

  /** Description below expiry action select box */
  // @ts-expect-error (legacy code incremental fix)
  expiryActionDescription: Translation;

  /** Batch updater which does api call */
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchExpiryUpdater;

  ngOnInit() {
    this.logger.debug('ngOnInit()', this);
    this.updater = new SubscriberBatchExpiryUpdater(
      this.logger,
      this.apiService,
      this.subscribers,
      this.subscriberApiService,
      this.simApiService
    );
    this.configureExpiryDatePicker();
    this.configureExpiryActionSelect();
    this.getExpiryActionDescription();
  }

  /**
   * Configure expiry date picker
   */
  configureExpiryDatePicker() {
    if (!this.subscribers) {
      throw new Error('configureExpiryDatePicker() was called before subscribers exist');
    }
    // debugger;
    const first = this.subscribers[0];

    if (this.subscribers.every((s) => s.expiredAt && s.expiredAt === first.expiredAt)) {
      this.expiryDateTime = first.expiredAt;
    } else {
      // round into hour + 10 days
      this.expiryDateTime = (Math.trunc(Date.now() / 3600_000) + 10 * 24) * 3600_000;
    }
    this.updater.expiryDate = new Date(this.expiryDateTime);
  }

  /**
   * Configure expiry action select box
   */
  configureExpiryActionSelect() {
    const orderedActions: ExpiryActions[] = ['doNothing', 'deleteSession', 'deactivate', 'suspend', 'terminate'];

    const s = new UiSelect(orderedActions, this);
    s.resetOptions();
    // because we cannot insert currently, but only append, and we need to add special options
    s.addValue('UNSET', { id: 'ChangeSubscribersExpiryComponent.expiryActions.UNSET' });
    s.addSeparator();
    s.addValues(orderedActions);
    s.classes = ['x-expiry-action']; // to make test writing easier

    s.optionFormatter = (opt) => {
      return { id: `ChangeSubscribersExpiryComponent.expiryActions.${opt}` };
    };

    this.expiryActionSelect = s;

    // keeping defaultAction to doNothing even in fact each subscriber can have other status
    this.expiryActionSelect.value = this.defaultAction;

    this.expiryActionSelect.labelId = 'ChangeSubscribersExpiryComponent.expiry_action';
  }

  // @ts-expect-error (legacy code incremental fix)
  outputType: ExtendedSubscriberInterface[];

  get output() {
    return this.updater.subscribers;
  }

  get subscribers() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers;
  }

  /**
   * Gets expiry action description based on action
   */
  getExpiryActionDescription() {
    const id = `ChangeSubscribersExpiryComponent.descriptions.${this.expiryActionSelect.value}`;
    this.expiryActionDescription = new Translation(id);
  }

  /**
   * On Expiry action change, update description below it
   */
  uiSelectDidChange(select: UiSelect<ExpiryActions>) {
    this.getExpiryActionDescription();

    const value = select.value;
    this.updater.expiryAction = value;
    this.updater.afterAction = value;
  }

  get canConfirm() {
    // if action is set, expiry date must be set
    // @ts-expect-error (legacy code incremental fix)
    return this.updater.expiryAction === 'UNSET' || this.expiryDateTime > 0;
  }

  override canClose() {
    return this.updater.state !== 'busy';
  }

  confirm() {
    if (!this.canConfirm) {
      return;
    }

    this.updater.performUpdate().then((updater) => {
      if (updater.state === 'succeeded') {
        this.close({
          success: true,
          updatedSubscribers: this.output,
        });
      } else {
        // Do nothing, as the batch updater now handles error message display.
      }
    });
  }
}

export interface ChangeSubscribersExpiryCompletionData {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
}

export interface ChangeSubscribersExpiryModalData {
  subscribers: ExtendedSubscriberInterface[];
}
