import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CouponsRoutingModule } from '../coupons/coupons-routing.module';
import { SoracomCommonModule } from '../soracom-common/soracom-common.module';
import { SoracomUiModule } from '../soracom-ui/soracom-ui.module';
import { LegacyPrefixedEventHandlersPageDirective } from './legacy-prefixed-event-handlers-page.directive';
import { PrefixedEventHandlersPageComponent } from './prefixed-event-handlers-page.component';
import {PrefixedEventHandlersByImsiDirective} from "./prefixed-event-handler-by-imsi.directive";

@NgModule({
  declarations: [PrefixedEventHandlersPageComponent, LegacyPrefixedEventHandlersPageDirective, PrefixedEventHandlersByImsiDirective],
  exports: [PrefixedEventHandlersPageComponent, LegacyPrefixedEventHandlersPageDirective, PrefixedEventHandlersByImsiDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SoracomCommonModule,
    SoracomUiModule,
    TranslateModule.forChild(),
  ],
})
export class PrefixedEventHandlersModule {}
