import { yearsToMs } from '@soracom/shared/util-common';
import { UiFixedRateSimDataUsageDetails } from '../fixed-rate-sim.service';
import { TopUpAmounts } from '../fixed-rate-sim.service';

export function fixedRateSimApproachingTopUpLimit(
  fixedRateSimDetails: UiFixedRateSimDataUsageDetails,
  topUpAmounts: TopUpAmounts
): boolean {
  if (
    isApproachingTopUpLimit(
      fixedRateSimDetails.dataUsage,
      fixedRateSimDetails.maximumAllowedDataUsage,
      topUpAmounts.data
    )
  ) {
    return true;
  }
  if (
    isApproachingTopUpLimit(
      fixedRateSimDetails.smsUsage,
      fixedRateSimDetails.maximumAllowedSmsUsage,
      topUpAmounts.sms
    )
  ) {
    return true;
  }
  if (
    isApproachingTopUpLimit(
      fixedRateSimDetails.ussdUsage,
      fixedRateSimDetails.maximumAllowedUssdUsage,
      topUpAmounts.ussd
    )
  ) {
    return true;
  }
  if (
    isApproachingTopUpLimit(
      new Date().getTime(),
      new Date(fixedRateSimDetails.expiryDate).getTime(),
      yearsToMs(topUpAmounts.expiry)
    )
  ) {
    return true;
  }
  return false;
}

export function fixedRateSimHasReachedTopUpLimit(
  fixedRateSimDetails: UiFixedRateSimDataUsageDetails
) {
  if (
    hasReachedTopUpLimit(
      fixedRateSimDetails.dataUsage,
      fixedRateSimDetails.maximumAllowedDataUsage
    )
  ) {
    return true;
  }
  if (
    hasReachedTopUpLimit(
      fixedRateSimDetails.smsUsage,
      fixedRateSimDetails.maximumAllowedSmsUsage
    )
  ) {
    return true;
  }
  if (
    hasReachedTopUpLimit(
      fixedRateSimDetails.ussdUsage,
      fixedRateSimDetails.maximumAllowedUssdUsage
    )
  ) {
    return true;
  }
  if (
    hasReachedTopUpLimit(
      new Date().getTime(),
      new Date(fixedRateSimDetails.expiryDate).getTime()
    )
  ) {
    return true;
  }
  return false;
}

export function isApproachingTopUpLimit(
  current: number,
  total: number,
  topUpAmount: number
) {
  if (current >= total) {
    return false;
  }
  return (total - current) / topUpAmount < 0.1;
}

export function hasReachedTopUpLimit(current: number, total: number) {
  return current >= total;
}
