import { LegacyAny } from '@soracom/shared/core';

import { Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { AbstractController } from '@soracom/shared-ng/soracom-ui-legacy';
import { SoracomUserConsole } from '../../shared/SoracomUserConsole';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import {
  getConnectionInfoTextToCopy,
  VirtualSimConnectionInfoComponent,
} from '../virtual-sim-connection-info/virtual-sim-connection-info.component';
import { SimDetailsService } from '../../sim-details/sim-details.service';

@Component({
  selector: 'app-vsim-details',
  templateUrl: './vsim-details.component.html',
  styleUrls: ['./vsim-details.component.scss'],
})
export class VsimDetailsComponent extends AbstractController {
  @Input() isActive = false;
  // @ts-expect-error (legacy code incremental fix)
  @Input() subscriber: ExtendedSubscriberInterface;
  // @ts-expect-error (legacy code incremental fix)
  @Input() apiMode: 'subscriber' | 'sim';
  // @ts-expect-error (legacy code incremental fix)
  @Input() enableSimApiMode: ($event: LegacyAny) => void;
  @ViewChild('terminateVsimConfirmModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  terminateVsimConfirmModalTemplate: TemplateRef<any>;

  // @ts-expect-error (legacy code incremental fix)
  oldSubscriber: ExtendedSubscriberInterface;

  state: 'loading' | 'ready' | 'resettingSession' | 'terminatingVirtualSim' = 'loading';

  alertsManager = new AlertsManager();
  alertsManagerForResetSession = new AlertsManager();
  alertsManagerForDeleteVirtualSim = new AlertsManager();
  alertsManagerForReinitializeVirtualSim = new AlertsManager();
  alertsManagerForAttachCredentials = new AlertsManager();

  credentialsResponseSummary: LegacyAny;

  constructor(
    logger: Logger,
    private apiService: SoracomApiService,
    private uiDsModalService: UiDsModalService,
    private simDetailsService: SimDetailsService
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.debugMode) {
      debugger;
    }

    this.state = 'ready';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.oldSubscriber = changes.subscriber?.previousValue;
  }

  get debugMode() {
    return SoracomUserConsole.debugMode;
  }

  get activeVSIM() {
    return this.subscriber.activeVSIM;
  }

  get isTerminated() {
    const secondarySubscribers = (this.subscriber as Sim)?.secondarySubscribers;
    const vsim = secondarySubscribers?.find((subscriber) => subscriber.subscription === 'planArc01');
    if (vsim?.status === 'terminated') {
      return true;
    } else if (!vsim && this.subscriber.subscription === 'planArc01' && this.subscriber.status === 'terminated') {
      return true;
    }
    return false;
  }

  get arcSessionStatus() {
    return this.subscriber?.arcSessionStatus;
  }

  get oldArcSessionStatus() {
    return this.oldSubscriber?.arcSessionStatus;
  }

  get arcSessionStatusSummary() {
    const arcSessionStatus = this.subscriber?.arcSessionStatus;
    return arcSessionStatus ? JSON.stringify(arcSessionStatus, null, 2) : '<none>';
  }

  // @ts-expect-error (legacy code incremental fix)
  arcClientPeerPublicKey: string;

  async attachCredentials(arcClientPeerPublicKey?: string) {
    let response: any;
    try {
      this.alertsManagerForAttachCredentials.clear();
      // the API will attach new creds and resets old session
      response = await this.apiService.attachArcCredentialsToSim(this.subscriber.simId, arcClientPeerPublicKey);
      this.alertsManagerForAttachCredentials.add(Alert.success('VsimDetailsComponent.attachCredentialSuccess'));
      this.credentialsResponseSummary = response.data;
      await this.simDetailsService.updateSubscriber();
    } catch (error) {
      const errAlert = Alert.danger(error);
      this.alertsManagerForAttachCredentials.add(errAlert);
    } finally {
      this.state = 'ready';
    }
  }

  async deleteSession() {
    this.state = 'resettingSession';

    let response: any;
    const imsi = this.activeVSIM?.imsi;

    try {
      if (!imsi) {
        throw '🤔 Hmm cant find VSIM - console bug?';
      }
      response = await this.apiService.deleteSubscriberSession(imsi);
      this.alertsManagerForResetSession.add(Alert.success('VsimDetailsComponent.resetSessionSuccess'));
      this.deleteSessionResponseSummary = JSON.stringify(response.data ?? 'ERROR BRO 🙁', null, 2);
      await this.simDetailsService.updateSubscriber();
    } catch (error) {
      const errAlert = Alert.danger(error);
      this.alertsManagerForResetSession.add(errAlert);
    }
  }

  deleteSessionResponseSummary = '';

  terminateVirtualSimConfirm() {
    this.uiDsModalService.openConfirmModal(this.terminateVsimConfirmModalTemplate, {
      title: 'VsimDetailsComponent.terminateVsimConfirmModal.title',
      okButton: (button) => {
        button.titleId = 'VsimDetailsComponent.terminateVsimConfirmModal.submit';
        button.buttonStyle = 'danger';
        button.classes = ['terminate-confirm-button'];
      },
      classes: ['virtual-sim-details-ds-modal'],
      onOkClick: this.terminateVirtualSim,
      showLoadingProgressOnOkClick: true,
    });
  }

  terminateVirtualSim = async () => {
    this.state = 'terminatingVirtualSim';

    let response: any;
    const simId = this.subscriber.simId;
    const iccid = this.subscriber.iccid;
    const imsi = this.activeVSIM?.imsi;
    try {
      this.alertsManagerForDeleteVirtualSim.clear();
      if (!imsi) {
        throw '🤔 Hmm cant find VSIM - console bug?';
      }
      response = await this.apiService.terminateVirtualSim(simId, iccid, imsi);
      this.alertsManagerForDeleteVirtualSim.add(Alert.success('VsimDetailsComponent.terminateVsimSuccess'));
      // TODO: should be reload
    } catch (error) {
      const errAlert = Alert.danger(error);
      this.alertsManagerForDeleteVirtualSim.add(errAlert);
    }
  };

  get debugInfo() {
    return JSON.stringify(this.subscriber, null, 2);
  }

  get textToCopy() {
    return getConnectionInfoTextToCopy(this.arcSessionStatus);
  }

  async reinitializeVirtualSim() {
    try {
      const response = await this.apiService.createSimVirtualSession(this.subscriber.simId);
      this.uiDsModalService.open(VirtualSimConnectionInfoComponent, {
        data: {
          connectionInfo: response.data,
          successMessage: 'VsimDetailsComponent.reinitializeSuccess',
        },
        title: 'VsimDetailsComponent.reinitializeSuccessModalTitle',
        classes: ['virtual-sim-details-ds-modal'],
      });
      await this.simDetailsService.updateSubscriber();
    } catch (e) {
      this.alertsManagerForReinitializeVirtualSim.add(Alert.fromApiError(e));
    }
  }
}

const example = [
  {
    operatorId: 'OP0059150966',
    simId: '8900001094781003145',
    profiles: {
      '8900001094781003145': {
        iccid: '8900001094781003145',
        globalSimPlmnTable: null,
        slotProvisioningStatus: { cancelled: false, completedExceptionally: false, numberOfDependents: 0, done: false },
        primaryImsi: '999999478100314',
        otaSupported: false,
        subscribers: {
          '999999478100314': { status: 'active', imsi: '999999478100314', subscription: 'planArc01', msisdn: null },
        },
      },
    },
    lastModifiedTime: 1623816528748,
    sessionStatus: {
      gtpcTeid: 1732457464,
      lastUpdatedAt: 1623816528748,
      imsi: '999999478100314',
      imei: null,
      location: null,
      ueIpAddress: '10.185.197.60',
      dnsServers: ['0.0.0.0', '0.0.0.0'],
      online: true,
      subscription: 'planArc01',
    },
    previousSession: {
      gtpcTeid: 3758185205,
      imsi: '999999478100314',
      imei: null,
      ueIpAddress: '10.185.197.60',
      dnsServers: ['0.0.0.0', '0.0.0.0'],
      subscription: 'planArc01',
      createdTime: 1623756628255,
      deletedTime: 1623816528945,
    },
    pcapStartTime: null,
    pcapEndTime: null,
    status: 'active',
    groupId: '0f114436-acfd-4aad-b846-bf62aa83610f',
    tags: { name: 'kaz vSIM4' },
    speedClass: 'arc.standard',
    createdTime: 1623752935773,
    expiryTime: null,
    expiryAction: 'doNothing',
    terminationEnabled: true,
    imeiLock: null,
    lastPortMappingCreatedTime: null,
    activeProfileId: '8900001094781003145',
    type: 'uicc',
  },
];
