// This element resides in the user-console library just because it references an image asset the user-console library.
// The following content is basd on https://opencellid.org/downloads.php with few modifications for appearance -->
class UiOpenCellIdLicenseElement extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<a rel="license" href="https://creativecommons.org/licenses/by-sa/4.0/">
  <img
    alt="Creative Commons License"
    style="border-width:0; vertical-align: middle;"
    src="assets/images/cc-by-sa-80x15.png"
    width="80"
    height="15"
  />
</a>
&nbsp;
<span xmlns:dct="http://purl.org/dc/terms/" property="dct:title"
  ><a
    xmlns:cc="https://creativecommons.org/ns#"
    href="https://opencellid.org"
    property="cc:attributionName"
    rel="cc:attributionURL"
    >OpenCelliD Project</a
  ></span
><a
  xmlns:cc="https://creativecommons.org/ns#"
  href="https://opencellid.org"
  property="cc:attributionName"
  rel="cc:attributionURL"
>
</a>
is licensed under a
<a
  rel="license"
  href="https://creativecommons.org/licenses/by-sa/4.0/"
  target="_blank"
>
  Creative Commons Attribution-ShareAlike 4.0 International License
</a>
`;
  }
}

customElements.define('ui-open-cellid-license', UiOpenCellIdLicenseElement);
