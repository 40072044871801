import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, getCoverageType, getOperatorData } from '@soracom/shared/data-access-auth';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalRef } from '@soracom/shared-ng/ui-ds-modal';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { RegisterSimModalComponent } from './register-sim-modal/register-sim-modal.component';

@Component({
  selector: 'app-register-sim',
  templateUrl: './register-sim.component.html',
})
export class RegisterSimComponent implements OnInit {
  @ViewChild('paymentNoticeModalTemplate', { static: true, read: TemplateRef })
  // @ts-expect-error (legacy code incremental fix)
  paymentNoticeModalTemplate: TemplateRef<any>;

  // @ts-expect-error (legacy code incremental fix)
  @Input() apiMode: 'subscriber' | 'sim';
  @Input() enableSimApiMode?: () => void = () => {}; //this is needed for old subscribers page, can be deleted later
  @Input() moveToRegisterPaymentMethod?: () => void = () => this.defaultMoveToRegisterPaymentMethod(); //in subscribers page, an angularjs callback is needed here so it will remain as an input.
  // @ts-expect-error (legacy code incremental fix)
  @Input() updateSubscribersTable: () => void;
  @Input() customClasses?: string[] = [];

  paymentNoticeButtonBar: UiButtonBar;
  // @ts-expect-error (legacy code incremental fix)
  paymentNoticeModalRef: UiDsModalRef;

  constructor(
    private uiDsModalService: UiDsModalService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.paymentNoticeButtonBar = UiButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'payment_methods.new.cancel';
          button.onClick = () => this.paymentNoticeModalRef.close();
        }),
        UiButton.configure((button) => {
          button.titleId = 'payment_methods.new.title';
          button.onClick = () => {
            this.paymentNoticeModalRef.close();
            // @ts-expect-error (legacy code incremental fix)
            this.moveToRegisterPaymentMethod();
          };
        }),
      ];
    });
  }

  ngOnInit(): void {}

  openRegisterSimModal() {
    if (this.shouldShowPaymentNoticeModal) {
      this.paymentNoticeModalRef = this.uiDsModalService.open(this.paymentNoticeModalTemplate, {
        title: 'payment_notice_modal.title',
      });
      return;
    }
    this.uiDsModalService.open(RegisterSimModalComponent, {
      title: 'subscriber_registration.header',
      subtitle: 'subscriber_registration.header_subtitle',
      headerIconClass: '--icon-sim',
      modalSize: 'wide',
      data: {
        apiMode: this.apiMode,
        enableSimApiMode: this.enableSimApiMode,
        updateSubscribersTable: this.updateSubscribersTable,
      },
      classes: ['--right', '--simple', '--color-ink'],
    });
  }

  get paymentNotice(): string {
    const status = getOperatorData().getPaymentStatus(getCoverageType());
    if (status === undefined || status === null) {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
    if (status === 'registered') {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
    return this.translate.instant('payment_notice.' + status);
  }

  get shouldShowPaymentNoticeModal(): boolean {
    return this.authService.isLoggedIn() && this.doesPaymentNoticeExist();
  }

  defaultMoveToRegisterPaymentMethod() {
    this.router.navigate(['payments']);
  }

  doesPaymentNoticeExist() {
    const status = getOperatorData().getPaymentStatus(getCoverageType());
    if (status === undefined || status === null) {
      return false;
    }
    return status !== 'registered';
  }
}
