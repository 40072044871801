import { Injectable, inject } from '@angular/core';
import { OperatorConfigurationNamespaceService } from '@soracom/shared-ng/operator-configuration';
import { SoracomSimpleApiService } from '@soracom/shared/soracom-api-typescript-client';

/**
 * This service is used to define the API calls needed to interact with the Fixed Rate SIMs.
 */
@Injectable({ providedIn: 'root' })
export class FixedRatePlanApiService {
  private soracomSimpleApiService = new SoracomSimpleApiService();
  private operatorConfigNamespaceService = inject(
    OperatorConfigurationNamespaceService
  );

  async getFixedRateSimDataUsage(imsi: string) {
    const url = `/subscribers/${imsi}/contracts/fixed_rate_plan`;
    return (
      await this.soracomSimpleApiService.get<FixedRateSimDataUsageApiResponse>(
        url
      )
    ).data;
  }

  async requestManualTopup(imsi: string) {
    const url = `/subscribers/${imsi}/contracts/fixed_rate_plan`;
    await this.soracomSimpleApiService.post(url);
  }

  async setSimLevelAutoTopUpTrue(imsi: string) {
    const url = `/subscribers/${imsi}/contracts/fixed_rate_plan/enable_automatic_top_up`;
    await this.soracomSimpleApiService.post(url);
  }

  async setSimLevelAutoTopUpFalse(imsi: string) {
    const url = `/subscribers/${imsi}/contracts/fixed_rate_plan/disable_automatic_top_up`;
    await this.soracomSimpleApiService.post(url);
  }

  async removeSimLevelAutoTopUp(imsi: string) {
    const url = `/subscribers/${imsi}/contracts/fixed_rate_plan/automatic_top_up`;
    await this.soracomSimpleApiService.delete(url);
  }

  async getOperatorFixedRatePlanConfiguration() {
    return await this.operatorConfigNamespaceService.getOperatorConfiguration(
      'FixedRatePlan',
      {
        accessHiddenNamespace: true,
      }
    );
  }

  async setOperatorFixedRatePlanConfiguration(automaticTopUpEnabled: boolean) {
    return await this.operatorConfigNamespaceService.setOperatorConfiguration(
      'FixedRatePlan',
      {
        global: {
          automaticTopUpEnabled,
        },
      },
      undefined,
      { accessHiddenNamespace: true },
    );
  }

  /**
   * Gets the amount of data/sms/ussd/contract years that will be added when a SIM is topped up.
   * @param imsi
   * @returns
   */
  async getFixedRateSimTopUpUnit(imsi: string) {
    const url = `/subscribers/${imsi}/contracts/fixed_rate_plan/top_up_unit`;
    return (
      await this.soracomSimpleApiService.get<FixedRateTopUpUnitApiResponse>(url)
    ).data;
  }
}

export interface FixedRateSimDataUsageApiResponse {
  automaticTopUpEnabled?: boolean;
  status: FixedRateSimLifecycleEventName;
  basicInformation: FixedRateSimBasicContractInformation;
  usage: FixedRateSimUsage;
  events: FixedRateSimLifecycleEvent[];
}

export interface FixedRateSimBasicContractInformation {
  contractYears: number;
  dataBytes: number;
  numberOfSms: number;
  numberOfUssd: number;
}

export interface FixedRateSimLifecycleEvent {
  eventName: FixedRateSimLifecycleEventName;
  eventTime: number;
  eventReason: FixedRateSimlifefcycleEventReason;
}

export interface FixedRateSimUsage {
  dataBytes: number;
  numberOfSms: number;
  numberOfUssd: number;
}

export interface FixedRateTopUpUnitApiResponse {
  contractYears: number;
  additionalDataBytes: number;
  additionalNumberOfSms: number;
  additionalNumberOfUssd: number;
}

export type FixedRateSimLifecycleEventName =
  | 'contracted'
  | 'toppedUp'
  | 'transferred'
  | 'suspended'
  | 'terminated'
  | 'usageNotificationMailSent'
  | 'unusedNotificationMailSent'
  | 'preSuspensionNotificationMailSent'
  | 'preTerminationNotificationMailSent';

export type FixedRateSimlifefcycleEventReason = 'automatic' | 'manual';
