<section dsDialogContent>
  <app-subscriber-batch-change
    [updater]="pingWorker"
    [showGroup]="false"
    [showAlertManager]="false"
    [i18nPrefix]="'PingSubscribersComponent'"
  >
    <ng-template let-subscriber #succeeded>
      <div class="ds-text">
        <i class="ds-icon--icon-online ds-icon--success" *ngIf="result(subscriber).data.success"></i>
        <i class="ds-icon--icon-warning ds-icon--warning" *ngIf="!result(subscriber).data.success"></i>
        {{ result(subscriber).data.stat }}
        <ng-container *ngIf="result(subscriber).data.rtt">
          <br /><span style="margin-left: 32px">{{ result(subscriber).data.rtt }}</span>
        </ng-container>
      </div>
    </ng-template>
    <ng-template let-subscriber #failed>
      <div class="ds-text">
        <i class="ds-icon--icon-circle-x ds-icon--alert"></i>
        {{ result(subscriber).data.message }} ({{ result(subscriber).data.code }})
      </div>
    </ng-template>
  </app-subscriber-batch-change>

  <div class="ds-rows" [formGroup]="form">
    <div class="ds-field">
      <label class="ds-label">{{ 'PingSubscribersComponent.fields.numberOfPingRequests.label' | translate }}</label>
      <div class="ds-input">
        <input formControlName="numberOfPingRequests" type="number" min="1" max="5" step="1" />
        <div class="ds-text --addon-light">
          {{ 'PingSubscribersComponent.fields.numberOfPingRequests.unit' | translate }}
        </div>
      </div>
    </div>
    <div class="ds-field">
      <label class="ds-label">{{ 'PingSubscribersComponent.fields.pingTimeoutSeconds.label' | translate }}</label>
      <div class="ds-input">
        <input formControlName="timeoutSeconds" type="number" min="1" max="3" step="1" />
        <div class="ds-text --addon-light">
          {{ 'PingSubscribersComponent.fields.pingTimeoutSeconds.unit' | translate }}
        </div>
      </div>
    </div>
  </div>
</section>

<footer dsDialogFooter>
    <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
      <span [innerHTML]="'PingSubscribersComponent.confirm' | translate"></span>
    </button>
    <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
      <span [innerHTML]="'PingSubscribersComponent.cancel' | translate"></span>
    </button>
</footer>
