import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkerMapComponent } from './marker-map/marker-map.component';

@NgModule({
  declarations: [MarkerMapComponent],
  imports: [CommonModule],
  exports: [MarkerMapComponent],
})
export class MapModule {}
