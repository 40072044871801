import { Component } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { i18nId, TextContent } from '@soracom/shared-ng/util-common';
import { CoverageTypeService } from '@soracom/shared/data-access-auth';
import { SubscriberStatus, SubscriptionPlan } from '@soracom/shared/subscription-plan';
import deepEqual from 'fast-deep-equal';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchStatusUpdater } from '../../shared/SubscriberBatchStatusUpdater';
import { SubscriptionPlanUserMessage } from '../../shared/SubscriptionPlanUserMessage';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

interface StatusChangeGroup {
  subscribers: ExtendedSubscriberInterface[];
  descriptions: TextContent[];
  updater: SubscriberBatchStatusUpdater;
  warnings: LegacyTextContent[];
}

@Component({
  selector: 'app-change-subscribers-status',
  templateUrl: './change-subscribers-status.component.html',
})
export class ChangeSubscribersStatusComponent extends DsModalContentBase<
  ChangeSubscribersStatusModalData,
  ChangeSubscribersStatusCompletionData
> {
  /**
   * Component state is the state of the component itself — it is not totally simple, because this state is separate from the updater state. The reason we had to add this was to enable additional behavior on load for plan-D (only), to work around a bug in the /v1/sims API (the `hasSms` property is missing with the new API).
   */
  componentState: 'loading' | 'ready' | 'error' = 'loading';

  /**
   * Error message, if any (only applies when `componentState` is `error` — otherwise, it is up to the batch updater).
   */
  componentErrorMessage: LegacyTextContent = LegacyTextContent.translation('common.error_messages.unknown_error');

  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    public coverageTypeService: CoverageTypeService,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super();
  }

  // @ts-expect-error (legacy code incremental fix)
  statusChangeGroups: StatusChangeGroup[];

  ngOnInit() {
    // @ts-expect-error (legacy code incremental fix)
    this.statusChangeGroups = this.sortInputSubscribersIntoGroups(this.modalData.subscribers);
    this.logger.debug('ngOnInit()', this);

    this.componentState = 'ready';
  }

  /**
   * Groups the subscribers by the user-facing warning that will apply to the status change (so that SIMs with the same warnings can be grouped together).
   */
  sortInputSubscribersIntoGroups(subscribers: ExtendedSubscriberInterface[]) {
    // @ts-expect-error (legacy code incremental fix)
    const newStatus = this.modalData.newStatus;
    const simsGroupedByWarning = SubscriptionPlanUserMessage.groupSubscribersByStatusChangeWarnings(
      subscribers,
      newStatus
    );

    const changeGroupsByPlan: StatusChangeGroup[] = [];

    simsGroupedByWarning.forEach((subscribersInGroup, warnings) => {
      const subscriptionDescriptionsInGroup = subscribersInGroup.map((sim) => {
        const plan = sim.subscriptionPlan;
        if (!plan) {
          return i18nId('SubscriptionPlan.unknownPlanDescription', { planName: sim.subscription || '--' });
        }
        const options = SubscriptionPlan.getOptions(sim);
        return plan.description(options);
      });

      const descriptions: TextContent[] = [];
      subscriptionDescriptionsInGroup.forEach((desc) => {
        if (!descriptions.find((e) => deepEqual(e, desc))) {
          descriptions.push(desc);
        }
      });

      const updater = new SubscriberBatchStatusUpdater(
        this.logger,
        this.apiService,
        subscribersInGroup,
        this.subscriberApiService,
        this.simApiService
      );
      updater.newStatus = newStatus;

      const changeGroup: StatusChangeGroup = { descriptions, updater, warnings, subscribers: subscribersInGroup };
      changeGroupsByPlan.push(changeGroup);
    });

    return changeGroupsByPlan;
  }
  get updatedSubscribers() {
    const result: ExtendedSubscriberInterface[] = [];
    this.updaters.forEach(async (updater) => {
      result.push(...updater.subscribers);
    });
    return result;
  }

  get updaters() {
    return this.statusChangeGroups.map((changeGroup) => changeGroup.updater);
  }

  async confirm() {
    const updaters = this.updaters;

    for (const updater of this.updaters) {
      await updater.performUpdate();
    }

    // Um... a for loop works, but do NOT use forEach like this:
    //
    // updaters.forEach(async (updater) => {
    //   debugger;
    //   await updater.performUpdate();
    //   debugger;
    // });
    //
    // This might be a "Ha ha, Mason is a JS async/await n00b" thing, but if we try to do it this way, we will hit the second debugger long after all the code below has executed (while the updater is still busy). I found this out the hard way (although luckily our test suite caught my bug, so it didn't get to production). Somehow I expected it to pause the function passed to forEach(), and the outer async confirm() as well. But it didn't. The await suspended execution of the function passed to forEach(), but then immediately executed the rest of confirm(). A while after that, it resumed execution of the function passed to forEach(), but by that time confirm() had long since finished (and failed to work properly).

    if (updaters.every((updater) => updater.state === 'succeeded')) {
      const modalResult: ChangeSubscribersStatusCompletionData = {
        success: true,
        updatedSubscribers: this.updatedSubscribers,
        // @ts-expect-error (legacy code incremental fix)
        newStatus: this.modalData.newStatus,
      };
      this.close(modalResult);
    } else {
      // The batch updater should have displayed any errors.
    }
  }

  override canClose() {
    return this.updaters.every((updater) => updater.state !== 'busy');
  }

  get newStatus() {
    return (this.modalData && this.modalData.newStatus) || 'ERROR (18dsT)';
  }

  // @ts-expect-error (legacy code incremental fix)
  outputType: ExtendedSubscriberInterface[];

  get output(): ExtendedSubscriberInterface[] {
    return this.updatedSubscribers;
  }

  get coverageType() {
    return this.coverageTypeService.getCoverageType();
  }

  get canConfirm() {
    return this.componentState === 'ready';
  }

  get inputSubscribers() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers;
  }

  get confirmButtonTitleId() {
    const newStatus = this.modalData && this.modalData.newStatus;
    const isPlural = this.modalData && this.modalData.subscribers.length > 1;
    const singleOrMultiple = isPlural ? 'multiple' : 'single';
    const prefix = `ChangeSubscribersStatusComponent.confirmButtonTitle.${singleOrMultiple}.`;

    return prefix + newStatus;
  }

  get showRebootSimWarning() {
    return this.newStatus === 'active' && this.inputSubscribers.some((sim) => sim.status === 'suspended');
  }
}
export interface ChangeSubscribersStatusModalData {
  subscribers: ExtendedSubscriberInterface[];
  newStatus: SubscriberStatus;
}
export interface ChangeSubscribersStatusCompletionData {
  updatedSubscribers: ExtendedSubscriberInterface[];
  success: boolean;
  newStatus: SubscriberStatus;
}
