<section dsDialogContent>
  <div class="ds-text" [translate]="explanatoryText"></div>
  <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>

  <app-group-chooser
    [initialGroupId]="initialGroupId"
    (groupChooserChange)="onGroupChooserChange($event)"
  ></app-group-chooser>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'ChangeSubscribersGroupComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
