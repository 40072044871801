import { Directive } from '@angular/core';
import { CoverageType } from '@foundation/coverage-type';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { UpdateSpeedClassRequestSpeedClassEnum } from '@soracom/shared/soracom-api-typescript-client';
import { SoracomApiService } from '../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SpeedClass } from './SpeedClass';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';

@Directive()
export class SubscriberBatchSpeedClassUpdater extends SubscriberBatchUpdater {
  /** Overridden to add required `coverageType` parameter.  */
  constructor(
    logger: Logger = LoggerService.shared(),
    apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    public coverageType: CoverageType,
    public necContractCode: string | undefined,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
    this.deriveOtherDataFromSubscribers();
  }

  // @ts-expect-error (legacy code incremental fix)
  private _newSpeedClass: SpeedClass;

  get newSpeedClass(): SpeedClass {
    return this._newSpeedClass;
  }

  set newSpeedClass(newValue: SpeedClass) {
    // this.state = "waiting";
    this._newSpeedClass = newValue;
  }

  // @ts-expect-error (legacy code incremental fix)
  private _availableSpeedClasses: SpeedClass[];

  get availableSpeedClasses() {
    return this._availableSpeedClasses;
  }

  /** After subscribers is changed, this sets the next speed class if appropriate, and rebuilds the list of available speed classes (some subscribers enable special speed classes via tags.) */
  private deriveOtherDataFromSubscribers() {
    if (this.subscribers.length > 0) {
      const firstSpeedClassLabel = this.subscribers[0].speedClass;

      if (this.subscribers.every((e) => e.speedClass === firstSpeedClassLabel)) {
        this.newSpeedClass = SpeedClass.get(firstSpeedClassLabel);
        // use the private property, since setting the public one invokes this method
        this.debug('Set _newSpeedClass to ', firstSpeedClassLabel, this.newSpeedClass);
      }
    }
    const availableSpeedClasses = SpeedClass.possibleSpeedClassesForSubscribers(this.subscribers, this.necContractCode);
    this._availableSpeedClasses = availableSpeedClasses.map((name) => SpeedClass.get(name));
  }

  updateFunction(subscriber: ExtendedSubscriberInterface) {
    return subscriber.isSim ? this.updateSim(subscriber) : this.updateLegacySubscriber(subscriber);
  }

  updateLegacySubscriber(subscriber: ExtendedSubscriberInterface) {
    return this.subscriberApiService.updateSpeedClass({
      imsi: subscriber.imsi,
      updateSpeedClassRequest: {
        speedClass: this.newSpeedClass.label as UpdateSpeedClassRequestSpeedClassEnum,
      },
    });
  }

  updateSim(subscriber: ExtendedSubscriberInterface) {
    return this.simApiService.updateSimSpeedClass({
      simId: subscriber.simId,
      updateSpeedClassRequest: {
        speedClass: this.newSpeedClass.label as UpdateSpeedClassRequestSpeedClassEnum,
      },
    });
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.speedClass`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return LegacyTextContent.string(subscriber.speedClass);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return this.newSpeedClass ? LegacyTextContent.string(this.newSpeedClass.label) : undefined;
  }
}
