import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  cleanAndFormat19DigitIccid,
  cleanAndFormatRegistrationSecret,
  removeFormattingFrom19DigitIccid,
} from '@soracom/shared/soracom-platform';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { debounceTime } from 'rxjs';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';

const iccidPattern = new RegExp(/^([0-9]{5}\s[0-9]{5}\s[0-9]{5}\s[0-9]{3}\s[0-9]{1})$/);
const registrationSecretPattern = new RegExp(/^[A-Z0-9]{4}-[A-Z0-9]{4}$/);

@Component({
  selector: 'app-register-sim-registration-key',
  templateUrl: './register-sim-registration-key.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterSimRegistrationKeyComponent implements OnInit {
  // @ts-expect-error (legacy code incremental fix)
  @Input() closeModal: (isCancel?: boolean) => void;
  awaitingResult = false;
  alertManager = new AlertsManager();
  // @ts-expect-error (legacy code incremental fix)
  public form: FormGroup;

  constructor(
    private cdf: ChangeDetectorRef,
    private soracomApi: SoracomApiService,
    private uiDsModalService: UiDsModalService
  ) {}

  ngOnInit(): void {
    this.setupForm();
  }

  private setupForm() {
    this.form = new FormGroup({
      firstIccid: new FormControl('', [Validators.pattern(iccidPattern), Validators.required]),
      lastIccid: new FormControl('', [Validators.pattern(iccidPattern), Validators.required]),
      registrationSecret: new FormControl('', [Validators.pattern(registrationSecretPattern), Validators.required]),
    });
    const firstIccid = this.form.get('firstIccid');
    // @ts-expect-error (legacy code incremental fix)
    firstIccid.valueChanges.pipe(debounceTime(300)).subscribe((value: LegacyAny) => {
      const formatted = cleanAndFormat19DigitIccid(value);
      if (formatted !== value) {
        // @ts-expect-error (legacy code incremental fix)
        firstIccid.setValue(formatted);
        this.cdf.markForCheck();
      }
    });

    const lastIccid = this.form.get('lastIccid');
    // @ts-expect-error (legacy code incremental fix)
    lastIccid.valueChanges.pipe(debounceTime(300)).subscribe((value: LegacyAny) => {
      const formatted = cleanAndFormat19DigitIccid(value);
      if (formatted !== value) {
        // @ts-expect-error (legacy code incremental fix)
        lastIccid.setValue(formatted);
        this.cdf.markForCheck();
      }
    });

    const registrationSecret = this.form.get('registrationSecret');
    // @ts-expect-error (legacy code incremental fix)
    registrationSecret.valueChanges.pipe(debounceTime(300)).subscribe((value: LegacyAny) => {
      const formatted = cleanAndFormatRegistrationSecret(value);
      if (formatted !== value) {
        // @ts-expect-error (legacy code incremental fix)
        registrationSecret.setValue(formatted);
        this.cdf.markForCheck();
      }
    });
  }

  confirm() {
    this.awaitingResult = true;
    this.makeRegistrationRequest().then(
      (response: LegacyAny) => {
        this.awaitingResult = false;
        this.closeModal(false);
        this.uiDsModalService.openConfirmModal('subscriber_registration.success.header', {
          title: 'subscriber_registration.header',
          hideCancelButton: true,
          okButtonTitleId: 'generic.button_title.close',
          contentClass: 'ds-notice --success --icon-online',
        });
      },
      (err) => {
        this.awaitingResult = false;
        this.showGenericError();
      }
    );
  }

  makeRegistrationRequest(): Promise<any> {
    return this.soracomApi.callApiWithToken({
      method: 'post',
      path: '/v1/sim_batch_registrations/register',
      contentType: 'application/json',
      body: this.parseFormValuesToApiRequestBody(),
    });
  }

  close() {
    this.closeModal();
  }

  private parseFormValuesToApiRequestBody() {
    const firstIccid = removeFormattingFrom19DigitIccid(this.form.value.firstIccid);
    const lastIccid = removeFormattingFrom19DigitIccid(this.form.value.lastIccid);
    const registrationSecret = this.form.value.registrationSecret;
    return {
      firstIccid,
      lastIccid,
      registrationSecret,
    };
  }

  private showGenericError() {
    this.alertManager.add(Alert.danger('registerSimByPasscode.errorWarning'));
  }
}
