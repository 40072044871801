import { LegacyAny } from '@soracom/shared/core';

import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { Group, Groupable } from '@soracom/shared/group';
import { AlertsServiceInstance } from './alerts.service';

export function assignGroup<T extends Groupable>(alertsService: AlertsServiceInstance, data: T[]): Promise<T[]> {
  /*
   * TODO: This is a kind of dirty implementation to perform object relation.
   *
   * Problem 1: If the number of groups is too big, we cannot fetch all groups at once.
   */
  return groupsService
    .getCachedAll()
    .then((groups) => {
      data.forEach((obj: LegacyAny) => {
        if (obj.groupId) {
          const group = groups.get(obj.groupId);
          if (group) {
            obj.group = new Group(group);
          }
        }
      });
      return data;
    })
    .catch((error: LegacyAny) => {
      if (alertsService) {
        alertsService.showError(error);
      }
      return data;
    });
}
