import {
  bundles,
  group,
  imsi,
  speedClass,
  status,
  subscription,
} from '@soracom/shared/i18n/common/label';

/**
 * This `column` section is i18n for column titles of the table.
 */
const column = {
  // These come from the @soracom/shared/i18n lib, so they don't need translation (they are already translated):
  bundles,
  group,
  imsi,
  speedClass,
  status,
  subscription,

  /**
   * Date and time string, like `2023-01-26 08:03`
   */
  dateString: {
    en: 'Date',
    ja: '日時',
  },
} as const;

const button = {
  loadMore: {
    en: 'Load more...',
    ja: 'さらに読み込む',
  },
} as const;

const message = {
  endOfHistoryData: {
    en: 'This is the end of the update history data. No additional data exists.',
    ja: '変更履歴は以上です。これ以上の履歴データはありません。',
  },

  // dataIsFiltered: {
  //   en: 'Data is filtered',
  //   ja: 'データはフィルタされています',
  // },

  // dataIsFilteredByImsi: {
  //   en: 'Data is filtered by IMSI. Showing {{ count }} of {{ total }} entries.',
  //   ja: 'データは IMSI でフィルタされています FIXME:JA Showing {{ count }} of {{ total }} entries',
  // },

  filterByImsi: {
    en: 'Filter by IMSI',
    ja: '表示する IMSI を選択',
  },

  all: {
    en: 'All',
    ja: 'すべて',
  },
} as const;

export type LocalizedColumnName = keyof typeof column;

export const i18n = {
  button,
  column,
  message,
} as const;
