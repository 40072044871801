<section dsDialogContent>
  <form class="form-horizontal">
    <div class="form-group">
      <div class="col-sm-12">
        <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-5 control-label">
        {{ 'change_termination_protection.termination_protection' | translate }}
      </label>
      <div class="col-sm-7">
        <ui-on-off-switch
          name="protectionSwitch"
          class="x-termination-protection"
          [content]="terminationProtectionSwitch"
        >
        </ui-on-off-switch>
        <div class="form-control-static">
          <small>
            <em class="text-muted">
              {{ 'change_termination_protection.switch_help' | translate: modelInfo }}
            </em>
          </small>
        </div>
      </div>
    </div>
  </form>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'ChangeSubscriberTerminationProtectionComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'generic.button_title.cancel' | translate"></span>
  </button>
</footer>

