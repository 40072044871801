<thead class="--sticky">
  <tr>
    <th class="--width-pc-5">{{ i18n.remainingUsageTable.imsi | localize }}</th>
    <th class="--width-pc-5">{{ i18n.remainingUsageTable.remainingData | localize }}</th>
    <th class="--width-pc-5">{{ i18n.remainingUsageTable.remainingSms | localize }}</th>
    <th class="--width-pc-5">{{ i18n.remainingUsageTable.remainingUssd | localize }}</th>
    <th class="--width-pc-5">{{ i18n.remainingUsageTable.expiryDate | localize }} <i class="ds-icon --tiny --icon-question" data-ds-texttip="YYYY-MM-DD"></i></th>
  </tr>
</thead>
<tbody>
  <tr>
    <td class="--no-wrap">{{simUsage?.imsi}}</td>
    <td class="--no-wrap" data-testid="fx-remaining-data" appFixedRateSimRemainingUsageTableValue [value]="simUsage?.remainingData" suffix="MB"></td>
    <td class="--no-wrap" data-testid="fx-remaining-sms" appFixedRateSimRemainingUsageTableValue [value]="simUsage?.remainingSms"></td>
    <td class="--no-wrap" data-testid="fx-remaining-ussd" appFixedRateSimRemainingUsageTableValue [value]="simUsage?.remainingUssd"></td>
    <td class="--no-wrap" data-testid="fx-remaining-expiry">{{simUsage?.expiry}}</td>
  </tr>
</tbody>
