import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyAny, assertNever } from '@soracom/shared/core';
import {
  SubscriberStatus,
  SubscriptionPlan,
  SubscriptionPlanInterface,
} from '@soracom/shared/subscription-plan';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriptionPlanName } from '@foundation/soracom-platform';

/**
 * Utility class to build complex i18n messages with type-safety and autocomplete, specifically for SubscriptionPlan-related messages. (It was very error-prone to build these messages without type-checked parameters, and the E2E tests are slow so that made things time-consuming.)
 *
 */
export class SubscriptionPlanUserMessage {
  private _partialTranslations: LegacyTextContent[] = [];

  private add(partialTranslation: LegacyTextContent) {
    this._partialTranslations.push(partialTranslation);
  }

  getJpLocalizedStatus(status: SubscriberStatus) {
    switch (status) {
      case 'active':
        return '使用中';
      case 'inactive':
        return '休止';
      case 'ready':
        return '準備完了';
      case 'shipped':
        return '出荷済み';
      case 'standby':
        return '利用開始待ち';
      case 'suspended':
        return '利用中断中';
      case 'terminated':
        return '解約済み';
      case 'transferring':
        return '譲渡手続き中';
      default:
        assertNever(status);
        return 'error';
    }
  }

  getJpLocalizedReactivationFeeWording(planName: SubscriptionPlanName) {
    switch (planName) {
      case 'planAP1':
        return 'アクティベーション料金';
      case 'plan-KM1':
        return '利用再開手数料';
      case 'plan-K':
        return '設定変更料金';
      default:
        return 'アクティベーション料金';
    }
  }

  /**
   * Get the options to use as the the translation parameters. This standardizes all the names. It is OK if they aren't all used.
   */
  private getTranslationParameters(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ): {[key: string]: string} {
    //
    const options = SubscriptionPlan.getOptions(subscriber);
    const basicFee = plan.effectiveBasicFee('active', options);
    const basicFeeInNewStatus = plan.effectiveBasicFee(newStatus, options);
    const basicFeeInNewStatusAmount = basicFeeInNewStatus && basicFeeInNewStatus.amount;
    const basicFeeCurrency = basicFee.currency;
    const basicFeeAmount = basicFee.amount;

    const transitionFee = plan.effectiveTransitionFeeForStatus(newStatus);

    const statusExitFee = transitionFee && transitionFee.exitFee; // <= 🤔
    const statusEntryFee = transitionFee && transitionFee.entryFee;
    const annualExtensionFee = transitionFee && transitionFee.annualExtensionFee;
    const statusEntryFeeCurrency = transitionFee && transitionFee.currency;
    const statusExitFeeCurrency = transitionFee && transitionFee.currency;
    const annualExtensionCurrency = transitionFee && transitionFee.currency;
    const extendedSuspensionFee = plan.extendedSuspensionFee;
    const reactivationFeeCurrency = plan.reactivationFee && plan.reactivationFee.currency;
    const reactivationFee = plan.reactivationFee && plan.reactivationFee.amount;
    const jpReactivationFeeWording = this.getJpLocalizedReactivationFeeWording(plan.planName); // In japan for some reason the wording for re-activation fee is changed per plan.  This is an inconsistency and may change in the future.
    // We have to manually localize here because ngx-translate is not capable of doing what we need sanely (and mason has never finished his TypeScript-instead-of-YAML i18n lib lol)
    const jpLocalizedCurrency = basicFeeCurrency === 'JPY' ? '円' : basicFeeCurrency;

    const jpLocalizedNewStatus = this.getJpLocalizedStatus(newStatus);

    const result = {
      annualExtensionCurrency,
      annualExtensionFee,
      basicFeeAmount,
      basicFeeInNewStatusAmount,
      basicFeeCurrency,
      newStatus,
      statusEntryFee,
      statusEntryFeeCurrency,
      statusExitFee,
      statusExitFeeCurrency,
      jpLocalizedCurrency,
      jpLocalizedNewStatus,
      extendedSuspensionFee,
      reactivationFee,
      reactivationFeeCurrency,
      jpReactivationFeeWording,
    };

    return result as {[key: string]: string};
  }

  blankLine() {
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.blankLine.any');
    this.add(t);
  }

  space() {
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.space.any');
    this.add(t);
  }

  pleaseSeeThePricingPageForMoreInformation() {
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.pleaseSeeThePricingPageForMoreInformation.any'
    );
    this.add(t);
  }

  statusWillDisableDataTransferAndDailyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillDisableDataTransferAndDailyBasicFee',
      params
    );
    this.add(t);
  }

  statusWillDisableDataTransferAndMonthlyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillDisableDataTransferAndMonthlyBasicFee',
      params
    );
    this.add(t);
  }

  statusEntryWillIncurFee(newStatus: SubscriberStatus, subscriber: ExtendedSubscriberInterface, plan: SubscriptionPlanInterface) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.statusEntryWillIncurFee', params);
    this.add(t);
  }

  statusExitWillIncurFee(newStatus: SubscriberStatus, subscriber: ExtendedSubscriberInterface, plan: SubscriptionPlanInterface) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.statusExitWillIncurFee', params);
    this.add(t);
  }

  statusWillIncurAnnualRenewalFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.statusWillIncurAnnualRenewalFee', params);
    this.add(t);
  }

  statusWillDisableDataTransferAndReduceTheMonthlyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillDisableDataTransferAndReduceTheMonthlyBasicFee',
      params
    );
    this.add(t);
  }

  statusWillDisableDataTransferAndReduceTheDailyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillDisableDataTransferAndReduceTheDailyBasicFee',
      params
    );
    this.add(t);
  }
  statusWillDisableDataTransferButNotReduceTheMonthlyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillDisableDataTransferButNotReduceTheMonthlyBasicFee',
      params
    );
    this.add(t);
  }

  statusWillDisableDataTransferButNotReduceTheDailyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillDisableDataTransferButNotReduceTheDailyBasicFee',
      params
    );
    this.add(t);
  }

  statusWillEnableDataTransferWithDailyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillEnableDataTransferWithDailyBasicFee',
      params
    );
    this.add(t);
  }

  statusWillEnableDataTransferWithMonthlyBasicFee(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillEnableDataTransferWithMonthlyBasicFee',
      params
    );
    this.add(t);
  }

  statusWillEnableDataTransferForFixedRateSim(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface,
    plan: SubscriptionPlanInterface
  ) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusWillEnableDataTransferFixedRate',
      params
    );
    this.add(t);
  }

  statusWillIncurReactivationFee(newStatus: SubscriberStatus, subscriber: ExtendedSubscriberInterface, plan: SubscriptionPlanInterface) {
    //
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);

    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.statusWillIncurReactivationFee.any', params);
    this.add(t);
  }

  statusCostImpactCouldNotBeAutomaticallyDetermined(newStatus: SubscriberStatus) {
    const jpLocalizedNewStatus = this.getJpLocalizedStatus(newStatus);
    const params: LegacyAny = { newStatus, jpLocalizedNewStatus };
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.statusCostImpactCouldNotBeAutomaticallyDetermined',
      params
    );
    this.add(t);
  }

  statusNotSupported(newStatus: SubscriberStatus, subscriber: ExtendedSubscriberInterface, plan: SubscriptionPlanInterface) {
    const params: LegacyAny = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.statusNotSupported', params);
    this.add(t);
  }

  basicFeeDiscountsMayAlsoApply() {
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.basicFeeDiscountsMayAlsoApply.any');
    this.add(t);
  }

  longTermDiscountsMayAlsoApply() {
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.longTermDiscountsMayAlsoApply.any');
    this.add(t);
  }

  basicFeeDiscountsDoNotApplyInStatus(newStatus: SubscriberStatus) {
    const jpLocalizedNewStatus = this.getJpLocalizedStatus(newStatus);
    const params = { newStatus, jpLocalizedNewStatus };
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.basicFeeDiscountsDoNotApplyInStatus.any',
      params
    );
    this.add(t);
  }

  longTermDiscountsDoNotApplyInStatus(newStatus: SubscriberStatus) {
    const jpLocalizedNewStatus = this.getJpLocalizedStatus(newStatus);
    const params = { newStatus, jpLocalizedNewStatus };
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.longTermDiscountsDoNotApplyInStatus.any',
      params
    );
    this.add(t);
  }

  willBeActiveWhenConnect() {
    const standBy = 'standby';
    const active = 'active';
    const jpLocalizedStandBy = this.getJpLocalizedStatus(standBy);
    const jpLocalizedActive = this.getJpLocalizedStatus(active);
    const params = { standBy, jpLocalizedStandBy, active, jpLocalizedActive };
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.willBeActiveWhenConnect.any', params);
    this.add(t);
  }

  willChargeAfter6Months(newStatus: SubscriberStatus, subscriber: ExtendedSubscriberInterface, plan: SubscriptionPlanInterface) {
    const params = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation(
      'SubscriptionPlanUserMessage.willChargeMonthlyFeeAfter6Months.any',
      params as LegacyAny
    );
    this.add(t);
  }

  suspendedRebootWarning(newStatus: SubscriberStatus, subscriber: ExtendedSubscriberInterface, plan: SubscriptionPlanInterface) {
    const params = this.getTranslationParameters(newStatus, subscriber, plan);
    const t = LegacyTextContent.translation('SubscriptionPlanUserMessage.statusWillPermanentlyRejectConnection', params);
    this.add(t);
  }

  private isBlankLine(t: LegacyTextContent) {
    return t.translatable && t.translatable.id === 'SubscriptionPlanUserMessage.blankLine.any';
  }

  private isSpace(t: LegacyTextContent) {
    return t.translatable && t.translatable.id === 'SubscriptionPlanUserMessage.space.any';
  }

  private isWhitespace(t: LegacyTextContent) {
    return this.isBlankLine(t) || this.isSpace(t);
  }

  get partialTranslations() {
    // FIXME: remove spaces adjacent to blank lines here, and reduce consecutive blank lines to one, just in case
    // return this._partialTranslations;

    const result: LegacyTextContent[] = [];

    this._partialTranslations.forEach((t) => {
      if (result.length < 1) {
        result.push(t);
      } else {
        const last = result[result.length - 1];

        if (this.isBlankLine(last) && this.isWhitespace(t)) {
          // don't add
        } else if (this.isWhitespace(last) && this.isSpace(t)) {
          // don't add
        } else {
          result.push(t);
        }
      }
    });
    return result;
  }

  /**
   * This is kind of weird. It partially constructs translations for user-facing warnings about side effects of changing the status of SIMs. But, it leaves off the final `"single"` or `"multiple"` part of the i18n key. This is because it will be added later, after all SIMs have been processed and separated into groups that have the same warnings.
   */
  static getPartiallyConstructedWarningsForStatusChange(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface
  ): LegacyTextContent[] {
    // 👨🏻‍🍳
    const msg = new SubscriptionPlanUserMessage();
    const plan = subscriber.subscriptionPlan;

    // Handle special cases and return early:
    if (plan.requiresNonSpecificStatusChangeWarnings) {
      return this.getPartiallyConstructedWarningsForStatusChangeUnknownImpact(newStatus, subscriber);
    }

    const subscriberOptions = SubscriptionPlan.getOptions(subscriber);

    const feePeriod = plan.basicFeePeriod(subscriberOptions);

    const basicFeeAmountsByStatus: LegacyAny = plan.effectiveBasicFeeAmountByStatus(subscriberOptions);

    const transitionFee = plan.effectiveTransitionFeeForStatus(newStatus, subscriberOptions);

    const basicFeeInCurrentStatus = basicFeeAmountsByStatus[subscriber.status];
    const basicFeeInNewStatus = basicFeeAmountsByStatus[newStatus];
    const currentStatus = subscriber.status;
    const hasReactivationFee =
      (currentStatus === 'standby' || currentStatus === 'suspended') &&
      (newStatus === 'active' || newStatus === 'inactive') &&
      plan.reactivationFee;

    if (!plan.supportedStatuses.includes(newStatus)) {
      msg.statusNotSupported(newStatus, subscriber, plan);
      return msg.partialTranslations;
    }

    if (newStatus === 'active') {
      if (feePeriod === 'daily') {
        msg.statusWillEnableDataTransferWithDailyBasicFee(newStatus, subscriber, plan);
      } else if (feePeriod === 'monthly') {
        msg.statusWillEnableDataTransferWithMonthlyBasicFee(newStatus, subscriber, plan);
      } else if (feePeriod === 'fixed') {
        msg.statusWillEnableDataTransferForFixedRateSim(newStatus, subscriber, plan);
      }
      msg.blankLine();
      if (hasReactivationFee) {
        msg.statusWillIncurReactivationFee(newStatus, subscriber, plan);
        msg.blankLine();
      }
      // The D-300MB causes plan-D to become pretty different, so we need to suppress some user-facing messages:
      if (!subscriberOptions.isPlanDWithD300MBBundle) {
        this.addBasicFeeDiscountInfoIfApplicable(msg, plan);
        msg.pleaseSeeThePricingPageForMoreInformation();
      }
      return msg.partialTranslations;
    } else if (newStatus === 'inactive') {
      if (basicFeeInNewStatus) {
        // Has a fee in 'inactive' status
        if (Number(basicFeeInCurrentStatus) > Number(basicFeeInNewStatus)) {
          // Has lower fee in inactive status
          if (feePeriod === 'daily') {
            msg.statusWillDisableDataTransferAndReduceTheDailyBasicFee(newStatus, subscriber, plan);
          } else if (feePeriod === 'monthly') {
            msg.statusWillDisableDataTransferAndReduceTheMonthlyBasicFee(newStatus, subscriber, plan);
          }
        } else {
          // does not have lower fee in inactive status
          if (feePeriod === 'daily') {
            msg.statusWillDisableDataTransferButNotReduceTheDailyBasicFee(newStatus, subscriber, plan);
          } else if (feePeriod === 'monthly') {
            msg.statusWillDisableDataTransferButNotReduceTheMonthlyBasicFee(newStatus, subscriber, plan);
          }
        }
        msg.blankLine();

        if (hasReactivationFee) {
          msg.statusWillIncurReactivationFee(newStatus, subscriber, plan);
          msg.blankLine();
        }
        if (!subscriberOptions.isPlanDWithD300MBBundle) {
          this.addBasicFeeDiscountInfoIfApplicable(msg, plan);
          msg.pleaseSeeThePricingPageForMoreInformation();
        }
        return msg.partialTranslations;
      } else {
        // has no fee in inactive status.
        // Mason 2020-05-24 We don't have any plans like that so this case is unimplemented (no message would appear)
        return msg.partialTranslations;
      }
    } else if (newStatus === 'suspended' || newStatus === 'standby') {
      const basicSuspendedMonthlyFeeIsDelayedBy6Months = subscriberOptions.subscriptionPlans?.includes('plan-US');
      if (basicSuspendedMonthlyFeeIsDelayedBy6Months) {
        msg.willChargeAfter6Months(newStatus, subscriber, plan);
      } else if (!basicFeeInNewStatus) {
        if (feePeriod === 'daily') {
          msg.statusWillDisableDataTransferAndDailyBasicFee(newStatus, subscriber, plan);
        } else if (feePeriod === 'monthly') {
          msg.statusWillDisableDataTransferAndMonthlyBasicFee(newStatus, subscriber, plan);
        }
      } else if (basicFeeInNewStatus === basicFeeInCurrentStatus) {
        if (feePeriod === 'daily') {
          msg.statusWillDisableDataTransferButNotReduceTheDailyBasicFee(newStatus, subscriber, plan);
        } else if (feePeriod === 'monthly') {
          msg.statusWillDisableDataTransferButNotReduceTheMonthlyBasicFee(newStatus, subscriber, plan);
        }
      } else {
        if (feePeriod === 'daily') {
          msg.statusWillDisableDataTransferAndReduceTheDailyBasicFee(newStatus, subscriber, plan);
        } else if (feePeriod === 'monthly') {
          msg.statusWillDisableDataTransferAndReduceTheMonthlyBasicFee(newStatus, subscriber, plan);
        }
      }
      msg.blankLine();

      if (newStatus === 'standby') {
        msg.willBeActiveWhenConnect();
        msg.blankLine();
      }

      if (transitionFee) {
        if (transitionFee.entryFee) {
          msg.statusEntryWillIncurFee(newStatus, subscriber, plan);
          msg.space();
        }
        if (transitionFee.exitFee) {
          msg.statusExitWillIncurFee(newStatus, subscriber, plan);
          msg.space();
        }
        if (transitionFee.annualExtensionFee) {
          msg.statusWillIncurAnnualRenewalFee(newStatus, subscriber, plan);
        }
      }
      if (msg.partialTranslations.length > 0) {
        msg.blankLine();

        if (!subscriberOptions.isPlanDWithD300MBBundle) {
          this.addDiscountsDontApplyInNewStatusIfApplicable(msg, newStatus, plan);
          msg.pleaseSeeThePricingPageForMoreInformation();
        }
      }

      if (newStatus === 'suspended') {
        msg.blankLine();
        msg.suspendedRebootWarning(newStatus, subscriber, plan);
      }
      return msg.partialTranslations;
    } else {
      return [];
    }
  }

  private static addBasicFeeDiscountInfoIfApplicable(
    msg: SubscriptionPlanUserMessage,
    plan: SubscriptionPlanInterface
  ) {
    const basicFeeDiscounts = plan.basicFeeDiscountPossibilities;
    if (basicFeeDiscounts.length) {
      msg.blankLine();
      if (basicFeeDiscounts.includes('basic')) {
        msg.basicFeeDiscountsMayAlsoApply();
        msg.space();
      }
      if (basicFeeDiscounts.includes('long-term')) {
        msg.longTermDiscountsMayAlsoApply();
        msg.space();
      }
      msg.space();
    }
  }

  private static addDiscountsDontApplyInNewStatusIfApplicable(
    msg: SubscriptionPlanUserMessage,
    newStatus: SubscriberStatus,
    plan: SubscriptionPlanInterface
  ) {
    const basicFeeDiscounts = plan.basicFeeDiscountPossibilities;
    if (basicFeeDiscounts.length) {
      msg.blankLine();
      if (basicFeeDiscounts.includes('basic')) {
        msg.basicFeeDiscountsDoNotApplyInStatus(newStatus);
        msg.space();
      }
      if (basicFeeDiscounts.includes('long-term')) {
        msg.longTermDiscountsDoNotApplyInStatus(newStatus);
        msg.space();
      }
      msg.space();
    }
  }

  static getPartiallyConstructedWarningsForStatusChangeUnknownImpact(
    newStatus: SubscriberStatus,
    subscriber: ExtendedSubscriberInterface
  ): LegacyTextContent[] {
    // 👨🏻‍🍳
    const msg = new SubscriptionPlanUserMessage();

    msg.statusCostImpactCouldNotBeAutomaticallyDetermined(newStatus);
    msg.blankLine();
    msg.pleaseSeeThePricingPageForMoreInformation();
    return msg.partialTranslations;
  }

  /**
   * Groups the subscribers into groups that have identical pricing/side-effect warnings, so that they can be grouped logically in the app.
   */
  static groupSubscribersByStatusChangeWarnings(
    subscribers: ExtendedSubscriberInterface[],
    newStatus: SubscriberStatus
  ): Map<LegacyTextContent[], ExtendedSubscriberInterface[]> {
    // 🕷️
    const subscribersByWarning = new Map<LegacyTextContent[], ExtendedSubscriberInterface[]>();

    subscribers.forEach((subscriber) => {
      const plan = SubscriptionPlan.get(subscriber.subscription as any);
      // when we talk about it, we usually refer to it as "plan" but property is 'subscription'

      let warningsForThisSubscriber: LegacyTextContent[] = [];

      if (!plan) {
        // const unknownPlanWarning = new Translation("ERROR: unknown plan: " + subscriber.subscription);
        // warningsForThisSubscriber.push(unknownPlanWarning);
        // Nah, just don't show anything. This is not an error, although it should not happen in real life.
      } else {
        warningsForThisSubscriber = this.getPartiallyConstructedWarningsForStatusChange(newStatus, subscriber);
      }

      let existingArray: ExtendedSubscriberInterface[];

      for (const entry of subscribersByWarning.entries()) {
        const existingWarnings = entry[0];
        if (LegacyTextContent.arraysAreEquivalent(warningsForThisSubscriber, existingWarnings)) {
          existingArray = entry[1];
        }
      }

      // @ts-expect-error (legacy code incremental fix)
      if (existingArray) {
        existingArray.push(subscriber);
      } else {
        subscribersByWarning.set(warningsForThisSubscriber, [subscriber]);
      }
    });

    // Now, figure out whether each warning needs to be the single or multiple version:
    subscribersByWarning.forEach((subscribersInGroup, partialTranslations) => {
      const suffix = subscribersInGroup.length === 1 ? '.single' : '.multiple';
      partialTranslations.forEach((partialTranslation) => {
        // @masonmark 2023-09-25: This if() is a workaround for an error that appeared after we enabled strict mode for the legacy code. But in this case, this code is expecting the .translatable property to always exist, and if it did not, we would have a bug, so throwing an Error here is appropriate.
        if (!partialTranslation.translatable) {
          throw new Error('no translatable');
        } else {
          if (!(partialTranslation.translatable && partialTranslation.translatable.id.endsWith('.any'))) {
            // almost all the strings here need to be different in English for singular/plural, so we use ".any" as our convention to mean 'either singular or plural'
            partialTranslation.translatable.id += suffix;
          }
        }
      });
    });

    const result = new Map<LegacyTextContent[], ExtendedSubscriberInterface[]>();
    subscribersByWarning.forEach((subscribersInGroup, warningTranslations) => {
      const warningTextContents: LegacyTextContent[] = [];
      warningTranslations.forEach((warningTranslation) => {
        warningTextContents.push(warningTranslation);
      });
      result.set(warningTextContents, subscribersInGroup);
    });
    return result;
  }
}
