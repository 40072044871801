import { inject } from '@angular/core';
import { SimApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import {
  ApiException,
  SimApiListSimStatusHistoryRequest,
  SimStatusHistoryResponse,
} from '@soracom/shared/soracom-api-typescript-client';
import { SimStatusHistoryResponseWrapper } from './SimStatusHistoryResponseWrapper';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { HttpLinkHeader, Pagination } from '@soracom/shared/pagination';
import { Stopwatch } from '@soracom/shared/core';
import { LoggerService } from '@soracom/shared-ng/logger-service';

const defaultPageLimit = 100;
/**
 * A local data provider for the SIM update history table. This is a stateful object that manages its own memory and pagination. It is designed to manage Update History data for a single SIM at a time. It uses the "Load More" pattern, and will just keep loading more data and appending it to its internal memory until there is no more data to load.
 *
 * NOTE: This design is a little different from usual, because I was initially trying to use Super Table and this object would have been the bridge between the SATC and the Super Table data provider API. I had to drop Super Table for now, but we might go back to it later.
 */
export class SimUpdateHistoryDataProvider {
  simApi = inject(SimApiService);
  logger = inject(LoggerService);

  /**
   * FIXME: this error will be the canonical errors source for the table component — handle it
   */
  errors: Array<string | Error> = [];

  /**
   * Special case. In our unit tests we mock and fetching group name always gets an error. We don't really need to track these in real life hopefully, because it shouldn't error but we do here just in case. If the group name fetch does get an error, that still shouldn't fail the entire fetch history operation (arguably, anyway).
   */
  groupFetchErrors: Array<string | Error> = [];

  /**
   * Clear the errors array. This implies some owning object has dealt with the errors somehow, and they are no longer needed.
   */
  clearErrors() {
    this.errors.length = 0;
  }

  rawData: SimStatusHistoryResponse[] = [];

  /**
   * The "wrapped" data includes more fields than the raw API data.
   */
  wrappedData: SimStatusHistoryResponseWrapper[] = [];

  simId?: string;
  pagination?: HttpLinkHeader;
  hasLoaded = false;

  /**
   * Time performance to help make sure we're not doing anything stupid
   */
  stopwatch = Stopwatch.create('loadMore', 'computeChanges');

  /**
   * In order to make the UI able to highlight what changed, each entry must be compared to the previous entry matching that IMSI. This implies the whole list of SIM update history entries must be loaded, because for SIMs with multiple subscriptions (multi-IMSI "subscription container") then the different change events will be interleaved.
   *
   * NOTE: The PRD says that "what changed" is out of scope, so this may be removed. Or not though, since it basically works?
   */

  get canLoadMore(): boolean {
    return (
      !this.hasLoaded || (this.simId != null && this.pagination?.next != null)
    );
  }

  reset() {
    this.hasLoaded = false;
    this.simId = undefined;
    this.pagination = undefined;
    this.rawData.length = 0;
    this.wrappedData.length = 0;
  }

  /**
   * This will load the next page of data, including the first page if it hasn't been loaded yet. If you want to start over, use `reset()`. This provider manages the memory for its data and stores it as an instance property. It is stateful, managing pagination, and stores data pertaining to a single SIM ID. It will automatically `reset()` if you pass a different SIM ID than what it's currently managing.
   */
  async loadMore(q: { simId: string; limit?: number }) {
    this.stopwatch.loadMore.start();

    // Handle the (weird) case that we're loading a different SIM's history; implies we have to reload everything:
    const thisSimIsDifferent = this.simId !== q.simId;

    if (thisSimIsDifferent) {
      this.reset();
      this.simId = q.simId;
    }

    const query: SimApiListSimStatusHistoryRequest = { ...q };

    if (this.pagination?.next?.lastEvaluatedKey) {
      query.lastEvaluatedKey = this.pagination.next.lastEvaluatedKey;
    }
    query.limit ??= q.limit ?? defaultPageLimit;

    try {
      // Do the request first; if it gets an error we do not want to update ANY of the internal state (except the errors array, which is handled in the catch block)
      // await sleep(2000);
      const res = await this.simApi.listSimStatusHistory(query);

      // If we get here, it worked, so we can update the internal state:
      this.hasLoaded = true;
      this.pagination = Pagination.getPaginationLinks(res.headers['link']);
      this.rawData.push(...res.data);

      // Now we have loaded more data, so we need to rebuild the wrapped data. The reason we need to rebuild it all is because the "what changed" highlighting requires looking back in the history, and we might have loaded more data that gives us additional information about what changed.

      // FIXME: This is a matter of a few hundred ms at the worst (but usually just like 1ms), but this simple way of rebuilding everything from 0 is not efficient. If we found the "previous" event for a givent IMSI, the existing wrapped data doesn't need to be updated.
      this.stopwatch.computeChanges.start();

      this.wrappedData.length = 0;
      for (let i = 0; i < this.rawData.length; i++) {
        const event = this.rawData[i];
        this.wrappedData.push(
          new SimStatusHistoryResponseWrapper(event, this.rawData.slice(i + 1))
        );

        // Add group names. Doing this sequentially looks kind of lame, but actually the entire cache will be rebuilt on the first call (if needed), so it's not actually bad:
        for (const w of this.wrappedData) {
          if (w.groupId && !w.groupName) {
            try {
              const group = await groupsService.getCachedById(w.groupId);
              if (group?.name != null) {
                w.groupName = group.name;
              }
            } catch (error) {
              this.groupFetchErrors.push(
                error instanceof Error ? error : new Error(`${error}`)
              );
            }
          }
        }
      }
      this.stopwatch.computeChanges.stop();
    } catch (error) {
      // @masonmark 2023-10-11: Well I think this error handling is a little weird; we could (and probably should) fix this in the SATC itself. Without the first clause here, the UI error is something like "ApiException: -500"
      if (error instanceof ApiException) {
        const body = await error.data;
        const message = body?.message ?? error.message ?? 'unknown error';
        const code = body?.code ? `(${body.code})` : '';
        this.errors.push(new Error(`${message} ${code}`));
      } else if (error instanceof Error) {
        this.errors.push(error);
      } else {
        this.errors.push(new Error(`${error}`));
      }
      // RESET if there is any error; we can't rely on the data we have in that case
      this.reset();
    } finally {
      this.stopwatch.loadMore.stop();
      // this.logger.debug(
      //   `SimUpdateHistoryDataProvider PERFORMANCE NOTES:\n${this.stopwatch}`
      // );
    }
  }
}
