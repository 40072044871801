<section dsDialogContent>
  <div class="ds-text" [innerHtml]="uiModalExplanatoryText | translate"></div>
  <div class="x-change-speed-class" *ngFor="let updater of speedClassUpdaters; let index = index">
    <app-subscriber-batch-change class="x-{{ index }}" [updater]="updater"></app-subscriber-batch-change>

    <ui-select class="x-select-speed-class x-{{ index }}" [content]="getSpeedClassSelect(index)"></ui-select>

    <hr *ngIf="speedClassUpdaters.length > 0 && index !== speedClassUpdaters.length - 1" />
    <!-- only have the <hr> if we have multiple groups and this is not the last one -->
  </div>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'ChangeSubscriberSpeedClassComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'generic.button_title.cancel' | translate"></span>
  </button>
</footer>
