import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ImeiLockStatus } from 'apps/user-console/app/shared/subscribers/actions/ChangeImeiLockAction';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchImeiLockUpdater } from '../../shared/SubscriberBatchImeiLockUpdater';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';

const i18n = {
  allowConnectionsWhenImsiIsNotAvailable: {
    en: 'Allow connections when IMEI is temporarily not available',
    ja: '一時的に IMEI が利用できない場合に接続を許可する',
  },
};

const IMEI_REGEX = /^\d{15,16}$/;

@Component({
  selector: 'app-change-subscribers-imei-lock',
  templateUrl: './change-subscribers-imei-lock.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeSubscribersImeiLockComponent extends DsModalContentBase<
  ChangeSubscribersImeiLockModalData,
  ChangeSubscribersImeiLockCompletionData
> {
  i18n = i18n;

  /** Updater which will update imei lock status by calling api */
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchImeiLockUpdater;

  /** For single sim subscriber, user can type IMEI */
  // @ts-expect-error (legacy code incremental fix)
  imeiToSet: string;

  // @ts-expect-error (legacy code incremental fix)
  currentImei: string;
  private noImeiValue = 'N/A';

  get imeiPlaceholder() {
    return this.allowAnonymousIMEI && this.imeiToSet === '' ? this.noImeiValue : this.currentImei ?? this.noImeiValue;
  }

  /**
   * Some roaming circumstances cause the IMEI to be unavailable. This controls whether the connection should be allowed in such cases.
   */
  allowAnonymousIMEI = false;

  constructor(private logger: Logger, private apiService: SoracomApiService, private simApiService: SimApiService) {
    super();
  }

  ngOnInit() {
    if (this.subscribers && this.isSingleSubscriber && this.subscribers[0] && this.subscribers[0].sessionStatus) {
      this.currentImei = this.subscribers[0].sessionStatus.imei;
    }

    // When there is already imei set, user can modify it!
    this.imeiToSet = this.currentImei || '';
    this.currentImei = this.currentImei || this.noImeiValue;
    this.updater = new SubscriberBatchImeiLockUpdater(
      this.logger,
      this.apiService,
      this.subscribers,
      this.simApiService
    );
    console.log('FIXME: debug ChangeSubscribersImeiLockComponent', this);
  }

  get subscribers() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.subscribers;
  }

  /**
   * Stating whether this modal is for locking or unlocking
   */
  get isLockModal() {
    // @ts-expect-error (legacy code incremental fix)
    return this.modalData.lockStatus === 'lock';
  }

  get output() {
    return this.updater.subscribers;
  }

  get isSingleSubscriber(): boolean {
    return this.subscribers.length === 1;
  }

  get isImeiValid() {
    // @ts-expect-error (legacy code incremental fix)
    if (this.modalData.lockStatus === 'unlock') {
      // Don't care imei value
      return true;
    }

    if (this.subscribers.length > 1) {
      // When there are more than 2 subscribers, you can't specify IMEI.
      return true;
    }

    return IMEI_REGEX.test(this.imeiToSet);
  }

  get atLeastOneSubscriberWillBeChanged() {
    return !this.subscribers.every((s) => s.imeiLock === this.imeiToSet);
  }

  get canConfirm() {
    return this.isImeiValid && this.atLeastOneSubscriberWillBeChanged;
  }

  override canClose() {
    return this.updater.state !== 'busy';
  }

  async confirm() {
    const updater = this.isLockModal
      ? await this.updater.lockImei(this.imeiToSet, this.allowAnonymousIMEI)
      : await this.updater.unlockImei();
    if (updater.state === 'succeeded') {
      this.close({
        success: true,
        updatedSubscribers: updater.subscribers,
        // @ts-expect-error (legacy code incremental fix)
        newLockStatus: this.modalData.lockStatus,
      });
    } else {
      // Do nothing. Batch change component is responsible for showing errors.
    }
  }

  get confirmButtonTitle() {
    return this.isLockModal ? 'set_imei_lock.do_update' : 'unset_imei_lock.do_update';
  }
}

export interface ChangeSubscribersImeiLockCompletionData {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
  newLockStatus: ImeiLockStatus;
}

export interface ChangeSubscribersImeiLockModalData {
  subscribers: ExtendedSubscriberInterface[];
  lockStatus: ImeiLockStatus;
}
