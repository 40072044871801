import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import {
  DsModalContentBase,
  UiDsModalService,
} from '@soracom/shared-ng/ui-ds-modal';
import { fixedRateI18n } from '../i18n';
import {
  FixedRateSimViewDataService,
  FixedRateTopUpType,
} from '../fixed-rate-sim.service';
import { FormsModule } from '@angular/forms';
import {
  Alert,
  AlertsComponent,
  AlertsManager,
} from '@soracom/shared-ng/soracom-ui-legacy';
import { localize } from '@soracom/shared/locale-service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: 'app-operator-top-up-defaults',
  templateUrl: './operator-top-up-defaults.component.html',
  imports: [CommonModule, SoracomI18nModule, FormsModule, AlertsComponent],
  providers: [FixedRateSimViewDataService],
})
export class OperatorTopUpDefaultsComponent
  extends DsModalContentBase<
    OperatorTopUpDefaultsComponent,
    { success: boolean }
  >
  implements OnInit
{
  private fixedRateSimService = inject(FixedRateSimViewDataService);
  private cdRef = inject(ChangeDetectorRef);
  currentOperatorTopUpDefault?: FixedRateTopUpType;
  newOperatorTopUpDefault?: FixedRateTopUpType;
  i18n = fixedRateI18n;
  loading = false;
  alertsManager = new AlertsManager();

  ngOnInit(): void {
    if (!this.getInput()?.currentOperatorTopUpDefault) {
      this.loading = true;
      this.fixedRateSimService
        .getOperatorTopUpDefaults()
        .then((currentOperatorTopUpDefault) => {
          this.currentOperatorTopUpDefault = currentOperatorTopUpDefault;
          this.newOperatorTopUpDefault = this.currentOperatorTopUpDefault;
        })
        .catch((err) => {
          this.alertsManager.add(Alert.fromApiError(err));
        })
        .finally(() => {
          this.loading = false;
          this.cdRef.markForCheck();
        });
    } else {
      this.currentOperatorTopUpDefault =
        this.getInput()?.currentOperatorTopUpDefault;
      this.newOperatorTopUpDefault = this.currentOperatorTopUpDefault;
    }
  }

  submit() {
    if (this.newOperatorTopUpDefault) {
      this.loading = true;
      this.alertsManager.clear();
      this.fixedRateSimService
        .setOperatorTopUpDefaults(this.newOperatorTopUpDefault)
        .then(() => {
          this.loading = false;
          this.close({ success: true });
        })
        .catch((err) => {
          this.loading = false;
          this.alertsManager.add(Alert.fromApiError(err));
        })
        .finally(() => {
          this.cdRef.markForCheck();
        });
    }
  }

  override canClose(): boolean {
    return !this.loading;
  }

  canSubmit(): boolean {
    return (
      !this.loading &&
      !!this.newOperatorTopUpDefault &&
      this.currentOperatorTopUpDefault !== this.newOperatorTopUpDefault
    );
  }
}

export function injectOpenOperatorTopUpDefaultsComponent() {
  const uiDsModalService = inject(UiDsModalService);
  return function openOperatorTopUpDefaultsComponent(
    currentOperatorTopUpDefault?: FixedRateTopUpType
  ) {
    return uiDsModalService.openAndWaitForResult(
      OperatorTopUpDefaultsComponent,
      {
        title: localize(fixedRateI18n.operatorTopUpDefaults.title),
        subtitle: localize(fixedRateI18n.operatorTopUpDefaults.subTitle),
        headerIconClass: '--icon-top-up',
        data: { currentOperatorTopUpDefault },
      }
    );
  };
}

export interface OperatorTopUpDefaultsModalInput {
  currentOperatorTopUpDefault?: FixedRateTopUpType;
}
