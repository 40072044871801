import { Component, OnInit } from '@angular/core';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchSessionDeleter } from '../../shared/SubscriberBatchSessionDeleter';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { Logger } from '@soracom/shared/logger';
import { SubscribersContainer } from 'apps/user-console/app/shared/core/SubscribersContainer';

@Component({
  selector: 'app-delete-subscribers-session',
  templateUrl: './delete-subscribers-session.component.html',
})
export class DeleteSubscribersSessionComponent
  extends DsModalContentBase<SubscribersContainer, DeleteSubscribersSessionResult>
  implements OnInit
{
  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchSessionDeleter;

  get output(): ExtendedSubscriberInterface[] {
    return this.updater.subscribers;
  }

  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.logger.debug('ngOnInit()', this);

    this.updater = new SubscriberBatchSessionDeleter(this.logger, this.apiService, this.inputSubscribers);
  }

  get inputSubscribers(): ExtendedSubscriberInterface[] {
    return this.getInput().subscribers;
  }

  get canConfirm(): any {
    return this.updater.state !== 'busy';
  }

  get canCancel() {
    return this.updater.state !== 'busy';
  }

  get explanatoryText() {
    return this.inputSubscribers.length === 1 ? this._explanatoryTextSingle : this._explanatoryTextMultiple;
  }
  _explanatoryTextSingle = 'DeleteSubscribersSessionComponent.explanatoryText.single';
  _explanatoryTextMultiple = 'DeleteSubscribersSessionComponent.explanatoryText.multiple';

  get cautionaryText() {
    return this.inputSubscribers.length === 1 ? this._cautionaryTextSingle : this._cautionaryTextMultiple;
  }
  _cautionaryTextSingle = 'DeleteSubscribersSessionComponent.cautionaryText.single';
  _cautionaryTextMultiple = 'DeleteSubscribersSessionComponent.cautionaryText.multiple';

  confirm() {
    this.updater.performUpdate().then((updater) => {
      if (updater.state === 'succeeded') {
        this.close({ success: true, updatedSubscribers: this.output });
      } else {
        // Do nothing. Batch updater is the one who shows error messages.
      }
    });
  }

  get confirmButtonTitle() {
    return 'DeleteSubscribersSessionComponent.confirmButtonTitle.' + this.singleOrMultiple;
  }

  get singleOrMultiple() {
    return this.inputSubscribers.length === 1 ? 'single' : 'multiple';
  }

  canClose(): boolean {
    return this.updater.state !== 'busy';
  }
}

export interface DeleteSubscribersSessionResult {
  success: boolean;
  updatedSubscribers: ExtendedSubscriberInterface[];
}
