import { LegacyAny } from '@soracom/shared/core';

import { Component, OnInit } from '@angular/core';
import { SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import {
  generateStInMemoryServiceProviders,
  StInMemoryTableDataProviderService,
} from '@soracom/shared-ng/ui-super-table';
import { SubscribersApiMode } from 'apps/user-console/app/shared/subscribers/subscribers.component';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { ApiException, Sim } from '@soracom/shared/soracom-api-typescript-client';
import { groupsService } from '@soracom/shared/soracom-services-ui/groups-ui';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { ExtendedSim } from '@soracom/shared/sim';

@Component({
  providers: [
    StInMemoryTableDataProviderService,
    ...generateStInMemoryServiceProviders<Sim>(StInMemoryTableDataProviderService, {}),
  ],
  templateUrl: './transfer-sim-operator.component.html',
})
export class TransferSimOperatorComponent
  extends DsModalContentBase<TransferSimOperatorModalData, TransferSimOperatorCompletionData>
  implements OnInit
{
  // @ts-expect-error (legacy code incremental fix)
  operatorEmail: string;
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  busy: boolean = false;
  // @ts-expect-error (legacy code incremental fix)
  subscribers: Subscriber[];
  // @ts-expect-error (legacy code incremental fix)
  apiMode: SubscribersApiMode;
  constructor(
    private stTableProvider: StInMemoryTableDataProviderService<Sim>,
    private subscriberApiService: SubscriberApiService
  ) {
    super();
  }

  ngOnInit(): void {
    // @ts-expect-error (legacy code incremental fix)
    this.subscribers = this.modalData.subscribers;
    // @ts-expect-error (legacy code incremental fix)
    this.apiMode = this.modalData.apiMode;
    this.stTableProvider.setData(this.subscribers);
  }

  submit() {
    if (this.apiMode === 'subscriber') {
      this.submitRequestSubscriberMode();
    } else {
      this.submitRequestSimMode();
    }
  }

  override canClose() {
    return !this.busy;
  }

  get operatorIdPattern() {
    return /^OP\d{10}$/;
  }

  groupName(groupId: string) {
    return groupsService.getGroupName(groupId);
  }

  private submitRequestSubscriberMode() {
    this.busy = true;
    const completionData: TransferSimOperatorCompletionData = {
      success: false,
      error: undefined,
    };
    const imsis: string[] = this.subscribers.map((subscriber) => {
      return subscriber.imsi as string;
    });
    this.subscriberApiService
      .issueSubscriberTransferToken({
        issueSubscriberTransferTokenRequest: {
          transferDestinationOperatorEmail: this.operatorEmail,
          transferDestinationOperatorId: this.operatorId,
          transferImsi: imsis,
        },
      })
      .then(() => {
        this.busy = false;
        completionData.success = true;
        this.close(completionData);
      })
      .catch((err: LegacyAny) => {
        this.busy = false;
        completionData.success = false;
        completionData.error = err instanceof ApiException ? err.data : Alert.unexpected();
        this.close(completionData);
      });
  }

  private submitRequestSimMode() {
    //FIXME: Right now this will just call the same endpoint as Subscriber mode, but this may change.
    this.submitRequestSubscriberMode();
  }
}

export interface TransferSimOperatorCompletionData {
  success: boolean;
  error?: any;
}

export interface TransferSimOperatorModalData {
  subscribers: ExtendedSim[];
}
