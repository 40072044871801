import { Directive } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

@Directive()
export class SubscriberBatchSessionDeleter extends SubscriberBatchUpdater {
  /**
   * This constructor looks useless, but is is required for Angular 15 compatibility. (Because the superclass has legacy dependencies which are not compatible with Angular DI.)
   */
  constructor(logger: Logger, public apiService: SoracomApiService, subscribers: ExtendedSubscriberInterface[]) {
    super(logger, apiService, subscribers);
  }

  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    return subscriber.isSim ? this.updateSim(subscriber) : this.updateLegacySubscriber(subscriber);
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.session`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    const onlineOrOffline = subscriber.sessionStatus && subscriber.sessionStatus.online ? 'online' : 'offline';
    return LegacyTextContent.translation('session_status_label.' + onlineOrOffline);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return undefined; // prevent ever showing → because it doesn't make sense in this context
  }

  updateLegacySubscriber(subscriber: ExtendedSubscriberInterface) {
    return this.apiService.deleteSubscriberSession(subscriber.imsi);
  }

  updateSim(sim: ExtendedSubscriberInterface) {
    return this.apiService.deleteSimSession(sim.simId);
  }
}
