/**
 * This is the pagination service from the legacy user-console as-is, before any changes.
 */
import { LegacyAny } from '@soracom/shared/core';

/**
 * For more information for pagination link header, see github example:
 * https://developer.github.com/v3/#pagination
 *
 * Code from parse-link-header.js with sincerely respect.
 */
export interface HttpLinkHeaderElement {
  // example: `/v1/subscribers?last_evaluated_key=xxx&from=1111111111&limit=10`
  url: string;
  // example: xxx
  lastEvaluatedKey: string;
}

export class HttpLinkHeader {
  prev?: HttpLinkHeaderElement;
  next?: HttpLinkHeaderElement;
}

class RelAndUrl {
  // @ts-expect-error (legacy code incremental fix)
  rel: string;
  // @ts-expect-error (legacy code incremental fix)
  url: string;
}

export class Pagination {
  /**
   * @param linkHeader The text value of the a `link` header as returned by the Soracom API, e.g. `"</v1/subscribers?status_filter=active&last_evaluated_key=440103105845932&limit=10>; rel=prev, </v1/subscribers?status_filter=active&last_evaluated_key=440103124741874&limit=10>; rel=next"`
   */
  static getPaginationLinks(linkHeader: string): HttpLinkHeader {
    if (linkHeader) {
      return linkHeader
        .split(/,\s*</)
        .map(this.parseLink)
        .filter(this.hasRel)
        .reduce(this.intoRels, new HttpLinkHeader());
    } else {
      return {};
    }
  }

  /**
   * @param link e.g. 'Link: <URL>; rel="next"' or 'URL>; rel="prev"'
   */
  private static parseLink = (link: string): RelAndUrl => {
    try {
      // parts will be like ['<URL>', '\srel=prev']
      const parts: string[] = link.split(';');
      // linkUrl will be 'URL'
      // @ts-expect-error (legacy code incremental fix)
      const linkUrl: string = parts.shift().replace(/[<>]/g, '');

      const info = parts.reduce(this.createRelAndUrl, new RelAndUrl());
      info.url = linkUrl;
      return info;
    } catch (e) {
      // @ts-expect-error (legacy code incremental fix)
      return null;
    }
  };

  /**
   * @param acc {}
   * @param p e.g. ' rel="next"'
   */
  private static createRelAndUrl = (
    obj: RelAndUrl,
    relPart: string
  ): RelAndUrl => {
    // rel="next" => 1: rel 2: next
    const matched = relPart.match(/\s*(.+)\s*=\s*"?([^"]+)"?/);
    if (matched && matched[1] === 'rel') {
      obj.rel = matched[2];
    }
    return obj;
  };

  private static hasRel = (obj: RelAndUrl): boolean => {
    if (obj && obj.rel) {
      return true;
    } else {
      return false;
    }
  };

  private static intoRels = (
    httpLinkHeader: HttpLinkHeader,
    obj: RelAndUrl
  ): HttpLinkHeader => {
    obj.rel.split(/\s+/).forEach((rel: string) => {
      // @ts-expect-error (legacy code incremental fix)
      let lastEvaluatedKey = obj.url.match(/last_evaluated_key=([^&]*)&?/)[1];
      if (lastEvaluatedKey) {
        lastEvaluatedKey = decodeURIComponent(lastEvaluatedKey);
      }
      (httpLinkHeader as LegacyAny)[rel] = { url: obj.url, lastEvaluatedKey };
    });

    return httpLinkHeader;
  };
}
