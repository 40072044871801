import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Logger, LoggerService } from '@soracom/shared-ng/logger-service';
import { ExtendedSubscriber as LegacySubscriber, ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { UiTableContent } from '../../soracom-ui/ui-table/ui-table-content';
import { UiTableColumn } from '../../soracom-ui/ui-table/UiTableColumn';

@Component({
  selector: 'app-subscribers-table',
  templateUrl: './subscribers-table.component.html',
})
export class SubscribersTableComponent implements OnInit, OnChanges {
  // @ts-expect-error (legacy code incremental fix)
  @Input() subscribers: ExtendedSubscriberInterface[];
  @Input() columnsFilter: any;
  @Output() selectionChanged = new EventEmitter<ExtendedSubscriberInterface[]>();
  private logger: Logger;

  columns: Array<UiTableColumn<ExtendedSubscriberInterface>> = [
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'imsi', (s) => s.imsi),
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'name', (s) => s.name),
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'group', (s) => s.groupName),
    new UiTableColumn<ExtendedSubscriberInterface>('Subscriber', 'subscription', (s) => s.subscription),
  ];
  content: UiTableContent<ExtendedSubscriberInterface> = new UiTableContent<ExtendedSubscriberInterface>([], this.columns);

  itemCreator: new () => ExtendedSubscriberInterface;

  constructor() {
    this.logger = LoggerService.shared();
    this.itemCreator = LegacySubscriber;
  }

  ngOnInit() {
    this.content = new UiTableContent<ExtendedSubscriberInterface>(this.subscribers, this.columns, { multiSelect: true });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.debug('Subscribers table detects change: ', changes);
    if (changes.subscribers && changes.subscribers.currentValue) {
      this.content.setData(changes.subscribers.currentValue);
    }
  }

  uiTableSelectionChanged(subscribers: ExtendedSubscriberInterface[]) {
    this.selectionChanged.emit(subscribers);
  }
}
