<ds-notices
  [notices]="notices()"
  [canClose]="true"
  (close)="removeNotice($event)"
></ds-notices>

<div [ngClass]="{'--loading': loadState() == 'loading'}">

  <div class="ds-controls" *ngIf="showImsiFilter() || showTextFilter()">
    <div class="ds-bar">
      <ui-sim-update-history-filter-by-imsi
        *ngIf="showImsiFilter()"
        [possibleImsis]="possibleImsis()"
        (filteredImsiChange)="filteredImsiChange($event)"
      />
    </div>
    <div class="ds-bar">
      <ui-sim-update-history-filter
        *ngIf="showTextFilter()"
        filterId="ui-sim-update-history-filter"
        (filterValueChange)="filterValueChange($event)"
      />
    </div>
  </div>

  <table class="ds-datatable --striped">
    <thead class="--sticky">
      <tr>
        <th>{{ i18n.column.dateString | localize }}</th>
        <th>{{ i18n.column.subscription | localize }} | {{ i18n.column.imsi | localize }}</th>
        <th>{{ i18n.column.status | localize }}</th>
        <th>{{ i18n.column.speedClass | localize }}</th>
        <th>{{ i18n.column.bundles | localize }}</th>
        <th>{{ i18n.column.group | localize }}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of items()">
        <td data-testid="dateString">
          {{ item.dateString + ' ' + item.timeString }}
        </td>
        <td data-testid="imsi">
            <ds-tag-subscription-plan-name
              [planName]="$any(item.subscription) ?? 'unknown'"
              [imsi]="item.imsi ?? 'unknown'"
            ></ds-tag-subscription-plan-name>
        </td>
        <td data-testid="status">
          {{ item.status }}
        </td>
        <td data-testid="speedClass">
          {{ item.speedClass }}
        </td>
        <td data-testid="bundles">
          {{ item.bundles }}
        </td>
        <td data-testid="group">
          {{ item.group }}
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td colspan="999">
          <!-- If additional rows -->
          <button
            class="ds-button --small --plain --icon-refresh-cw --color-celeste-dark"
            *ngIf="loadState() !== 'loadedAll'"
            [disabled]="!canLoadMore"
            [ngClass]="{'--loading': loadState() == 'loadingMore'}"
            (click)="load()"
          >
            <span>{{ i18n.button.loadMore | localize}}</span>
          </button>
          <!-- If no additional rows -->
          <div class="ds-text --icon-info --small" *ngIf="loadState() === 'loadedAll' && !isFiltered() && !isFilteredByImsi()">
             {{ i18n.message.endOfHistoryData | localize }}
          </div>
        </td>
      </tr>
    </tfoot>
  </table>

</div>
