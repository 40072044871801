import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { SimPacketCaptureSessionsService } from '../sim-packet-capture-sessions.service';
import { SelectionService } from '../selection.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'sim-stop-capture-session',
  templateUrl: './sim-stop-capture-session.component.html',
})
export class SimStopCaptureSessionComponent {
  selection = inject(SelectionService);
  private simPacketCaptureSessionService = inject(SimPacketCaptureSessionsService);

  // @ts-expect-error (legacy code incremental fix)
  @Input() simId: string;

  // @ts-expect-error (legacy code incremental fix)
  sessionId: string;

  alertManager = new AlertsManager();
  bottomButtonBar = new UiButtonBar();
  isStoppingPacketCaptureSession = false;

  constructor(private cdRef: ChangeDetectorRef) {
    this.bottomButtonBar.configure((bar) => {
      bar.rightButtons = [
        UiButton.configure((button) => {
          button.titleId = 'simPacketCaptureSessions.stopCaptureModal.button.cancel';
          button.onClick = () => this.hide();
        }),
        UiButton.configure((button) => {
          button.titleId = 'simPacketCaptureSessions.stopCaptureModal.button.stop';
          button.buttonStyle = 'danger';
          button.onClick = this.deleteCaptureSession;
          button.isDisabled_ƒ = () => this.isStoppingPacketCaptureSession;
          button.buttonLoadingState_ƒ = () => (this.isStoppingPacketCaptureSession ? 'loading' : undefined);
        }),
      ];
    });

    this.selection.selectedSessionId$.pipe(takeUntilDestroyed()).subscribe((sessionId) => {
      this.sessionId = sessionId;
      this.cdRef.markForCheck();
    });
  }

  deleteCaptureSession = async () => {
    this.isStoppingPacketCaptureSession = true;
    this.alertManager.clear();
    try {
      await this.simPacketCaptureSessionService.stopSession(this.simId, this.sessionId);
      this.hide(true);
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'simPacketCaptureSessions.deleteCommonError'));
    } finally {
      this.isStoppingPacketCaptureSession = false;
    }
  };

  hide(afterSuccess = false) {
    this.selection.closePanel(afterSuccess);
  }
}
