<section dsDialogContent>
  <div class="ds-rows">
    <app-alerts [alertsManager]="alertManager"></app-alerts>
  </div>
</section>

<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'CreateVirtualSessionComponent.confirmButtonTitle' | translate"></span>
  </button>
  <button class="ds-button" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'common.cancel' | translate"></span>
  </button>
</footer>
