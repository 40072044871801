<section dsDialogContent>

  <dl class="ds-details">
    <dt>SIM ID</dt>
    <dd><div>{{simId}}</div></dd>
    <dt>IMSI</dt>
    <dd>
      <div>
        <ds-tag-subscription-plan-name
        [planName]="plan"
        [imsi]="imsi"
      ></ds-tag-subscription-plan-name></div>
    </dd>
  </dl>
    <table class="ds-datatable --embedded --flow-down" data-testid="top-up-history">
        <thead>
          <tr>
            <th>{{i18n.history.columns.date | localize}}</th>
            <th>{{i18n.history.columns.event | localize}}</th>
            <th>{{i18n.history.columns.reason | localize}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of history">
            <td>{{record.date}}</td>
            <td>{{record.name}}</td>
            <td>{{record.reason}}</td>
          </tr>
        </tbody>
      </table>
</section>
<footer dsDialogFooter>
    <button class="ds-button" uiFocusMe (click)="close()">{{i18n.history.close | localize}}</button>
</footer>