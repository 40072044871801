<div class="ds-group --no-indent">
  <header class="ds-banner --plain --indent-small">
    <div>
      <h4>{{'subscriber_session_log.title' | translate}}</h4>
    </div>
  </header>
  <div style="overflow-x: auto">
    <div class="ds-controls">
      <div></div>
      <div>
        <button class="ds-button --icon-refresh-cw --hide-label" [disabled]="isLoading" (click)="fetchLogs()">
          <span>Reload</span>
        </button>
      </div>
    </div>
    <app-alerts [alertsManager]="alertManager"></app-alerts>
    <div class="table-session-logs --40-h">
      <table class="ds-datatable --plain --striped ">
        <thead class="--sticky">
        <tr>
          <th class="session-log-time">{{ "subscriber_session_log.time" | translate }}<br><span style="font-size: smaller;">{{ getTimezoneString() }}</span></th>
          <th class="session-log-event">{{ "subscriber_session_log.event" | translate }}
            <div class="ds-richtip --right-bottom">
              <i class="ds-icon --icon-info ds-richtip__button"></i>
              <div class="ds-richtip__content" [innerHTML]="'subscriber_session_log.tooltip.event' | translate"></div>
            </div>
          </th>
          <th class="session-log-imei">{{ "subscriber_session_log.imei" | translate }}</th>
          <th class="session-log-imei">{{ "subscriber_session_log.subscription" | translate }}</th>
          <th class="session-log-mcc-mnc">{{ "subscriber_session_log.mcc" | translate }}<br>{{ "subscriber_session_log.mnc" | translate }}</th>
          <th class="session-log-vpg">VPG</th>
          <th class="session-log-country">{{ "subscriber_session_log.country" | translate }}</th>
          <th class="session-log-operator">{{ "subscriber_session_log.operator" | translate }}</th>
          <th class="session-log-radio-type">{{ "subscriber_session_log.radio_type" | translate }}</th>
          <th class="session-log-area-code">{{ "subscriber_session_log.area_code" | translate }}</th>
          <th class="session-log-cell-id">{{ "subscriber_session_log.cell_id" | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let log of sessionLogs">
          <td class="session-log-time">{{ formatLogTime(log.time) }}</td>
          <td class="session-log-event">{{ log.event }}</td>
          <td class="session-log-imei"><ui-imei [imei]="log.imei"></ui-imei></td>
          <td class="session-log-subscription">{{ log.subscription }}</td>
          <td class="session-log-mcc-mnc">{{ log.cell?.mcc || '' }}<br>{{ log.cell?.mnc || '' }}</td>
          <td class="session-log-vpg">
            <a *ngIf="log.vpgIsLinkable && log.vpgId" routerLink="{{ getVpgUrl(log.vpgId) }}"  [queryParams]="getVpgUrlQueryParams()">{{ log.vpgName || log.vpgId || '' }}</a>
            <span *ngIf="!log.vpgIsLinkable || !log.vpgId">{{ log.vpgName || log.vpgId || '' }}</span>
          </td>
          <td class="session-log-country">
            <span simSessionCountryInfo [cellInfo]="log.cellInfo"></span>
          </td>
          <td class="session-log-operator">{{ $any(log.cellInfo)?.getOperatorName() || '' }}</td>
          <td class="session-log-radio-type">{{ ($any(log.cellInfo)?.getRadioType() || '') | translate }}</td>
          <td class="session-log-area-code">{{ $any(log.cellInfo)?.getAreaCode() || '' }}</td>
          <td class="session-log-cell-id">{{ $any(log.cellInfo)?.getCellId() || '' }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="ds-controls">
      <button (click)="loadNext()" class="ds-button" [ngClass]="{'--loading': isLoading}" [disabled]="reachedEnd || isLoading || !$any(sessionLogs)?.length" style="width: 100%; justify-content: center">
        <span>{{'subscriber_session_log.next_page' | translate}}</span>
      </button>
    </div>
  </div>
</div>
