<div class="ds-rows">
  <h4> {{ "local_info.heading" | translate }} </h4>
  <div *ngIf="getUiMode() === 'ineligible'"
     class="text-muted">{{ subscriber.reasonReportLocalInfoIsDisabled() ?? '' | translate }}</div>

  <div [innerHTML]="'local_info.help.price_caution' | translate" class="ds-notice--warning"></div>

  <div class="ds-rows" [ngClass]="'x-local-info-report-' + getUiMode()">
    <div *ngIf="getUiMode() === 'submitting'"> {{ "local_info.help.submitting" | translate }} </div>
    <div *ngIf="getUiMode() === 'nonexistent'"> {{ "local_info.help.no_report_exists" | translate }} </div>
    <div *ngIf="getUiMode() === 'pending'"> {{ "local_info.help.request_pending" | translate }} </div>
    <div *ngIf="getUiMode() === 'failed'"> {{ "local_info.help.request_failed" | translate }} </div>
    <div *ngIf="getUiMode() === 'failed'"> {{ "local_info.help.to_try_again_press_the_button_below" | translate }} </div>
    <div *ngIf="getUiMode() === 'complete'"> {{ "local_info.help.request_succeeded" | translate }} </div>

    <div class="alerts" *ngIf="alertManager.alerts?.length">
      <app-alerts [alertsManager]="alertManager"></app-alerts>
    </div>

    <button (click)="requestReport()"
            [disabled]="submitting"
            class="ds-button x-request-local-report"
            type="button"
    >
      <span *ngIf="getUiMode() !== 'complete'">{{ "local_info.buttons.request_report" | translate }}</span>
      <span *ngIf="getUiMode() === 'complete'">{{ "local_info.buttons.request_update" | translate }}</span>
    </button>

    <table *ngIf="getUiMode() !== 'submitting' && getUiMode() !== 'nonexistent'"
           class="ds-datatable --embedded x-sim-local-info-report">
      <thead>
        <tr>
          <th>Data</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let key of ['status' ,'createdTime' ,'lastModifiedTime' ,'location' ,'nmr' ,'batteryStatus' ,'timingAdvance' ,'imsi' ,'iccid' ,'imei']">
          <tr *ngIf="localInfoDisplayData[key]">
            <td>{{ 'local_info.labels.' + key | translate }}</td>
            <td [innerHTML]="localInfoDisplayData[key]" class="local-info-report-value x-{{key}}"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
