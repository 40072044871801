import { Component, Input } from '@angular/core';
import { SimplifiedSubscriber } from '@soracom/shared/soracom-api-typescript-client';

@Component({
  selector: 'table[appNapterSubscriberDetailList]',
  templateUrl: './napter-subscriber-detail-list.component.html',
  host: { class: 'ds-datatable --condensed --indent-xsmall' },
})
export class NapterSubscriberDetailListComponent {
  @Input() subscribers: SimplifiedSubscriber[] | undefined = [];
  @Input() imsiOnlineMap: { [imsi: string]: boolean } = {};

  isSubscriberOnline(subscriber: SimplifiedSubscriber) {
    const imsi = subscriber.imsi;
    if (imsi) {
      return this.imsiOnlineMap[imsi];
    } else {
      return false;
    }
  }
}
