import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiSimUpdateHistoryTableComponent } from './ui-sim-update-history-table.component';

@Component({
  selector: 'ui-sim-update-history',
  standalone: true,
  imports: [CommonModule, UiSimUpdateHistoryTableComponent],
  templateUrl: './ui-sim-update-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSimUpdateHistoryComponent {
  @Input() simId: string = '';
}
