<section dsDialogContent class="--gap-xsmall" [class.--loading]="loading">
  <app-alerts [alertsManager]="alertsManager" *ngIf="alertsManager.alerts.length > 0"></app-alerts>
  <ng-container *ngIf="!loading">
    <dl class="ds-details">
      <dt>SIM ID</dt>
      <dd><div>{{simId}}</div></dd>
    </dl>
    <p class="ds-text --label">{{i18n.manualTopUpComponent.beforeTopUp | localize}}</p>
    <table appFixedRateSimRemainingUsageTable [simUsage]="remainingSimUsage" class="--embedded --flow-down"></table>
    <div class="ds-cols --center">
      <i class="ds-icon --icon-arrow-bottom --medium"></i>
    </div>
    <p class="ds-text --label">{{i18n.manualTopUpComponent.afterTopUp | localize}}</p>
    <table appFixedRateSimRemainingUsageTable [simUsage]="postTopUpSimUsage" class="--embedded --flow-down"></table>
    <div class="ds-notice --info" *ngIf="hasOverage">{{i18n.manualTopUpComponent.overageWarning | localize}}</div>
  </ng-container>
</section>
<footer dsDialogFooter>
    <button class="ds-button --primary" (click)="manualTopUp()" [disabled]="submitDisabled" [class.--loading]="submitting">{{i18n.manualTopUpComponent.submit | localize}}</button>
    <button class="ds-button" [disabled]="!canClose()" (click)="close()" uiFocusMe>{{i18n.manualTopUpComponent.cancel | localize}}</button>
</footer>
