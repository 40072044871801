import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { memoize } from 'lodash-es';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { PacketCaptureSession, PacketCaptureSessionStatusEnum } from '@soracom/shared/soracom-api-typescript-client';
import { SelectionService } from '../selection.service';
import { formatDateTime } from '@soracom/shared/util-common';
import { SimPacketCaptureSessionsService } from '../sim-packet-capture-sessions.service';
import { TranslateService } from '@ngx-translate/core';
import { SimsService } from '../../../../app/shared/subscribers/sims.service';

const getSessionStatusClass = memoize((status: PacketCaptureSessionStatusEnum) => ({
  'ds-tag--outline': status === 'REQUESTED' || status === 'STOPPED',
  'ds-tag--info': status === 'ACCEPTED',
  'ds-tag--color-celeste': status === 'CAPTURING',
  'ds-tag--warning': status === 'POST_PROCESSING',
  'ds-tag--alert': status === 'FAILED',
  'ds-tag--success': status === 'DONE' || status === 'STOPPED',
}));

@Component({
  selector: '[simSessionDataRow]',
  templateUrl: './sim-session-data-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimSessionDataRowComponent {
  private selection = inject(SelectionService);
  private simService = inject(SimsService);
  private translate = inject(TranslateService);
  private cdRef = inject(ChangeDetectorRef);

  private simPacketCaptureSessionService = inject(SimPacketCaptureSessionsService);

  /**
   * Current sim id
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() simId: string;

  /**
   * Packet session for current row
   */
  // @ts-expect-error (legacy code incremental fix)
  @Input() session: PacketCaptureSession;

  formatDateTime = formatDateTime;

  sessionInfoErrorMsg = '';

  // @ts-expect-error (legacy code incremental fix)
  sessionInfo: PacketCaptureSession;
  isFetchingSessionInfo = false;

  constructor() {}

  async fetchCaptureSession($event: LegacyAny) {
    $event.preventDefault();
    this.isFetchingSessionInfo = true;
    this.sessionInfoErrorMsg = '';

    try {
      const response = await this.simPacketCaptureSessionService.fetchSimPacketCaptureSession(
        this.simId,
        // @ts-expect-error (legacy code incremental fix)
        this.session.sessionId
      );
      this.sessionInfo = response.data;
    } catch (e) {
      const apiMessage = Alert.fromApiError(e);
      this.sessionInfoErrorMsg =
        (apiMessage && apiMessage.textContent.text) ||
        this.translate.instant('simPacketCaptureSessions.sessionInfoCommonError');
    } finally {
      this.isFetchingSessionInfo = false;
      this.cdRef.markForCheck();
    }
  }

  stopPacketCaptureSessionHandler = async ($event: LegacyAny) => {
    $event.preventDefault();
    this.selection.selectPanel('stop');
    // @ts-expect-error (legacy code incremental fix)
    this.selection.selectSessionId(this.session.sessionId);
  };

  deletePacketCaptureSessionHandler = async ($event: LegacyAny) => {
    $event.preventDefault();
    this.selection.selectPanel('delete');
    // @ts-expect-error (legacy code incremental fix)
    this.selection.selectSessionId(this.session.sessionId);
  };

  get sessionStatusClass() {
    const { status } = this.session;
    // @ts-expect-error (legacy code incremental fix)
    return getSessionStatusClass(status);
  }

  showGetLink() {
    return (
      // @ts-expect-error (legacy code incremental fix)
      (this.session.status.toLowerCase() === 'DONE'.toLowerCase() ||
        // @ts-expect-error (legacy code incremental fix)
        this.session.status.toLowerCase() === 'STOPPED'.toLowerCase()) &&
      !this.isFetchingSessionInfo &&
      !this.sessionInfo
    );
  }

  get showStopButton() {
    const { status } = this.session;
    // @ts-expect-error (legacy code incremental fix)
    return status.toLowerCase() === 'CAPTURING'.toLowerCase();
  }

  showDeleteButton() {
    const { status } = this.session;

    return (
      // @ts-expect-error (legacy code incremental fix)
      status.toLowerCase() === 'REQUESTED'.toLowerCase() ||
      // @ts-expect-error (legacy code incremental fix)
      status.toLowerCase() === 'DONE'.toLowerCase() ||
      // @ts-expect-error (legacy code incremental fix)
      status.toLowerCase() === 'FAILED'.toLowerCase() ||
      // @ts-expect-error (legacy code incremental fix)
      status.toLowerCase() === 'STOPPED'.toLowerCase()
    );
  }
}
