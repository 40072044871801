import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { SoracomI18nModule } from '@soracom/shared-ng/i18n';
import {
  DsModalContentBase,
  UiDsModalService,
} from '@soracom/shared-ng/ui-ds-modal';
import { fixedRateI18n } from '../i18n';
import {
  FixedRateSimViewDataService,
  UiFixedRateSimDataUsageDetails,
  TopUpAmounts,
} from '../fixed-rate-sim.service';
import {
  Alert,
  AlertsComponent,
  AlertsManager,
} from '@soracom/shared-ng/soracom-ui-legacy';
import {
  RemainingSimUsageData,
  calculateCurrentRemainingFixedRateSimUsage,
  calculateRemainingSimUsageAfterTopUp,
} from '../utils';
import { FixedRateSimRemainingUsageTableComponent } from '../fixed-rate-sim-remaining-usage-table/fixed-rate-sim-remaining-usage-table.component';
import { FocusMeDirective } from '@soracom/shared-ng/ui-common';
import { localize } from '@soracom/shared/locale-service';

@Component({
  selector: 'app-manual-top-up-modal',
  templateUrl: './manual-top-up-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SoracomI18nModule,
    AlertsComponent,
    FixedRateSimRemainingUsageTableComponent,
    FocusMeDirective,
  ],
})
export class ManualTopUpModalComponent
  extends DsModalContentBase<FixedRateSimTopUpModalInput, { success: boolean }>
  implements OnInit
{
  readonly i18n = fixedRateI18n;
  readonly fixedRateSimService = inject(FixedRateSimViewDataService);
  readonly cdRef = inject(ChangeDetectorRef);
  readonly alertsManager = new AlertsManager();
  loadingDataUsage = false;
  loadingTopUpAmounts = false;
  submitting = false;
  simDataUsage?: UiFixedRateSimDataUsageDetails;
  remainingSimUsage?: RemainingSimUsageData;
  postTopUpSimUsage?: RemainingSimUsageData;
  private topUpAmounts?: TopUpAmounts;

  async ngOnInit() {
    if (this.getInput().simUsage) {
      this.simDataUsage = this.getInput().simUsage;
    } else {
      await this.fetchSimDataUsageDetails();
    }
    await this.fetchTopUpAmountsData();
  }

  private fetchTopUpAmountsData() {
    this.loadingTopUpAmounts = true;
    return this.fixedRateSimService
      .getTopUpAmounts(this.imsi)
      .then((topUpAmounts) => {
        this.topUpAmounts = topUpAmounts;
        this.calculateViewData();
      })
      .catch((error) => {
        this.alertsManager.add(Alert.fromApiError(error));
      })
      .finally(() => {
        this.loadingTopUpAmounts = false;
        this.cdRef.markForCheck();
      });
  }

  private fetchSimDataUsageDetails() {
    this.loadingDataUsage = true;
    return this.fixedRateSimService
      .getSimDataUsageDetails(this.getInput().imsi)
      .then((simDataUsageDetails) => {
        this.simDataUsage = simDataUsageDetails;
      })
      .catch((error) => {
        this.alertsManager.add(Alert.fromApiError(error));
      })
      .finally(() => {
        this.loadingDataUsage = false;
        this.cdRef.markForCheck();
      });
  }

  private calculateViewData(): void {
    if (this.simDataUsage && this.topUpAmounts) {
      this.remainingSimUsage = calculateCurrentRemainingFixedRateSimUsage(
        this.simDataUsage
      );
      this.postTopUpSimUsage = calculateRemainingSimUsageAfterTopUp(
        this.simDataUsage,
        this.topUpAmounts
      );
    }
  }

  public get submitDisabled() {
    return this.loadingDataUsage || !this.simDataUsage || this.submitting;
  }

  public manualTopUp() {
    this.submitting = true;
    if (this.simDataUsage?.imsi) {
      this.fixedRateSimService
        .requestManualTopUp(this.simDataUsage.imsi)
        .then(() => {
          this.submitting = false;
          this.close({ success: true });
        })
        .catch((error) => {
          this.submitting = false;
          this.alertsManager.add(Alert.fromApiError(error));
        })
        .finally(() => {
          this.cdRef.markForCheck();
        });
    }
  }

  override canClose(): boolean {
    return !this.submitting;
  }

  get loading() {
    return this.loadingDataUsage || this.loadingTopUpAmounts;
  }

  get simId() {
    return this.getInput().simId;
  }

  get imsi() {
    return this.getInput().imsi;
  }

  get hasOverage(): boolean {
    return (
      !!this.remainingSimUsage &&
      (this.remainingSimUsage.remainingData < 0 ||
        this.remainingSimUsage.remainingSms < 0 ||
        this.remainingSimUsage.remainingUssd < 0)
    );
  }
}

export function injectOpenManualTopUpModal() {
  const uiDsModalService = inject(UiDsModalService);
  return function openManualTopUpModal(
    simId: string,
    imsi: string,
    simUsage?: UiFixedRateSimDataUsageDetails
  ) {
    return uiDsModalService.openAndWaitForResult(ManualTopUpModalComponent, {
      title: localize(fixedRateI18n.manualTopUpComponent.title),
      subtitle: localize(fixedRateI18n.manualTopUpComponent.subTitle),
      headerIconClass: '--icon-top-up',
      data: {
        simId: simId,
        imsi: imsi,
        simUsage: simUsage,
      },
    });
  };
}

export interface FixedRateSimTopUpModalInput {
  simId: string;
  imsi: string;
  simUsage?: UiFixedRateSimDataUsageDetails;
}
