<div class="ds-cols --6 x-sim-description-tab-content" *ngIf="subscriber">
  <dl class="ds-details --wide">
    <dt>{{ 'subscriber_details.msisdn' | translate }}</dt>
    <dd>
      <div>
        {{subscriber.msisdnDescription}}
      </div>
    </dd>

    <dt>{{ 'subscriber_details.module_type' | translate }}</dt>
    <dd>
      <div>{{subscriber.getPrettyModuleTypeNameId() | translate}}</div>
    </dd>

    <dt>{{ 'subscriber_details.ip_address' | translate }}</dt>
    <dd>
      <div>{{subscriber.getIpAddress()}}</div>
    </dd>

    <dt>{{ 'subscriber_details.speed_class' | translate }}</dt>
    <dd>
      <div>{{subscriber.speedClass}}</div>
    </dd>

    <dt>{{ 'subscriber_details.status' | translate }}</dt>
    <dd>
      <div style="white-space: nowrap;">
        <sim-status-info
          [isOnline]="subscriber.isOnline()"
          [status]="subscriber.status"
          [isTransferring]="subscriber.isTransferring()">
        </sim-status-info>
        <span *ngIf="subscriber.status === 'active'" dsSessionStatusTag [isOnline]="subscriber.isOnline()"></span>
      </div>
    </dd>

    <ng-container *ngIf="shouldShowRenewFeeTrackingDate()">
      <dt>
        {{ 'subscriber_details.renewFeeTrackingDate' | translate }}
        <span class="ds-richtip">
          <i class="ds-icon --icon-info --xxsmall ds-richtip__button"></i>
          <span class="ds-richtip__content" [innerHTML]="'subscriber_details.renewFeeTrackingTooltip' | translate"></span>
        </span>
      </dt>
      <dd>
        <div *ngIf="renewalFeeTrackingDate">{{renewalFeeTrackingDate}}</div>
        <div *ngIf="!renewalFeeTrackingDate">{{renewalFeeTrackingNoFeeMsg}}</div>
      </dd>
    </ng-container>
  </dl>

  <dl class="ds-details --wide">
    <ng-container *appFeature="'showSerialNumber'">
      <dt>{{ 'subscriber_details.serial_number' | translate }}</dt>
      <dd>
        <div>{{subscriber.serialNumber}}</div>
      </dd>
    </ng-container>
    <ng-container *appNoFeature="'showSerialNumber'">
      <dt>{{ 'subscriber_details.iccid' | translate }}</dt>
      <dd>
        <div>{{subscriber.iccid}}</div>
      </dd>
    </ng-container>

    <dt>{{ 'subscriber_details.plan' | translate }}</dt>
    <dd>
      <div>
        <p>
          <i class="ds-icon --icon-data"></i>&nbsp;
          {{ 'subscriber_details.mobile_data' | translate }}
        </p>
        <p *ngIf="subscriber.hasSms()">
          <i class="ds-icon --icon-sms"></i>&nbsp;
          {{ 'subscriber_details.sms' | translate }}
        </p>
      </div>
    </dd>

    <dt>{{ 'subscriber_details.expiry' | translate }}</dt>
    <dd>
      <div>{{formatDateTime(subscriber.expiredAt, 'datetime')}}</div>
    </dd>

    <dt>{{ 'subscriber_details.expiry_action' | translate }}</dt>
    <dd>
      <div id="expiry-action">{{subscriber.getExpiryActionDescriptionId() | translate}}</div>
    </dd>

    <dt>{{ 'subscriber_details.imei' | translate }}</dt>
    <dd>
      <div>{{subscriber.sessionStatus.imei}}</div>
    </dd>
  </dl>
</div>
