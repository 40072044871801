<header class="ds-banner --plain --indent-small">
  <div>
    <h4>{{i18n.fixedRateSimDetails.title | localize}}</h4>
  </div>
  <div class="ds-text">
    <button class="ds-button --icon-settings --plain --hide-label --small" data-testid="open-operator-topup-settings" (click)="operatorDefaultsEdit()" [disabled]="loading" *ngIf="isOperatorDefaultTopUpSetting">
      <span>{{i18n.fixedRateSimDetails.operatorDefaultHint | localize}}</span>
    </button>
    <div class="ds-select --small">
      <select [(ngModel)]="appliedTopUpOption" (ngModelChange)="switchTopUpSetting()" [disabled]="loading || !fixedRateSimDetails" data-testid="top-up-mode-select">
        <option *ngFor="let topUpOption of topUpOptions" [ngValue]="topUpOption">{{topUpOption.label | localize}}</option>
      </select>
    </div>
  </div>
</header>
<ng-container *ngIf="fixedRateSimDetails">
  <div class="ds-cols --middle --gap-small">
    <!-- Sim set to AUTO top up -->
    <ng-container *ngIf="isAutoTopUp">
      <div class="ds-tag --medium --success --icon-arrow-bold-top">
        <span>{{i18n.fixedRateSimDetails.autoTopUpLabel | localize}}</span>
      </div>
      <div class="ds-text --small">
        {{i18n.fixedRateSimDetails.autoTopUpDescription | localize}}
      </div>
    </ng-container>
    <!-- Sim set to MANUAL top up -->
    <ng-container *ngIf="isManualTopUp">
      <button class="ds-button --icon-top-up --small" (click)="manualTopUp()" [disabled]="loading || isTerminated">
        <span>{{ i18n.fixedRateSimDetails.manualTopUpButton | localize}}</span>
      </button>
      <div class="ds-text --small">
        {{ i18n.fixedRateSimDetails.manualTopUpDescription | localize}}
      </div>
    </ng-container>
  </div>
  <hr>
  <!-- Start Date/Expiry Date -->
  <div class="ds-cols" *ngIf="fixedRateSimDetails">
    <div class="ds-text --icon-calendar">
      <span class="--label">{{i18n.fixedRateSimDetails.startDate | localize}}:</span> {{fixedRateSimDetails.startDate}}
    </div>
    <div class="ds-text --icon-calendar-delete">
      <span class="--label">{{i18n.fixedRateSimDetails.expiryDate | localize}}:</span> {{fixedRateSimDetails.expiryDate}}
    </div>
    <button class="ds-button ds-span --end --icon-time-history --xsmall --hide-label --top-left" (click)="viewHistory()" data-testid="view-top-up-history">
      <span>{{i18n.fixedRateSimDetails.historyHint | localize}}</span>
    </button>
  </div>
  <!-- Charts -->
  <div class="ds-cols --3 --center">
    <div dsDialChart *ngIf="dataChartConfig" [chartTitle]="i18n.dataUsage | localize" [chartData]="dataChartConfig" [colorConfig]="dataChartColorConfig"></div>
    <div dsDialChart *ngIf="smsChartConfig" [chartTitle]="i18n.smsUsage | localize" [chartData]="smsChartConfig" [colorConfig]="smsChartColorConfig"></div>
    <div dsDialChart *ngIf="ussdChartConfig" [chartTitle]="i18n.ussdUsage | localize" [chartData]="ussdChartConfig" [colorConfig]="ussdChartColorConfig"></div>
    <div dsDialChart *ngIf="remainingTimeChartConfig" [chartTitle]="i18n.remainingTime | localize" [chartDataDMYY]="remainingTimeChartConfig" [colorConfig]="remainingTimeChartColorConfig"></div>
  </div>
  <!-- Limit Warnings -->
  <div class="ds-rows" *ngIf="isApproachingLimit || hasReachedLimit">
    <div class="ds-notice --warning" *ngIf="isApproachingLimit">
      {{i18n.fixedRateSimDetails.isApproachingLimit | localize}}
    </div>
    <div class="ds-notice --alert" *ngIf="hasReachedLimit">
      {{i18n.fixedRateSimDetails.hasReachedLimit | localize}}
    </div>
  </div>
</ng-container>
<!--  -->
<app-alerts [alertsManager]="alertsManager" *ngIf="alertsManager.alerts.length > 0"></app-alerts>
<div class="ds-notice" *ngIf="fixedRateSimDetails?.calculatedTime">
  <div class="ds-text --small --icon-time">
    {{i18n.calculatedTime | localize : { date: fixedRateSimDetails?.calculatedTime } }}
  </div>
</div>
