import { Component, inject, OnInit } from '@angular/core';
import { ExtendedSim as Sim } from '@soracom/shared/sim';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SimsService } from '../../../../app/shared/subscribers/sims.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { VirtualSimConnectionInfoComponent } from '../virtual-sim-connection-info/virtual-sim-connection-info.component';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { Logger } from '@soracom/shared/logger';
import { LegacyAny } from '@soracom/shared/core';
import { Translation } from '@soracom/shared-ng/soracom-ui-legacy';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

export interface SimAddVirtualSimComponentInput {
  subscribers: ExtendedSubscriberInterface[];
}

type AddableSubscription = 'planArc01';

/**
 * NOTE: mason 2023-05-09: This component is a legacy remnant of the old UI. All of the actual on-SIM subscription plans are now handled by ManageSubscriptionsComponent in the @soracom/shared-ng/feature-manage-subscriptions library.
 *
 * HOWEVER, virtual SIMs were out of scope, so this old UI which used to handle both virtual SIMs and other "add subscription" tasks is still here, but now it only deals with virtual SIMs. It has been renamed to SimAddVirtualSimComponent to reflect that, and the old code for adding subscriptions has been removed, but due to those reasons, the code here might be a little weird.
 */
@Component({
  selector: 'app-sim-add-subscription',
  templateUrl: './sim-add-virtual-sim.component.html',
})
export class SimAddVirtualSimComponent
  extends DsModalContentBase<SimAddVirtualSimComponentInput, Sim[]>
  implements OnInit
{
  loading = false;
  simsService = inject(SimsService);
  uiDsModalService = inject(UiDsModalService);

  helpText = LegacyTextContent.translation('SimAddVirtualSimComponent.helpText');

  // @ts-expect-error (legacy code incremental fix)
  currentSubscriptionsHelpText: LegacyTextContent;

  alertsManager = new AlertsManager();

  constructor(private logger: Logger) {
    super();
  }

  ngOnInit() {
    if (!this.targetSim.canAddSubscription()) {
      this.helpText = LegacyTextContent.translation('SimAddVirtualSimComponent.helpTextForVirtualSIMOnly');
    }

    const singleOrMultiple = this.currentSubscriptions.length === 1 ? 'single' : 'multiple';
    this.currentSubscriptionsHelpText = LegacyTextContent.translation(
      'SimAddVirtualSimComponent.currentSubscriptionsHelpText.' + singleOrMultiple
    );
  }

  private _output: Sim[] = [];

  get output(): Sim[] {
    return this._output;
  }

  className = 'SimAddVirtualSimComponent';

  get canConfirm() {
    return this.subscriptionsThatCanBeAdded.length > 0 && !this.loading;
  }

  get targetSim(): Sim {
    return this.getInput().subscribers[0] as Sim;
  }

  get currentSubscriptions(): string[] {
    return this.targetSim.allSubscribersWithPrimaryFirstThenSortedByImsi
      .filter((sub) => sub.status !== 'terminated')
      .map((sub) => sub.subscription);
  }

  /**
   * Get the list of subscriptions that may still be added to the target SIM. This may also include `'planArc01'` which is a magic subscription plan name that will cause a VSIM to be associated with the actual SIM if it is added (special case).
   */
  get subscriptionsThatCanBeAdded(): string[] {
    const candidates = ['planArc01'];
    return candidates.filter((p) => !this.currentSubscriptions.includes(p));
  }

  /**
   * Make API call (will throw error if API call promise does).
   */
  async addSubscription(planName: AddableSubscription, theSim: Sim) {
    // TOTALLY DIFFERENT SPECIAL CASE:
    this.logger.debug('ADD VSIM:', theSim);
    const vsimResult = await this.simsService.addVSIMToExistingSim(theSim.simId, theSim.iccid);
    this.logger.debug('ADD VSIM RESULT:', vsimResult);
    return vsimResult;
  }

  async confirm() {
    const theSim = this.targetSim;

    const results = {
      planArc01: undefined,
    };

    const errors: LegacyAny[] = [];

    try {
      this.loading = true;
      results.planArc01 = await this.addSubscription('planArc01', theSim);

      // We don't get back the same structure as the above add subscription cases. Therefore we need to just update the SIM:
      const updatedSim = await this.simsService.get(this.targetSim.simId);
      this._output = [updatedSim];

      // Show connection info in modal (private key, public key etc)
      this.uiDsModalService.open(VirtualSimConnectionInfoComponent, {
        title: 'SimAddVirtualSimComponent.planArcSuccessModalTitle',
        data: {
          connectionInfo: {
            ...updatedSim.arcSessionStatus,
            // @ts-expect-error (legacy code incremental fix)
            arcClientPeerPrivateKey: results.planArc01.arcClientPeerPrivateKey,
          },
          successMessage: 'SimAddVirtualSimComponent.successMessage',
        },
      });
    } catch (error) {
      errors.push(error);
    }

    if (errors.length > 0) {
      errors.forEach((error: LegacyAny) => {
        this.alertsManager.add(Alert.danger(error));
      });
    } else if (!theSim || !theSim.activeProfileId || !theSim.profiles) {
      // the decode failed, something weird went wrong
      errors.push(Alert.unexpected('decoding failed'));
    } else {
      // everything succeeded
      this.loading = false;
      this.close(this.output);
    }
    this.loading = false;
  }

  private _addSubscriptionCostWarning = new Translation(`${this.className}.addSubscriptionCostWarning`);

  get cautionaryText() {
    const result: LegacyAny[] = [];

    if (this.subscriptionsThatCanBeAdded.length > 0) {
      result.push(this._addSubscriptionCostWarning);
    }
    return result;
  }

  canClose(): boolean {
    return !this.loading;
  }
}
