import { PortMappingDestination } from './PortMappingDestination';
import { PortMappingSource } from './PortMappingSource';

export class PortMapping {
  // @ts-expect-error (legacy code incremental fix)
  endpoint: string;

  // @ts-expect-error (legacy code incremental fix)
  ipAddress: string;

  // @ts-expect-error (legacy code incremental fix)
  hostname: string;

  // @ts-expect-error (legacy code incremental fix)
  port: number;

  // @ts-expect-error (legacy code incremental fix)
  tlsRequired: boolean;

  // @ts-expect-error (legacy code incremental fix)
  duration: number;

  // @ts-expect-error (legacy code incremental fix)
  destination: PortMappingDestination;

  // @ts-expect-error (legacy code incremental fix)
  source: PortMappingSource;

  simId?: string;

  static get examplePortMapping() {
    const result = new PortMapping();
    result.destination = new PortMappingDestination(23, '001010000022509', 'air');
    result.source = new PortMappingSource(['10.10.10.10/32']);
    result.duration = 30 * 60;
    result.tlsRequired = false;
    result.endpoint = 'foo.bar.soracom.hoge:54321';
    result.ipAddress = '20.40.60.80';
    result.hostname = 'foo.bar.baz.hoge';
    result.port = 54321;
    return result;
  }

  /** Actual response from E2E testing, useful for tests. */
  static get exampleJSON() {
    return {
      ipAddress: '54.93.141.241',
      port: 10933,
      operatorId: 'OP0059150966',
      createdTime: 1558582732830,
      tlsRequired: false,
      destination: {
        imsi: '295050910122150',
        port: 22,
      },
      source: {
        ipRanges: ['124.35.192.25/32'],
      },
      expired: false,
      endpoint: '54.93.141.241:10933',
      imsi: '295050910122150',
      expiredTime: 1558600732830,
    };
  }
}
