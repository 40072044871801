<section dsDialogContent>
  <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>

  <div class="ds-rows">
    <ui-select [content]="expiryActionSelect"></ui-select>

    <label *ngIf="expiryActionSelect.value !== 'UNSET'" class="ds-field">
      <div class="ds-label">{{ 'ChangeSubscribersExpiryComponent.date' | translate }}</div>
      <div>
        <div class="ds-text --addon-light">UTC</div>
        <ds-date-time-picker
          class="ds-input --datetime-split"
          [asUtcTime]="true"
          [(ngModel)]="expiryDateTime"
          (ngModelChange)="onExpiryDateChange()"
          required
        >
        </ds-date-time-picker>
      </div>
      <div class="ds-text">
        <span class="--label">
          <span>
            {{ 'ChangeSubscribersExpiryComponent.in_local_date' | translate }}
          </span>
          <span> (UTC {{ utcOffset }}) </span>
        </span>
        :&nbsp; {{ expiryDateTime | datetime: 'datetime':'-' }}
      </div>
    </label>
  </div>

  <div class="form-control-static">
    <small>
      <em
        class="text-muted x-expiry-help-text {{ expiryActionDescription.lastPathComponent }}"
        [translate]="expiryActionDescription.id"
      ></em>
    </small>
  </div>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'ChangeSubscribersExpiryComponent.confirm' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'ChangeSubscribersExpiryComponent.cancel' | translate"></span>
  </button>
</footer>

