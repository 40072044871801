<section dsDialogContent>
  <!-- This loading screen is a temporary workaround for bug in `/v1/sims` API, where `hasSms` isn't returned (which matters for plan-D only). Will be unneeded after Configuration API comes online but for now it is needed. (mason 2021-07-20) -->
  <ng-container *ngIf="componentState === 'loading'">
    <div class="ds-group--loading x-loading">
      <div class="ds-group__title"></div>
      <p>Loading...</p>
    </div>
  </ng-container>

  <ng-container *ngIf="componentState === 'error'">
    <div class="ds-notice--alert x-error">
      <ui-text-content [content]="componentErrorMessage"></ui-text-content>
    </div>
  </ng-container>

  <ng-container *ngIf="componentState === 'ready'">
    <div *ngFor="let changeGroup of statusChangeGroups; let i = index" class="x-{{ i }}">
      <h4>
        <span *ngFor="let desc of changeGroup.descriptions; let i = index">
          <span *ngIf="i > 0 && i < changeGroup.descriptions.length"> / </span>
          <span>{{ desc | text }} </span>
        </span>
      </h4>

      <app-subscriber-batch-change [updater]="changeGroup.updater" [showBundle]="true"></app-subscriber-batch-change>

      <span *ngFor="let warning of changeGroup.warnings">
        <ui-span [content]="warning"></ui-span>
      </span>
    </div>
  </ng-container>

  <div class="ds-notice --info" *ngIf="showRebootSimWarning" [innerHtml]="'ChangeSubscribersStatusComponent.statusWillRequireDeviceReboot' | translate">

  </div>
</section>

<footer dsDialogFooter>
    <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
      <span [innerHTML]="confirmButtonTitleId | translate"></span>
    </button>
    <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
      <span [innerHTML]="'generic.button_title.cancel' | translate"></span>
    </button>
</footer>
