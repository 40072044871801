<div dsDialogContent>
  <p [innerHtml]="'subscriber_registration.notice_base_charge' | translate"></p>
  <div class="ds-rows" [innerHtml]="'subscriber_registration.apn_info' | translate"></div>

  <app-alerts [alertsManager]="alertManager"></app-alerts>

  <div *ngIf="isLoading" class="ds-notice --center --loading">
    <p translate="core.label.loading_dot_dot_dot"></p>
  </div>

  <ui-tabs [content]="tabs" *ngIf="!isLoading">
    <ui-tab [content]="tabContents.fromOrder">
      <ng-template ui-tab-content>
        <app-register-sim-from-order [closeModal]="close" [orders]="orders" (orderUpdated)="onOrderUpdated()"></app-register-sim-from-order>
      </ng-template>
    </ui-tab>
    <ui-tab [content]="tabContents.direct">
      <ng-template ui-tab-content>
        <app-register-sim-direct [closeModal]="close"></app-register-sim-direct>
      </ng-template>
    </ui-tab>
    <ui-tab *ngIf="tabContents.authenticationPasscode" [content]="tabContents.authenticationPasscode">
      <ng-template ui-tab-content>
        <app-register-sim-registration-key
          [closeModal]="close"
        ></app-register-sim-registration-key>
      </ng-template>
    </ui-tab>
    <ui-tab [content]="tabContents.virtualSim">
      <ng-template ui-tab-content>
        <app-register-virtual-sim
          [closeModal]="close"
          [isNewSimUi]="isNewSimUi()"
          [enableSimApiMode]="data.enableSimApiMode"
        ></app-register-virtual-sim>
      </ng-template>
    </ui-tab>
  </ui-tabs>
</div>
