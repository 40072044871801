<div class="ds-rows">
  <dl class="ds-details">
    <dt>{{'subscriber_session_info.status' | translate}}</dt>
    <dd>
      <div>
        <div dsSessionStatusTag [isOnline]="$any(subscriber)?.isOnline()"></div>
      </div>
    </dd>

    <dt>
      {{'subscriber_session_info.location' | translate}}
      <p class="ds-text --small control-label" *ngIf="oldLocation" style="text-transform: initial;">
        {{ 'subscriber_session_info.old_location_help_text' | translate: lastSeenTimeTranslatedData }}
      </p>
    </dt>
    <dd>
      <div *ngIf="!location">{{'subscriber_session_info.unknown' | translate}}</div>
      <div *ngIf="location">
        <app-marker-map
          style="width: 680px; height: 360px"
          [markers]="location"
          [zoom]="mapZoom"
        >
        </app-marker-map>
        <div class="ds-text --xsmall ds-cols --span --end">
          <ui-open-cellid-license></ui-open-cellid-license>
        </div>
        <div class="ds-text --alert" *ngIf="lowAccuracy">
          {{ 'subscriber_session_info.low_accuracy' | translate }}
        </div>
      </div>
    </dd>
  </dl>

  <app-sim-session-logs [subscriber$]="simDetailsService.subscriber"></app-sim-session-logs>
</div>
