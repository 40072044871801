import { LegacyAny } from '@soracom/shared/core';

import { Component, Input } from '@angular/core';

import { SoracomApiService } from '../../../../../app/shared/components/soracom_api.service';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButtonBar } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiButton } from '@soracom/shared-ng/soracom-ui-legacy';
import { UiDsModalService } from '@soracom/shared-ng/ui-ds-modal';
import { VirtualSimConnectionInfoComponent } from '../../virtual-sim-connection-info/virtual-sim-connection-info.component';

@Component({
  selector: 'app-register-virtual-sim',
  templateUrl: './register-virtual-sim.component.html',
})
export class RegisterVirtualSimComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() closeModal: (isCancel?: boolean) => void;
  // @ts-expect-error (legacy code incremental fix)
  @Input() isNewSimUi: boolean;
  // @ts-expect-error (legacy code incremental fix)
  @Input() enableSimApiMode: ($event: LegacyAny) => void;

  footerButtonBar: UiButtonBar;
  // @ts-expect-error (legacy code incremental fix)
  cancelButton: UiButton;
  alertManager = new AlertsManager();

  isRegistering = false;

  constructor(private soracomApiService: SoracomApiService, private uiDsModalService: UiDsModalService) {
    this.footerButtonBar = UiButtonBar.configure((bar) => {
      this.cancelButton = UiButton.configure((button) => {
        button.titleId = 'subscriber_registration.close';
        button.onClick = () => this.closeModal();
      });
      const registerButton = UiButton.configure((button) => {
        button.titleId = 'subscriber_registration.register_virtual_sim';
        button.buttonStyle = 'primary';
        button.onClick = this.registerVirtualSim;
        button.isDisabled_ƒ = () => this.isRegistering;
      });

      bar.rightButtons = [this.cancelButton, registerButton];
    });
  }

  enableSimUi($event: LegacyAny) {
    this.enableSimApiMode($event);
    this.closeModal();
  }

  registerVirtualSim = async () => {
    const subscriber = {
      type: 'virtual',
      subscription: 'planArc01',
    };

    this.isRegistering = true;

    try {
      const response = await this.soracomApiService.createVirtualSim(subscriber);
      this.closeModal(false);

      this.uiDsModalService.open(VirtualSimConnectionInfoComponent, {
        title: 'subscriber_registration.vsim.successTitle',
        subtitle: 'subscriber_registration.vsim.successMessage',
        headerIconClass: '--icon-deactivate-sim',
        data: {
          connectionInfo: {
            ...response.data.arcSessionStatus,
            simId: response.data.simId,
            arcClientPeerPrivateKey: response.data.profiles[response.data.activeProfileId]?.arcClientPeerPrivateKey,
          },
        },
      });
    } catch (e) {
      this.alertManager.add(Alert.fromApiError(e, 'common.error_messages.unknown_error'));
    } finally {
      this.isRegistering = false;
    }
  };
}
