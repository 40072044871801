<div class="ds-rows">
  <dl class="ds-details">
    <dt>{{ 'port-mapping-details.simId' | translate}}</dt>
    <dd>
      <div>
        {{ portMapping.simId }}
      </div>
    </dd>
    <dt>{{'port-mapping-details.serviceType' | translate}}</dt>
    <dd>
      <div>
        {{ portMapping.destination.serviceLabel }}
      </div>
    </dd>
    <dt>{{ 'port-mapping-details.port' | translate }}</dt>
    <dd>
      <div class="--code">
        {{ port }}
      </div>
      <button appCopyButton="{{port}}"></button>
    </dd>
    <dt>{{ 'port-mapping-details.tls' | translate }}</dt>
    <dd>
      <div>
        {{ (tls ? 'port-mapping-details.tlsYes' : 'port-mapping-details.tlsNo') | translate }}
      </div>
    </dd>
    <dt>{{ 'port-mapping-details.hostname' | translate }}</dt>
    <dd>
      <div class="--code">
        {{ hostname }}
      </div>
      <button appCopyButton="{{hostname}}"></button>
    </dd>
    <dt>{{ 'port-mapping-details.ipAddress' | translate }}</dt>
    <dd>
      <div class="--code">
        {{ ipAddress }}:{{ externalPort }}
      </div>
      <button appCopyButton="{{ipAddress + ':' + externalPort}}"></button>
    </dd>
    <dt>{{ 'port-mapping-details.from' | translate }}</dt>
    <dd>
      <div class="--code">
        {{ allowedIpRanges }}
      </div>
      <button appCopyButton="{{allowedIpRanges}}"></button>
    </dd>
  </dl>
  
  <p>
    {{ 'port-mapping-details.toAccessThisDevice' | translate }}:
  </p>
  
  <dl class="ds-details">
  
    <ng-container *ngIf="!tls">
      <dt>SSH</dt>
      <dd>
        <div class="--code">{{ sshConnectionExample }}</div>
        <button appCopyButton="{{sshConnectionExample}}"></button>
      </dd>
    </ng-container>
    
    <ng-container *ngIf="!tls">
      <dt>
        http
      </dt>
      <dd>
        <div>
          <a  [href]="httpConnectionExample" target="_blank">{{ httpConnectionExample }}
          </a>
        </div>
        <button appCopyButton="{{httpConnectionExample}}"></button>
      </dd>
    </ng-container>
  
    <ng-container *ngIf="tls">
      <dt>
        HTTPS
      </dt>
      <dd>
        <div>
          <a  [href]="httpsConnectionExample" target="_blank">{{ httpsConnectionExample }}
          </a>
        </div>
        <button appCopyButton="{{httpsConnectionExample}}"></button>
      </dd>
    </ng-container>
  
    <ng-container *ngIf="shouldShowVncConnectionExample">
      <dt>
        VNC
      </dt>
      <dd>
        <div class="--code">{{ vncConnectionExample }}</div>
        <button appCopyButton="{{vncConnectionExample}}"></button>
      </dd>
    </ng-container>
  
    <dt>Windows</dt>
    <dd>
      <div>
        <a
          [href]="rdpProfileLink"
          [download]="rdpDefaultFileName"
          (click)="processRdpProfileLinkClickForDefectiveBrowser($event)"
          [textContent]="'on-demand-remote-access.downloadRdpProfile' | translate"
        ></a>
      </div>
    </dd>
  </dl>
</div>
