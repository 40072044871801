import { LegacyAny } from '@soracom/shared/core';

import { Terminatable } from '@soracom/shared/soracom-platform';
import { PaginationService, SearchQuery } from '@user-console/legacy-soracom-api-client';
import { assignGroup } from '../components/GroupFetcher';
import { ScRelation } from '../components/paginator';
import { SubscriberSearchQueryParam } from '../components/subscriber_search_query_param';
import {
  LegacyBaseSoracomApiService,
  ListApiOptions,
  PaginatableService,
  SoracomApiParams,
  TaggableService,
  TagParams,
} from '@user-console/legacy-soracom-api-client';
import { GroupableService } from '@soracom/shared/soracom-platform';
import { TerminatableService } from '@soracom/shared/soracom-platform';
import { InjectList } from '../core/injectable';
import {
  ExtendedSubscriber as LegacySubscriber,
  ExtendedSubscriberInterface,
  ExtendedSubscriber,
} from '@soracom/shared/subscriber';
import { SubscriberSearchOption } from './enumerators/subscriber_enumerator_base';
import { AlertsServiceInstance } from '../components/alerts.service';

const VALID_KEYS = [
  'tag_name',
  'tag_value',
  'tag_value_match_mode',
  'status_filter',
  'speed_class_filter',
  'serial_number_filter',
];

const VALID_KEYS_FOR_QUERY_API = [
  'name',
  'group',
  'imsi',
  'msisdn',
  'iccid',
  'serial_number',
  'tag',
  'bundles',
  'session_status',
  'search_type',
];

export interface SubscribersApiInterface<T extends ExtendedSubscriberInterface>
  extends TaggableService,
    GroupableService,
    PaginatableService<T>,
    TerminatableService {
  search(queryParam: SubscriberSearchQueryParam): Promise<ScRelation<ExtendedSubscriberInterface>>;
}

/**
 * TODO: This implementation is work in progress.
 * Please add missing methods from soracom_api_service.js.
 */
export class SubscribersService implements SubscribersApiInterface<ExtendedSubscriberInterface> {
  static $inject: InjectList = ['$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'subscribers';

  constructor(
    private $q: ng.IQService,
    private SoracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {}

  /**
   * Get subscribers from Session Manager.
   */
  list(
    paginationOptions: SubscriberSearchOption,
    searchQuery?: SearchQuery | undefined,
    apiOptions?: ListApiOptions,
  ): Promise<ScRelation<LegacySubscriber>> {
    const apiParams: SoracomApiParams = {
      method: 'get',
      path: '/v1/subscribers',
      query: { ...paginationOptions },
    };

    if (apiOptions?.mode === 'query') {
      apiParams.path = '/v1/query/subscribers';
      apiParams.contentType = 'application/json';
      if (searchQuery) {
        searchQuery.queryItems.forEach((item) => {
          if (VALID_KEYS_FOR_QUERY_API.includes(item.key)) {
            // @ts-expect-error (legacy code incremental fix)
            apiParams.query[item.key] = item.value;
          }
        });
      }
    } else {
      if (searchQuery) {
        searchQuery.queryItems.forEach((item) => {
          if (VALID_KEYS.includes(item.key)) {
            // @ts-expect-error (legacy code incremental fix)
            apiParams.query[item.key] = item.value;
          }
        });
      }
    }

    return this.SoracomApi.callApiWithToken(apiParams).then((response) => {
      const data = response.data.map((element: any) => new LegacySubscriber(element));
      const links = this.paginationService.getPaginationLinks(response.headers.link);
      const relation: ScRelation<ExtendedSubscriber> = {
        data,
        links,
      };

      if (apiOptions?.assignGroup) {
        return assignGroup(null as any, relation.data).then((s) => {
          return { data: s, links: relation.links } as ScRelation<ExtendedSubscriber>;
        });
      } else {
        return relation;
      }
    });
  }

  listAll(
    // @ts-expect-error (legacy code incremental fix)
    lastEvaluatedKey: string = null,
    subscribers: ExtendedSubscriberInterface[] = [],
    searchQuery?: SearchQuery | undefined,
    apiOptions?: ListApiOptions,
  ): Promise<ExtendedSubscriberInterface[]> {
    return this.list({ limit: 1000, last_evaluated_key: lastEvaluatedKey }, searchQuery, apiOptions).then(
      (relation) => {
        subscribers.push(...relation.data);
        const newLastEvaluatedKey = relation.links.next?.lastEvaluatedKey;
        if (newLastEvaluatedKey) {
          return this.listAll(newLastEvaluatedKey, subscribers);
        } else {
          return subscribers;
        }
      },
    );
  }

  search(queryParam: SubscriberSearchQueryParam): Promise<ScRelation<ExtendedSubscriberInterface>> {
    const apiParams = {
      method: 'get',
      path: '/v1/query/subscribers',
      contentType: 'application/json',
      query: queryParam.toQuery(),
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const data = response.data.map((element: any) => new LegacySubscriber(element));
      const links = this.paginationService.getPaginationLinks(response.headers.link);
      const relation: ScRelation<ExtendedSubscriberInterface> = {
        data,
        links,
      };

      return this.$q.resolve(relation);
    });
  }

  searchAll(
    queryParam: SubscriberSearchQueryParam,
    // @ts-expect-error (legacy code incremental fix)
    lastEvaluatedKey: string = null,
    subscribers: ExtendedSubscriberInterface[] = [],
  ): Promise<ExtendedSubscriberInterface[]> {
    if (lastEvaluatedKey) {
      queryParam.lastEvaluatedKey = lastEvaluatedKey;
    }
    return this.search(queryParam).then((relation) => {
      subscribers.push(...relation.data);
      const newLastEvaluatedKey = relation.links.next?.lastEvaluatedKey;
      if (newLastEvaluatedKey) {
        return this.searchAll(queryParam, newLastEvaluatedKey, subscribers);
      } else {
        return subscribers;
      }
    });
  }

  findByImsi(imsi: string): Promise<ExtendedSubscriberInterface> {
    const apiParams: SoracomApiParams = {
      path: `/v1/subscribers/${imsi}`,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const subscriber = new LegacySubscriber(response.data);
      return subscriber;
    });
  }

  findByMsisdn(msisdn: string): Promise<ExtendedSubscriberInterface> {
    const apiParams: SoracomApiParams = {
      path: `/v1/subscribers/msisdn/${msisdn}`,
    };

    return this.SoracomApi.callApiWithToken(apiParams).then((response: LegacyAny) => {
      const subscriber = new LegacySubscriber(response.data);
      return subscriber;
    });
  }

  setGroup(id: string, groupId: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/set_group`,
      body: { groupId },
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  unsetGroup(id: string) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/unset_group`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  updateTags(id: string, tags: TagParams[]) {
    const apiParams = {
      method: 'put',
      path: `/v1/${this.resourcePath}/${id}/tags`,
      contentType: 'application/json',
      body: tags,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  deleteTag(id: string, tagName: string) {
    const apiParams = {
      method: 'delete',
      path: `/v1/${this.resourcePath}/${id}/tags/${encodeURIComponent(tagName)}`,
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  terminate(id: string) {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/terminate`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams);
  }

  disableTermination(id: string): Promise<Terminatable> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/disable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then(
      (response: LegacyAny) => new LegacySubscriber(response.data),
    );
  }

  enableTermination(id: string): Promise<Terminatable> {
    const apiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/${id}/enable_termination`,
      contentType: 'application/json',
    };
    return this.SoracomApi.callApiWithToken(apiParams).then(
      (response: LegacyAny) => new LegacySubscriber(response.data),
    );
  }

  export(async = true) {
    const apiParams: SoracomApiParams = {
      method: 'post',
      path: `/v1/${this.resourcePath}/export`,
      contentType: 'application/json',
    };
    if (async) {
      apiParams.query = { export_mode: 'async' };
    }
    this.SoracomApi.addDebugQueryParameters(apiParams);

    return this.SoracomApi.callApiWithToken(apiParams);
  }
}
