import { LegacyAny } from '@soracom/shared/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { Alert } from '@soracom/shared-ng/soracom-ui-legacy';
import { AlertsManager } from '@soracom/shared-ng/soracom-ui-legacy';
import { SimDetailsService } from '../sim-details.service';

@Component({
  selector: 'app-sim-tags-editor',
  templateUrl: './sim-tags-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimTagsEditorComponent {
  private simService = inject(SimApiService);
  private subscriberService = inject(SubscriberApiService);
  private simDetailsService = inject(SimDetailsService);
  private translate = inject(TranslateService);

  // @ts-expect-error (legacy code incremental fix)
  @Input() subscriber: ExtendedSubscriberInterface;

  alertManager = new AlertsManager();
  form: FormGroup;
  isUpdating = false;
  prevTags: LegacyAny;

  constructor(private cdRef: ChangeDetectorRef) {
    this.form = new FormGroup({
      tags: new FormControl(),
    });
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.subscriber.currentValue) {
      if (this.subscriber.tags) {
        const { name, ...tags } = this.subscriber.tags;
        this.prevTags = { ...tags };
        this.form.setValue({ tags });
      }
    }
  }

  get isUpdateBtnDisabled() {
    return (
      !this.form.valid || JSON.stringify(this.form.value.tags) === JSON.stringify(this.prevTags) || this.isUpdating
    );
  }

  get apiPromise() {
    return this.subscriber.isSim ? this.simService : this.subscriberService;
  }

  async updateTags() {
    const promise = this.apiPromise;
    this.isUpdating = true;
    this.alertManager.clear();
    try {
      if (this.subscriber.tags) {
        await this.removeTags();
      }
      const body = Object.keys(this.form.value.tags).reduce((accum, curr) => {
        // @ts-expect-error (legacy code incremental fix)
        accum.push({
          tagName: curr,
          tagValue: this.form.value.tags[curr],
        });
        return accum;
      }, []);
      this.subscriber.isSim
        ? await this.simService.putSimTags({ simId: this.subscriber.simId, tagUpdateRequest: body })
        : await this.subscriberService.putSubscriberTags({ imsi: this.subscriber.imsi, tagUpdateRequest: body });
      this.prevTags = { ...this.form.value.tags };
      await this.simDetailsService.updateSubscriber();
      this.alertManager.add(Alert.success(this.translate.instant('tag.updateSuccess')));
    } catch (e) {
      throw e;
      this.alertManager.add(Alert.fromApiError(e));
    } finally {
      this.isUpdating = false;
      this.cdRef.markForCheck();
    }
  }

  private async removeTags() {
    // Find tags which are in original tag list, but not in form. Those are the ones which are removed by user, which we have to remove.
    // Also in case user renamed tag, here it will be removed too, because updateTags api will add the new tag anyway.
    const tagsToRemove = Object.keys(this.subscriber.tags).reduce((accum, current) => {
      if (!this.form.value.tags[current] && current !== 'name') {
        // @ts-expect-error (legacy code incremental fix)
        accum.push(current);
      }
      return accum;
    }, []);

    for (const tag of tagsToRemove) {
      this.subscriber.isSim
        ? await this.simService.deleteSimTag({ simId: this.subscriber.simId, tagName: tag })
        : await this.subscriberService.deleteSubscriberTag({ imsi: this.subscriber.imsi, tagName: tag });
    }
  }
}
