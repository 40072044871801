import { Directive } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { SubscriberStatus } from '@soracom/shared/subscription-plan';
import { SoracomApiService } from 'apps/user-console/app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchUpdater } from './SubscriberBatchUpdater';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';

@Directive()
export class SubscriberBatchStatusUpdater extends SubscriberBatchUpdater {
  // @ts-expect-error (legacy code incremental fix)
  newStatus: SubscriberStatus;
  constructor(
    logger: Logger,
    public apiService: SoracomApiService,
    subscribers: ExtendedSubscriberInterface[],
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService
  ) {
    super(logger, apiService, subscribers);
  }

  updateFunction(subscriber: ExtendedSubscriberInterface): Promise<any> {
    if (!this.newStatus) {
      throw new Error('updateFunction() invoked with invalid newStatus');
    }

    return subscriber.isSim ? this.updateSim(subscriber) : this.updateLegacySubscriber(subscriber);
  }

  updateLegacySubscriber(subscriber: ExtendedSubscriberInterface): Promise<any> {
    const imsi = subscriber.imsi;
    if (this.newStatus === 'active') {
      return this.subscriberApiService.activateSubscriber({ imsi });
    } else if (this.newStatus === 'inactive') {
      return this.subscriberApiService.deactivateSubscriber({ imsi });
    } else if (this.newStatus === 'standby') {
      return this.subscriberApiService.setSubscriberToStandby({ imsi });
    } else if (this.newStatus === 'suspended') {
      return this.subscriberApiService.suspendSubscriber({ imsi });
    } else {
      throw new Error('FIXME: Invalid status for change, or else not yet implemented. (HD398DHWWN3)');
    }
  }

  updateSim(subscriber: ExtendedSubscriberInterface): Promise<any> {
    const simId = subscriber.simId;
    if (this.newStatus === 'active') {
      return this.simApiService.activateSim({ simId });
    } else if (this.newStatus === 'inactive') {
      return this.simApiService.deactivateSim({ simId });
    } else if (this.newStatus === 'standby') {
      return this.simApiService.setSimToStandby({ simId });
    } else if (this.newStatus === 'suspended') {
      return this.simApiService.suspendSim({ simId });
    } else {
      throw new Error('FIXME: Invalid status for change, or else not yet implemented. (HD398DHWWN3)');
    }
  }

  get changeColumnHeading() {
    return LegacyTextContent.translation(`SubscriberBatchUpdater.status`);
  }

  beforeDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent {
    return LegacyTextContent.translation(`SubscriberStatus.${subscriber.status}`);
  }

  afterDescription(subscriber: ExtendedSubscriberInterface): LegacyTextContent | undefined {
    return this.newStatus ? LegacyTextContent.translation(`SubscriberStatus.${this.newStatus}`) : undefined;
  }
}
